import React, { useState } from "react";
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import moment from "moment";
import {Row, Col, InputGroup, Form, Modal, Container} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

// components
import TableTemplate from "../../utilities/table_template";
import Navbar from "../Navbar/Navbar"
import AddEmployeeDeduction from "./AddEmployeeDeduction";
import DeletePrompt from "../Modals/DeletePrompt";
import { GetAllEmployees } from "../../helpers/CommonApi";

// icons
import PaymentIcon from '@material-ui/icons/Payment';
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from '@mui/icons-material/Upload';

//css
import './Deductions.css'
import '../../utilities/common.css'
import { toast } from "react-toastify";

import { validateEmployeeDeduction } from "../../helpers/Validation/Manage/EmployeeDeductionValidation";
import InputError from "../../helpers/InputError/InpuError";

export default function EmployeeDeduction() {
    const userToken = getToken();
    const userId = getUser();

    //get all deductions
    const [inactive, setInactive] = useState(false);
    const [allDeductions, setAllDeductions] = useState([]);
    const [showAddDeduction, setShowAddDeduction] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [editData, setEditData] = useState([])
    const [filteredDeductions, setfilteredDeductions] = useState([])
    const [editDeduction, setEditDeduction] = useState([])
    const [deductionId, setDeductionId] = useState()
    const [loading, setLoading] = useState(true);
    const [click, setClick] = useState(false);
    const navigate = useNavigate();

    // GET ALL EMPLOYEES
    const allEmployees = GetAllEmployees();

    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleCloseAdd = () => setShowAddDeduction(false);
    const handleShowAddDeduction = () => setShowAddDeduction(true);

    // Validation
    const [isError, setIsError] = useState({
        employee_id: '',
        issue_date: '',
        sss_amount: '',
        phic_amount: '',
        hdmf_amount: '',
    });
    
    function onClickAddDeduction () {
        handleShowAddDeduction ()
    }

    function handleEdit (deduction_id) {
        setDeductionId(deduction_id)
        getDeductionById(deduction_id);
        setShowEdit(true)
    }

    function onClickDeleteDeduction (deduction_id) {
        setDeductionId(deduction_id)
        setShowDelete(true)
    }

    // Delete Deduction
    function handleRemove () {
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'post',
            url: window.$link + 'deductions/delete',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify ({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                deduction_id: deductionId
            })
        }).then(response => {
            toast.success("Successfully Deleted Deduction")
        }).catch(function (error) {
            toast.error("Failed to Delete Deduction")
        })

        setTimeout(() => refreshPage(), 1000);  
    }

    function handle(e) {
        const newData = { ...editData };
        newData[e.target.id] = e.target.value;
        setEditData(newData);
    }

    function renderInputError(var_name) {
        let value = editData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // Handle Search Filter
    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allDeductions) {
            allDeductions.forEach(function(employee) {
                if((employee.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(employee);
                }
            })
        }

        if(term == "") {
            setfilteredDeductions(allDeductions);
        } else {
            setfilteredDeductions(filteredArr);
        }
    }

    // Get deduction by deductionId
    function getDeductionById(deduction_id) {
        var axios = require('axios')
        var qs  = require('qs');

        axios({
            method: 'post',
            url: window.$link + 'deductions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                deduction_id: deduction_id
            })
        }).then(response => {
            const res = response.data.data[0]
            setEditDeduction(res)
            setEditData(res)
        }).catch(function (error) {
            tokenExpired(error);
        });
    }

    // Submit the edited deduction details
    function submitEditedDeduction () {
        var axios = require('axios')
        var qs = require('qs')

        if (validateEmployeeDeduction(editData, setIsError)==true && click===false) {
            setClick(true);
            axios({
                method: 'post',
                url: window.$link + 'deductions/update',
                headers: {
                    "api-key": window.$api_key,
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: qs.stringify({
                    requester: userId,
                    token: userToken.replace(/['"]+/g, ''),
                    deduction_id: deductionId,
                    employee_id: editData.employee_id,
                    issue_date: editData.issue_date,
                    sss_amount: editData.sss_amount,
                    phic_amount: editData.phic_amount,
                    hdmf_amount: editData.hdmf_amount,
                    intellicare_amount: editData.intellicare_amount,
                    axa_amount: editData.axa_amount,
                    cash_advance: editData.cash_advance,
                    phone_loan: editData.phone_loan,
                    laptop_loan: editData.laptop_loan
                })
                
            }).then(response => {
                toast.success("Deduction Updated Successfully");
                setTimeout(() => refreshPage(), 1000);
            }).catch(function(error) {
                toast.error("Failed to Update Deduction")
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    // GET ALL DEDUCTIONS
    React.useEffect(() => {
        setAllDeductions([]);
        allDeductions.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'deductions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            })
        }).then(response => {
            const res = response.data.data;
            res.sort((a, b) => {return (a.last_name < b.last_name) ? -1 : (a.last_name > b.last_name) ? 1 : 0});
            res.map((deduction) => {
                var info = {};

                info.id = deduction.id;
                info.employee_name = deduction.employee_name;
                info.issue_date = moment(deduction.issue_date).format('MMM D, YYYY'); // to change
                info.sss_amount = deduction.sss_amount;
                info.phic_amount = deduction.phic_amount;
                info.hdmf_amount = deduction.hdmf_amount;
                info.intellicare_amount = deduction.intellicare_amount;
                info.axa_amount = deduction.axa_amount;
                info.phone_loan = deduction.phone_loan;
                info.laptop_loan = deduction.laptop_loan;
                info.cash_advance = deduction.cash_advance;

                setAllDeductions(oldArray => [...oldArray, info]);
                setfilteredDeductions(oldArray => [...oldArray, info]);    
                setLoading(false)         
            })
        }).catch(function (error) {
            tokenExpired(error);
            setLoading(false);
        });
    }, []);

    return(
        <div className="comm-bg">
            <Navbar />
            <div className={`comm-page-container`}>

                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className="d-flex comm-header-deduction">
                            Employee Deductions   
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-deduction" onClick={() => navigate('employee_deduction_batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                                <Col className="me-5">
                                    <button className="export-button-deduction" onClick={onClickAddDeduction}>
                                        <span className="export-text"><PaymentIcon/> Add Deduction</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>   
                    </Row>
                </Row>

                <AddEmployeeDeduction
                    show={showAddDeduction} 
                    hide={handleCloseAdd}
                    allEmployees = {allEmployees}
                />

                <DeletePrompt
                    name = "DEDUCTION"
                    show = {showDelete}
                    hide = {handleCloseDelete}
                    remover = {handleRemove}
                />

                <Row className='mt-3'>
                    <InputGroup className="search-deduction">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>
    
                {/* CONTENT */}
                <div className="deductions-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                                <TableTemplate
                                    tableType='deductions'
                                    // tableHeaders={["Employee Name", "Deduction Date", "SSS", "PhilHealth", "Pag-Ibig", "Intellicare", "AXA","Phone Loan","Laptop Loan","Cash Advance",""]}
                                    tableHeaders={["Employee Name", "Deduction Date", "SSS", "PhilHealth", "Pag-Ibig", "Intellicare", "AXA",""]}
                                    tableData={filteredDeductions}
                                    editHandler={handleEdit}
                                    deleteHandler={onClickDeleteDeduction}
                                />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }                    
                </div>

                <Modal show={showEdit} onHide={handleCloseEdit} size="m" centered>
                {/* <Form> */}
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Edit Deduction
                        </div>
                        <button type="button" class="btn-close"  onClick={() => setShowEdit(false)}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="name">Deduction Date<span className='red'> *</span></Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                value={editData.issue_date}
                                                type="date" 
                                                name="issue_date" 
                                                id='issue_date' className="mb-2" 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError("issue_date")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Employee<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={editData.employee_name}
                                                aria-label="employee_name" 
                                                aria-describedby="employee_name" 
                                                placeholder="" 
                                                name="employee_name" 
                                                id='employee_name' 
                                                disabled
                                            >
                                            </Form.Control>
                                            {renderInputError("employee_name")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                               
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">SSS Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control 
                                                value={editData.sss_amount}
                                                type="number" 
                                                name="sss_amount"
                                                id="sss_amount" 
                                                onChange={(e) => handle(e)} 
                                            />
                                            {renderInputError("sss_amount")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">PhilHealth Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control 
                                                value={editData.phic_amount}
                                                type="number" 
                                                name="phic_amount" 
                                                id="phic_amount" 
                                                onChange={(e) => handle(e)} 
                                            />
                                            {renderInputError("phic_amount")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Pag-Ibig Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={editData.hdmf_amount} 
                                                type="number" 
                                                name="hdmf_amount" 
                                                id="hdmf_amount" 
                                                onChange={(e) => handle(e)} 
                                            />
                                            {renderInputError("hdmf_amount")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Intellicare Amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                value={editData.intellicare_amount} 
                                                type="number" 
                                                name="intellicare_amount" 
                                                id="intellicare_amount" 
                                                aria-label="intellicare_amount" 
                                                aria-describedby="intellicare_amount"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Cash Advance</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                value={editData.cash_advance} 
                                                type="number" 
                                                name="cash_advance" 
                                                id="cash_advance" 
                                                aria-label="cash_advance" 
                                                aria-describedby="cash_advance"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">AXA Amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                value={editData.axa_amount} 
                                                type="number" 
                                                name="axa_amount" 
                                                id="axa_amount" 
                                                aria-label="axa_amount" 
                                                aria-describedby="axa_amount"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Phone Payment</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                value={editData.phone_loan} 
                                                type="number" 
                                                name="phone_loan" 
                                                id="phone_loan" 
                                                aria-label="phone_loan" 
                                                aria-describedby="phone_loan"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Laptop Payment</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                value={editData.laptop_loan} 
                                                type="number" 
                                                name="laptop_loan" 
                                                id="laptop_loan" 
                                                aria-label="laptop_loan" 
                                                aria-describedby="laptop_loan"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={handleCloseEdit}>Cancel</button>
                            <button type="submit" className='save-button' onClick={submitEditedDeduction}>Save</button>
                        </div>
                    </Modal.Body>
                {/* </Form> */}
            </Modal>
            </div>
        </div>
    );
}