import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import {toast} from 'react-toastify'
import '../Modals/modal.css'
import { GetAllRequirements } from '../../helpers/CommonApi';

import { validateBusinessUnit } from '../../helpers/Validation/Manage/BusinessUnitValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditEmploymentStatus(props) {
    const userToken = getToken();
    const userId = getUser();
    const allRequirements = GetAllRequirements();
    const [isError, setIsError] = useState({
        name: false
    });
    const [click, setClick] = useState(false);
    const [editEmploymentStatusData, setEditEmploymentStatusData] = useState(props.editEmploymentStatusData);

    function handle(e, element = null) {
        let editedEmploymentStatusData = { ...props.editEmploymentStatusData };
        if (e.target.id === "requirement_id") {
            let index = editedEmploymentStatusData["requirements"].map(t => t.id).indexOf(element.id);
            editedEmploymentStatusData["requirements"][index][e.target.id] = e.target.value;
        } else {
            editedEmploymentStatusData[e.target.id] = e.target.value;
        }
        setEditEmploymentStatusData(editedEmploymentStatusData);
    }

    function renderInputError(var_name) {
        let value = editEmploymentStatusData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function submitEditEmploymentStatus(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateBusinessUnit(editEmploymentStatusData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'employment_statuses/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: editEmploymentStatusData.name,
                employment_status_id: editEmploymentStatusData.id,
                no_of_employment_requirements: editEmploymentStatusData.requirements.length,
                employment_requirements: editEmploymentStatusData.requirements
            })
            })
            .then(function (response) {
                toast.success("Employment Status Edited Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error("Failed to Update Employment Status")
                tokenExpired(error);
                setClick(false);
                // setTimeout(() => refreshPage(), 1000);
            });
        }
    }

     function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addRecipient(e) {
        var recipients = props.editEmploymentStatusData.requirements;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            requirement_id: ''
        });

        setEditEmploymentStatusData((prev) => {
            return {
                ...prev,
                requirements: recipients
            }
        });
    }

    function removeRecipient(e, req_index) {
        var recipients = props.editEmploymentStatusData.requirements;
        for(let i=0;i<recipients.length;i++) {
            if(i === req_index) {
                recipients.splice(i, 1);
                break;
            }
        }

        setEditEmploymentStatusData((prev) => {
            return {
                ...prev,
                requirements: recipients
            }
        })
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Employment Status
                    </div>
                    <button type="button" class="btn-close"   onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll m-2 p-0'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Employment Status Name <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            defaultValue={props.editEmploymentStatusData?props.editEmploymentStatusData.name:""}
                                            type="text"
                                            aria-label="name" 
                                            aria-describedby="name" 
                                            placeholder="Employment Status Name" 
                                            name="name" 
                                            id='name' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("name")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            {
                                (props.editEmploymentStatusData["requirements"] && props.editEmploymentStatusData["requirements"].length) ? 
                                props.editEmploymentStatusData["requirements"].map((data, index) => {
                                    return (
                                        <Row>
                                            <Col xs={10}>
                                            <Form.Label className="h6" htmlFor="name">Requirement</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Select 
                                                    value={data.requirement_id}
                                                    aria-label="requirement_id"
                                                    aria_describedby="requirement_id"
                                                    name="requirement_id"
                                                    id="requirement_id"
                                                    placeholder=''
                                                    onChange={(e) => handle(e, data)}
                                                >
                                                    <option value=''>Select Requirements</option>
                                                    {
                                                        allRequirements.map((data) => (
                                                            <option value={data.id}>{data.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, index)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>  
                                    )
                                }) : (
                                    <p></p>
                                )
                            }
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Requirement</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditEmploymentStatus}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}