import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { refreshPage, removeUserSession} from './utilities/common.js';
import { getRoleId, getToken } from './utilities/common.js';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// components
import Login from "../src/components/Login/Login";
import Dashboard from "../src/components/Dashboard/Dashboard";
import Payroll from "../src/components/Payroll/Payroll";
import PayrollPrintPage from "../src/components/Payroll/PayrollPrintPage";
import GenPayrollPage from './components/Payroll/GenPayrollPage.js';
import Attendance from "../src/components/Attendance/Attendance";
import Schedule from "../src/components/Schedule/Schedule";
import ScheduleBatchEntry from "./components/Schedule/ScheduleBatchEntry";
import Employee from "../src/components/Employee/Employee";
import Branch from "../src/components/Branch/Branch";
import Memo from "../src/components/Memo/Memo";
import ViewMemo from "../src/components/Memo/ViewMemo";
import Announcement from "../src/components/Announcement/Announcement";
import ViewAnnouncement from "../src/components/Announcement/ViewAnnouncement";
import CommunicationLetters from './components/CommunicationLetter/CommunicationLetters.js';
import ViewCommunicationLetters from './components/CommunicationLetter/ViewCommunicationLetters.js';
import ViewEmployee from "../src/components/Employee/ViewEmployee";
import UpdateContract from "../src/components/Employee/UpdateContract";
import Leave from "../src/components/Leave/Leave";
import ViewLeave from './components/Leave/ViewLeave.js';
import Holidays from "../src/components/Holidays/Holidays";
import Shifts from '../src/components/Shifts/Shifts';
import ShiftsBatchEntry from '../src/components/Shifts/ShiftsBatchEntry';
import Forms from './components/Forms/Forms';
import GenForms from './components/Forms/GenForms';
import ViewForms from "../src/components/Forms/ViewForms";
import EditForms from "../src/components/Forms/EditForms";
import SurveyForm from "../src/components/Forms/SurveyForm"
import Project from "../src/components/Project/Project";
import Department from "../src/components/Department/Department";
import Position from "../src/components/Position/Position";
import BusinessUnit from "../src/components/BusinessUnit/BusinessUnit";
import EmploymentStatus from "../src/components/EmploymentStatus/EmploymentStatus";
import EmployeePayslip from './components/Payroll/EmployeePayslip.js';
import WorkFromAnywhere from './components/WorkFromAnywhere/WorkFromAnywhere.js';
import BatchEntry from './components/Attendance/BatchEntry'
import CompanyFiles from './components/CompanyFiles/CompanyFiles.js';
import EmployeeStatus from './components/EmployeeStatus/EmployeeStatus.js';
import Requirements from './components/Requirements/Requirements.js';
import Loan from "../src/components/Loan/Loan";
import ViewLoan from "../src/components/Loan/ViewLoan";
import Deduction from "../src/components/Deduction/Deduction";
import Philhealth from "../src/components/Philhealth/Philhealth";
import ViewPhilhealth from "../src/components/Philhealth/ViewPhilhealth";
import Pagibig from "../src/components/Pagibig/Pagibig";
import ViewPagibig from "../src/components/Pagibig/ViewPagibig";
import SSS from "../src/components/SSS/SSS";
import ViewSSS from "../src/components/SSS/ViewSSS";
import Taxwithheld from "../src/components/Taxwithheld/Taxwithheld";
import ViewTaxwithheld from "../src/components/Taxwithheld/ViewTaxwithheld";
import Salaryloan from "../src/components/Salaryloan/Salaryloan";
import ViewSalaryloan from "../src/components/Salaryloan/ViewSalaryloan";
import SSScalamityloan from "../src/components/SSScalamityloan/SSScalamityloan";
import ViewSSScalamityloan from "../src/components/SSScalamityloan/ViewSSScalamityloan";
import Pagibigloan from "../src/components/Pagibigloan/Pagibigloan";
import ViewPagibigloan from "../src/components/Pagibigloan/ViewPagibigloan";
import Phoneloan from "../src/components/Phoneloan/Phoneloan";
import ViewPhoneloan from "../src/components/Phoneloan/ViewPhoneloan";
import LetterType from "./components/LetterType/LetterType";
import LeaveType from "./components/LeaveType/LeaveType";
import EmployeeDeduction from "./components/EmployeeDeduction/EmployeeDeduction";
import EmployeeDeductionBatchEntry from "./components/EmployeeDeduction/EmployeeDeductionBatchEntry";

function App() {
  const [token, setAuthentication] = useState(window.$userToken);
  const [tokenExpiry, setTokenExpiry] = useState(window.$token_expiry);
  const userRoleId = getRoleId();

  function promptExpiry() {
    toast.warning("TOKEN HAS EXPIRED. PLEASE LOG IN AGAIN...");
    setTimeout(() => {
      removeUserSession();
      refreshPage();
    },1000);
  }

  useEffect(() => {
    var startDate = new Date().getTime();
    var endDate;

    if(tokenExpiry != null) {
      endDate = new Date(tokenExpiry.replace(/['"]+/g, ''));

      var seconds = Math.floor((endDate - startDate)/1000);
      setInterval(promptExpiry, parseFloat(seconds) * 1000);

    }
  },[tokenExpiry]);

  return (
    <div>
      <Router>
        <Routes> 
          <Route path = "/" element = {<Login />} />
          <Route path = "/login" element = {token ? <Login /> : <Navigate to="/"/>} />
          <Route path = "/dashboard" element = {token ? <Dashboard /> : <Navigate to="/" />} />
          <Route path = "/payroll" element = {token ? <Payroll /> : <Navigate to="/" />} />
          <Route path = "/payroll/print" element = {token ? <PayrollPrintPage /> : <Navigate to="/" />} />
          <Route path = "/payroll/generate" element = {token && (userRoleId==='1' || userRoleId==='2') ? <GenPayrollPage /> : <Navigate to="/" />} />
          <Route path = "/attendance" element = {token && (userRoleId==='1' || userRoleId==='2') ? <Attendance /> : <Navigate to="/" />} />
          <Route path = "/schedule" element = {token && (userRoleId==='1' || userRoleId==='2') ? <Schedule /> : <Navigate to="/" />} />
          <Route path = "/schedule/schedule_batch_entry" element = {token && (userRoleId==='1' || userRoleId==='2') ? <ScheduleBatchEntry /> : <Navigate to="/" />} />
          <Route path = "/branch" element = {token && (userRoleId==='1' || userRoleId==='2')? <Branch /> : <Navigate to="/" />} />
          <Route path = "/letter_type" element = {token ? <LetterType /> : <Navigate to="/" />} />
          <Route path = "/memo" element = {token && (userRoleId==='1' || userRoleId==='2')? <Memo /> : <Navigate to="/" />} />
          <Route path = "/memo/view" element = {token && (userRoleId==='1' || userRoleId==='2')? <ViewMemo /> : <Navigate to="/" />} />
          <Route path = "/communication_letters" element = {token && (userRoleId==='1' || userRoleId==='2')? <CommunicationLetters /> : <Navigate to="/" />} />
          <Route path = "/warningLetters/view" element = {token && (userRoleId==='1' || userRoleId==='2')? <ViewCommunicationLetters /> : <Navigate to="/" />} />
          <Route path = "/announcement" element = {token && (userRoleId==='1' || userRoleId==='2')? <Announcement /> : <Navigate to="/" />} />
          <Route path = "/announcement/view" element = {token && (userRoleId==='1' || userRoleId==='2')? <ViewAnnouncement /> : <Navigate to="/" />} />
          <Route path = "/employee" element = {token && (userRoleId==='1' || userRoleId==='2')? <Employee /> : <Navigate to="/" />} />
          <Route path = "/employee/view" element = {token ? <ViewEmployee /> : <Navigate to="/" />} />
          <Route path = "/employee/update" element = {token && (userRoleId==='1' || userRoleId==='2')? <UpdateContract /> : <Navigate to="/" />} />
          <Route path = "/leave" element = {token ? <Leave /> : <Navigate to="/" />} />
          <Route path = "/leave/view" element = {token ? <ViewLeave /> : <Navigate to="/" />} />
          <Route path = "/leave_type" element = {token ? <LeaveType /> : <Navigate to="/" />} />
          <Route path = "/holidays" element = {token && (userRoleId==='1' || userRoleId==='2')? <Holidays /> : <Navigate to="/" />} />
          <Route path = "/shifts" element = {token && (userRoleId==='1' || userRoleId==='2')? <Shifts /> : <Navigate to="/" />} />
          <Route path = "/shifts/ShiftsBatchEntry" element = {token ? <ShiftsBatchEntry /> : <Navigate to="/" />} />
          <Route path = "/employee_deductions" element = {token && (userRoleId==='1' || userRoleId==='2')? <EmployeeDeduction /> : <Navigate to="/" />} />
          <Route path = "/employee_deductions/employee_deduction_batch_entry" element = {token ? <EmployeeDeductionBatchEntry/> : <Navigate to="/" />} />
          <Route path = "/forms" element = {token ? <Forms /> : <Navigate to="/" />} />
          <Route path = "/forms/generate" element = {token ? <GenForms /> : <Navigate to="/" />} />
          <Route path = "/forms/view" element = {token ? <ViewForms /> : <Navigate to="/" />} />
          <Route path = "/forms/edit" element = {token ? <EditForms /> : <Navigate to="/" />} />
          <Route path = "/forms/survey" element = {token ? <SurveyForm /> : <Navigate to="/" />} />
          <Route path = "/project" element = {token && (userRoleId==='1' || userRoleId==='2')? <Project /> : <Navigate to="/" />} />
          <Route path = "/department" element = {token && (userRoleId==='1' || userRoleId==='2')? <Department /> : <Navigate to="/" />} />
          <Route path = "/position" element = {token && (userRoleId==='1' || userRoleId==='2')? <Position /> : <Navigate to="/" />} />
          <Route path = "/businessunit" element = {token && (userRoleId==='1' || userRoleId==='2')? <BusinessUnit /> : <Navigate to="/" />} />
          <Route path = "/employmentstatus" element = {token && (userRoleId==='1' || userRoleId==='2')? <EmploymentStatus /> : <Navigate to="/" />} />
          <Route path = "/employee_payslip" element = {token ? <EmployeePayslip /> : <Navigate to="/" />} />
          <Route path = "/work_from_anywhere" element = {token ? <WorkFromAnywhere/> : <Navigate to="/" />} />
          <Route path = "/attendance/batch_entry" element = {token && (userRoleId==='1' || userRoleId==='2')? <BatchEntry/> : <Navigate to="/" />} />
          <Route path = "/company_files" element = {token && (userRoleId==='1' || userRoleId==='2')? <CompanyFiles/> : <Navigate to="/"/>} />
          <Route path = "/employee_status" element = {token && (userRoleId==='1' || userRoleId==='2')? <EmployeeStatus/> : <Navigate to="/"/>} />
          <Route path = "/requirements" element = {token && (userRoleId==='1' || userRoleId==='2')? <Requirements/> : <Navigate to="/"/>} />
          <Route path = "/philhealth" element = {token ? <Philhealth /> : <Navigate to="/" />} />
          <Route path = "/philhealth/view" element = {token ? <ViewPhilhealth /> : <Navigate to="/" />} />
          <Route path = "/pagibig" element = {token ? <Pagibig /> : <Navigate to="/" />} />
          <Route path = "/pagibig/view" element = {token ? <ViewPagibig /> : <Navigate to="/" />} />
          <Route path = "/taxwithheld" element = {token ? <Taxwithheld /> : <Navigate to="/" />} />
          <Route path = "/taxwithheld/view" element = {token ? <ViewTaxwithheld /> : <Navigate to="/" />} />
          <Route path = "/SSS" element = {token ? <SSS /> : <Navigate to="/" />} />
          <Route path = "/SSS/view" element = {token ? <ViewSSS /> : <Navigate to="/" />} />
          <Route path = "/salaryloan" element = {token ? <Salaryloan /> : <Navigate to="/" />} />
          <Route path = "/salaryloan/view" element = {token ? <ViewSalaryloan /> : <Navigate to="/" />} />
          <Route path = "/SSScalamityloan" element = {token ? <SSScalamityloan /> : <Navigate to="/" />} />
          <Route path = "/SSScalamityloan/view" element = {token ? <ViewSSScalamityloan /> : <Navigate to="/" />} />
          <Route path = "/pagibigloan" element = {token ? <Pagibigloan /> : <Navigate to="/" />} />
          <Route path = "/pagibigloan/view" element = {token ? <ViewPagibigloan /> : <Navigate to="/" />} />
          <Route path = "/phoneloan" element = {token ? <Phoneloan /> : <Navigate to="/" />} />
          <Route path = "/phoneloan/view" element = {token ? <ViewPhoneloan /> : <Navigate to="/" />} />
          <Route path = "/loan" element = {token ? <Loan /> : <Navigate to="/" />} />
          <Route path = "loan/view" element = {token ? <ViewLoan /> : <Navigate to="/" />} />
          <Route path = "/deductions" element = {token ? <Deduction /> : <Navigate to="/" />} />
        </Routes>
      </Router>

      <ToastContainer/>
    </div>
  );
}

export default App;
