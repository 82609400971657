import { handleValidationChange } from "./CommonValidation";

export const validateLetter = (data, setIsError) => {
    var isValid = true;

    if (data.subject === "") {
        handleValidationChange("subject", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("subject", false, setIsError);
    }

    if (data.content === "") {
        handleValidationChange("content", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("content", false, setIsError);
    }

    if (data.letter_type_id === "") {
        handleValidationChange("letter_type_id", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("letter_type_id", false, setIsError);
    }

    if (data.letter_date === "") {
        handleValidationChange("letter_date", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("letter_date", false, setIsError);
    }

    if (data.letter_from === "") {
        handleValidationChange("letter_from", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("letter_from", false, setIsError);
    }

    if (data.letter_to_prime === "") {
        handleValidationChange("letter_to_prime", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("letter_to_prime", false, setIsError);
    }

    if (data.type_prime === "") {
        handleValidationChange("type_prime", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("type_prime", false, setIsError);
    }

    return isValid;
};