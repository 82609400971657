import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';

// import { validateSuppliers } from "../../helpers/Validation/Employee/EmployeeValidation";

export default function UpdateAllowance(props) {    

    const userToken = getToken();
    const userId = getUser();
    const data = props.employeeData;
    const [click, setClick] = useState(false);

    // SAVE EDITED EMPLOYEE INFORMATION
    function submitEditedEmployee(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (click===false) {
            setClick(true);
             axios({
            url: window.$link + 'employees/update/' + props.employeeId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                last_name: data.last_name,
                first_name: data.first_name,
                middle_name: data.middle_name,
                gender: data.gender,
                address: data.address,                
                contact_no: data.contact_no,
                tin: data.tin,
                sss: data.sss,
                hdmf: data.hdmf,
                phic: data.phic,
                intellicare_number: data.intellicare_number,
                axa_policy_number: data.axa_policy_number,
                email: data.email,
                birthdate: dateFormat(data.birthdate, 'MM/dd/yyyy'),
                remarks: data.remarks,
                file_name: data.file_name,
                wtaxstatus: data.wtaxstatus,
                civil_status: data.civil_status,
                nationality: data.nationality,
                dependent: data.dependent,
                religion: data.religion,
                spouse_name: data.spouse_name,
                spouse_age: data.spouse_age,
                spouse_occupation: data.spouse_occupation,
                mothers_name: data.mothers_name,
                mothers_age: data.mothers_age,
                mothers_occupation: data.mothers_occupation,
                fathers_name: data.fathers_name,
                fathers_age: data.fathers_age,
                fathers_occupation: data.fathers_occupation,
                no_of_children: data.children.length,
                children: data.children.map(t => t.name),
                age: data.children.map(t => t.age),
                occupation: data.children.map(t => t.occupation),
                contact_person: data.contact_person,
                contact_person_relationship: data.contact_person_relationship,
                contact_number: data.contact_number,
                contact_address: data.contact_address,
                dtr_id: data.dtr_id,
                branch_id: data.branch_id,
                salary_type: data.salary_type,
                employment_status_id: data.employment_status_id,
                employee_status_id: data.employee_status_id,
                employment_status: data.employment_status_id,
                position_id: data.position_id,
                position: data.position_id,
                business_unit_id: data.business_unit_id,
                immediate_head_id: data.immediate_head_id,
                project_id: data.project_id,
                department_id: data.department_id,
                date_hired: data.date_hired,
                end_of_contract: data.end_of_contract,
                date_regularized: data.date_regularized,
                previous_employer: data.previous_employer,
                current_shift_id: data.current_shift_id,
                salary_from: data.salary_from,
                salary_to: data.salary_to,
                salary: data.salary_to,
                daily_allowance: data.daily_allowance,
                commu_allowance: data.commu_allowance,
                transpo_allowance: data.transpo_allowance,
                food_allowance: data.food_allowance,
                hmo_allowance: data.hmo_allowance,
                tech_allowance: data.tech_allowance,
                ops_allowance: data.ops_allowance,
                special_allowance: data.special_allowance,
                file_name: data.file_name,
                no_of_days: data.no_of_days,
                grocery_allowance: data.grocery_allowance,
                entertainment_allowance: data.entertainment_allowance,
                medicine_allowance: data.medicine_allowance,
                uniform_allowance: data.uniform_allowance,
                position_to: data.position_id,
                position: data.position_id,
                position_date: data.position_date,
                position_from: data.position_from,
                salary_date: data.salary_date,
                salary_from: data.salary_from
            })
            }).then(function (response) {
                toast.success("Allowance Updated Successfully!");
                setTimeout(() => refreshPage(), 1500);
            }).catch(function (error) {
                toast.error("Failed to Update Allowance")
                setClick(false);
            });
        }
    }

    function handle(e, element = null) {
        let newData = { ...data };
        newData[e.target.id] = e.target.value;
        props.setEditData(newData);
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    return(
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Update Allowance
                    </div>
                    <button type="button" class="btn-close-employee"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll mt-2 p-0'>
                    <Container>
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Daily Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.daily_allowance}
                                        type="number"
                                        aria-label="daily_allowance" 
                                        aria-describedby="daily_allowance" 
                                        placeholder=""
                                        name="daily_allowance" 
                                        id='daily_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Communication Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].commu_allowance?data.allowance[0].commu_allowance:''}
                                        value={data.commu_allowance}
                                        type="number"
                                        aria-label="commu_allowance" 
                                        aria-describedby="commu_allowance" 
                                        name="commu_allowance" 
                                        id='commu_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Transportation Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].transpo_allowance?data.allowance[0].transpo_allowance:''}
                                        value={data.transpo_allowance}
                                        type="number"
                                        aria-label="transpo_allowance" 
                                        aria-describedby="transpo_allowance" 
                                        placeholder=""
                                        name="transpo_allowance" 
                                        id='transpo_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            
                        </Row>  
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Food Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].food_allowance?data.allowance[0].food_allowance:''}
                                        value={data.food_allowance}
                                        type="number"
                                        aria-label="food_allowance" 
                                        aria-describedby="food_allowance" 
                                        placeholder=""
                                        name="food_allowance" 
                                        id='food_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">HMO Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].hmo_allowance?data.allowance[0].hmo_allowance:''}
                                        value={data.hmo_allowance}
                                        type="number"
                                        aria-label="hmo_allowance" 
                                        aria-describedby="hmo_allowance" 
                                        placeholder=""
                                        name="hmo_allowance" 
                                        id='hmo_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Tech Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].tech_allowance?data.allowance[0].tech_allowance:''}
                                        value={data.tech_allowance}
                                        type="number"
                                        aria-label="tech_allowance" 
                                        aria-describedby="tech_allowance" 
                                        placeholder=""
                                        name="tech_allowance" 
                                        id='tech_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">OPS Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].ops_allowance?data.allowance[0].ops_allowance:''}
                                        value={data.ops_allowance}
                                        type="number"
                                        aria-label="ops_allowance" 
                                        aria-describedby="ops_allowance" 
                                        placeholder=""
                                        name="ops_allowance" 
                                        id='ops_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Special Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        // value={data.allowance[0].special_allowance?data.allowance[0].special_allowance:''}
                                        value={data.special_allowance}
                                        type="number"
                                        aria-label="special_allowance" 
                                        aria-describedby="special_allowance" 
                                        placeholder=""
                                        name="special_allowance" 
                                        id='special_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Grocery Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.grocery_allowance}
                                        type="number"
                                        aria-label="grocery_allowance" 
                                        aria-describedby="grocery_allowance" 
                                        placeholder=""
                                        name="grocery_allowance" 
                                        id='grocery_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>                 
                        </Row>  
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Entertainment Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.entertainment_allowance}
                                        type="number"
                                        aria-label="entertainment_allowance" 
                                        aria-describedby="entertainment_allowance" 
                                        placeholder=""
                                        name="entertainment_allowance" 
                                        id='entertainment_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Medicine Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.medicine_allowance}
                                        type="number"
                                        aria-label="medicine_allowance" 
                                        aria-describedby="medicine_allowance" 
                                        placeholder=""
                                        name="medicine_allowance" 
                                        id='medicine_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>   
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Uniform Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.uniform_allowance}
                                        type="number"
                                        aria-label="uniform_allowance" 
                                        aria-describedby="uniform_allowance" 
                                        placeholder=""
                                        name="uniform_allowance" 
                                        id='uniform_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>                 
                        </Row>
                    </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditedEmployee}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}