import {handleValidationChange} from '../Validation/CommonValidation';

export const validateAnnouncement = (data, setIsError) => {
    var isValid = true;

    if(data.announcement_date === "") {
        handleValidationChange("announcement_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("announcement_date", false, setIsError);
    }

    if(data.announcement_from === "") {
        handleValidationChange("announcement_from", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("announcement_from", false, setIsError);
    }

    if(data.subject === "") {
        handleValidationChange("subject", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("subject", false, setIsError);
    }

    if(data.content === "") {
        handleValidationChange("content", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("content", false, setIsError);
    }

    if(data.type_prime === "") {
        handleValidationChange("type_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("type_prime", false, setIsError);
    }

     if(data.announcement_to_prime === "") {
        handleValidationChange("announcement_to_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("announcement_to_prime", false, setIsError);
    }

    if(data.announcement_to_id_prime === "") {
        handleValidationChange("announcement_to_id_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("announcement_to_id_prime", false, setIsError);
    }
    

    return isValid
  
}