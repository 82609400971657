import { useState } from 'react';
import { Modal, Row, Col, Form, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

// css
import '../Modals/modal.css'
import './Payroll.css';
import "../../utilities/common.css"

export default function GenPayroll(props) {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    return(
        <div>
            {/* Generate Payroll Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                <Form>
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Generate Payroll
                        </div>
                        <button type="button" class="btn-close"  onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>Date From</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="date" name="date_from" id='date_from' className="mb-2" onChange={(e) => setDateFrom(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Date To</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="date" name="date_to" id='date_to' className="mb-2" onChange={(e) => setDateTo(e.target.value)} />
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                            <Link to="/payroll/generate" state={{from: dateFrom, to: dateTo}} className='print-payroll'>
                                <button type="submit" className='save-button'>Proceed</button>
                            </Link>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    );
}
