import {useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import { refreshPage, tokenExpired } from '../../utilities/common';
import {Row, Col, Container, Form} from 'react-bootstrap'
import NewLogo from "../../images/NewLogo.png";

// icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

// css
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';

export default function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const [data, setData] = useState({
        email: '',
        password: '',
      });
    
      let NavigationRedirection;
      
      function submit(e) {
        console.log('LOGIN');
        e.preventDefault();
          var axios = require('axios');
          var qs = require('qs');
          axios({
            url: window.$link + 'login',
            method: 'post',
            headers: {
              "api-key": window.$api_key,
              "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
              email: data.email,
              password: data.password
            })
          }).then(function (response) {
            console.log(response);
              const fullName = response.data.name;
              const firstName = fullName.split(' ').shift();
              localStorage.setItem('token', JSON.stringify(response.data.token));
              localStorage.setItem('user', JSON.stringify(response.data.id));
              localStorage.setItem('name', JSON.stringify(firstName));
              localStorage.setItem('role_id', JSON.stringify(response.data.role_id));
              localStorage.setItem('token_expiry', JSON.stringify(response.data.token_expiry));
              refreshPage();
          }).catch(function (error) {
            toast.error('Invalid Login');
            console.log(error.response.data);
            tokenExpired(error);
          });
      }
    
      function loginHandle(e) {
        const newData = { ...data };
        setData(newData);
        newData[e.target.id] = e.target.value;
      }
    
      if (window.$userToken != null) {
        NavigationRedirection = <Navigate to="/dashboard" />;
        return NavigationRedirection;
      }
    
    return(
      <div className="row login-container p-2">
        <Col xs={6} className="login-left-side">
          <div>
            <Row className='d-flex justify-content-center'>
              <img src = {NewLogo} alt={"logo"} className="login-logo" width={200} height={100}></img>
            </Row>
            <Row className='company-name text-center'>
                <span>MYT SoftDev Solutions Inc.</span>
            </Row>
            <Row className="company-address text-center mt-1">
              <span>301 The Greenery, Pope John Paul II Ave, Kasambagan, Cebu City, 6000 Cebu</span> 
            </Row>
          </div>
        </Col>
      
        <Col xs={6} className='className="d-flex justify-content-left login-right-side'>
          <Container className='login-form-container'>
            <Row>
              <p className='login-sentence'><span className="login-color">Login</span> to your account</p>
            </Row>
           
            <Row className="Email">
                <Row><h1 className='bold'>Username</h1></Row>
                <Row>
                  <Form>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><AccountCircleIcon/></span>
                      </div>
                      <input 
                          onChange={(e) => loginHandle(e)}
                          id="email"
                          value={data.email}
                          type="text"
                          className='pass-input-length login-input'
                      />
                    </div>
                  </Form>
                </Row>
            </Row>
            <Row className="Password">
                <Row><h1 className='bold'>Password</h1></Row>
                <Row>
                  <Form>
                    <div class="input-group input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><LockIcon/></span>
                      </div>
                        <input
                          onChange={(e) => loginHandle(e)}
                          id="password"
                          value={data.password}
                          className="pass-input-length login-input"
                          type={showPassword ? 'text' : 'password'}
                      />
                          {showPassword ?
                              <VisibilityOutlinedIcon fontSize='small' type='button' onClick={togglePassword} className='Password-img' /> :
                              <VisibilityOffOutlinedIcon fontSize='small' type='button' onClick={togglePassword} className='Password-img' />
                          }
                    </div>
                  </Form>
                </Row>
            </Row>
            <button className='login-btn' type="submit" onClick={submit}>Login</button>
          </Container>
        </Col>
      </div>
    );
}
