import {handleValidationChange} from '../Validation/CommonValidation';

export const validateTimesheet = (data, setIsError) => {
    var isValid = true;

    if(data.employee_id === "") {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }

    if (data.dtr_id === "") {
        handleValidationChange("dtr_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("dtr_id", false, setIsError);
    }

    if(data.time_in === "") {
        handleValidationChange("time_in", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("time_in", false, setIsError);
    }

    if(data.time_out === "") {
        handleValidationChange("time_out", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("time_out", false, setIsError);
    }

    if(data.work_date === "") {
        handleValidationChange("work_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("work_date", false, setIsError);
    }

    if(data.no_of_hrs === "") {
        handleValidationChange("no_of_hrs", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("no_of_hrs", false, setIsError);
    }

    return isValid
  
}