import React, {useState} from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getRoleId } from './common';

// icons
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/Edit';

// css
import './common.css';
import './Table.scss';

// Components
import TableFooter from './TableFooter'
import useTable from './Pagination';


function TableTemplate({tableType, tableHeaders, tableData, deleteHandler, editHandler, uploadRequirements, surveyHandler, approveHandler, updateContract, updateAllowance, givenClass, breakOn = "small"}) {
    let navigate = useNavigate();
    const userRoleId = getRoleId();

    // set all row fields in the table
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(tableData, page, rowsPerPage);

    let tableClass = "table-container__table";

    if (breakOn === "small") {
      tableClass += " table-container__table--break-sm";
    } else if (breakOn === "medium") {
      tableClass += " table-container_table--break-md";
    } else if (breakOn === "large") {
      tableClass += " table-container_table--break-lg";
    }

    const handlePrint = (e) => {
        navigate('/employee_payslip', {state:{id: e.target.id}})
    }

    const handleSelectChange = (e) => {
      const { id, value } = e.target;
      if (value === "view_employee") {
        navigate('/employee/view', {state:id})
      } else if (value === "update_contract"){
        updateContract(id);
      } else if(value === "update_allowance"){
         updateAllowance(id)
      }else if (value === "view_form") {
        navigate('/forms/view', {state:id});
      } else if (value === "edit") {
        editHandler(id);
      } else if (value === "delete") {
        deleteHandler(id);
      } else if (value === "approve") {
        approveHandler(id);
      } else if (value === "upload_requirements") {
        uploadRequirements(id);
      } else if (value === "view_memo") {
        navigate('/memo/view', {state:id});
      } else if (value === "view_leave") {
        navigate('/leave/view', {state:id});
      } else if (value === "view_letter") {
        navigate('/warningLetters/view', {state: id});
      } else if (value === "view_announcement") {
        navigate('/announcement/view', {state:id});
      } else if (value === "print_payslip") {
        navigate('/employee_payslip', {state: id});
      } else if (value === "survey", {state: id}) {
        surveyHandler(id);
      }
    };

    const rowFields = (row, index) => {
        if (tableType === 'employmenthistory') { // employment history per employee
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.date}</td>
                    <td>{row.details}</td>
                    <td>{row.salary}</td>
                </tr>
            )
        } else if (tableType === 'payroll') { // get all payroll table
            return (
                <div>
                <tr key={row.payroll_id} className="table-temp border-bottom">
                    <td>{moment(row.date_from).format('MMM D, yyyy')}</td>
                    <td>{moment(row.date_to).format('MMM D, yyyy')}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.total_earnings ? 'P ' + Number(row.total_earnings).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                    <td>{row.total_deduction ? 'P ' + Number(row.total_deduction).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                    <td>{row.total_payout ? 'P ' + Number(row.total_payout).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.payroll_id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="print_payslip">Print</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                </div>
            )
        } else if (tableType === 'print_payroll') { // print all payroll
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.id}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.basic_hrs ? parseFloat(row.basic_hrs): ''}</td>
                    <td>{row.basic_pay ? parseFloat(row.basic_pay) : ''}</td>
                    <td>{row.overtime_hrs ? (row.overtime_hrs): ''}</td>
                    <td>{row.overtime_rate ? parseFloat(row.overtime_rate) : ''}</td>
                    <td>{row.undertime_hrs ? row.undertime_hrs: ''}</td>
                    <td>{row.undertime_rate ? parseFloat(row.undertime_rate): ''}</td>
                    <td>{row.absent_days ? parseFloat(row.absent_days): ''}</td>
                    <td>{row.absent_rate ? parseFloat(row.absent_rate) : ''}</td>
                    <td>{row.sss_contribution ? row.sss_contribution:''}</td>
                    <td>{row.philhealth_contribution ? row.philhealth_contribution: ''}</td>
                    <td>{row.pagibig_contribution ? row.pagibig_contribution: ''}</td>
                    <td>{row.sss_loans ? row.sss_loans : ''}</td>
                    <td>{row.philhealth_loans ? row.philhealth_loans : ''}</td>
                    <td>{row.cooperative_loans ? row.cooperative_loans : ''}</td>
                    <td>{row.cooperative_savings ? row.cooperative_savings : ''}</td>
                    <td>{row.cash_advance ? row.cash_advance : ''}</td>
                    <td>{Number(row.grand_total).toFixed(2)}</td>
                </tr>
            )
        } else if (tableType === 'attendance') { // get all timesheet table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{row.dtr_id}</td>
                    <td>{moment(row.work_date).format('MMM D, YYYY')}</td>
                    <td>{moment(row.time_in, 'hh:mm:ss').format('h:mm A')}</td>
                    <td>{moment(row.time_out, 'hh:mm:ss').format('h:mm A')}</td>
                    <td>{row.late + ' ' + 'mins'}</td>
                    <td>
                        <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'schedule') { // get all schedule table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.full_name}</td>
                    <td>{row.shift_name}</td>
                    <td>{row.date_from}</td>
                    <td>{row.date_to}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === 'employee') { // get all employee table
            return (
                    <tr key={row.id} className="table-temp border-bottom">
                        <td>{row.name}</td>
                        <td>{row.date_hired}</td>
                        <td>{row.end_of_contract ? row.end_of_contract : ''}</td>
                        <td>{row.position ? row.position : ''}</td>
                        {/* <td>{row.salary ? row.salary :''}</td> */}
                        <td>
                            <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.id} status={row.employment_status_id} value=''>
                                <option selected hidden>Actions</option>
                                <option value="view_employee">View</option>
                                <option value="edit">Edit</option>
                                <option value="update_contract">Update Contract</option>
                                <option value="update_allowance">Update Allowance</option>
                                <option value="upload_requirements">Upload Requirements</option>
                                {
                                    userRoleId === '1' ? (
                                        <option value="delete">Delete</option>
                                    ) : ('')
                                }
                            </select>
                        </td>
                    </tr>
                )
        } else if (tableType === 'branch') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"BRANCH NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-branch" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'letter_type') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.name}</td>
                    <td>{row.description}</td>
                    <td>
                        <select className="dropdown-btn-branch" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'company_files') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td style={{textAlign:"left"}}>{row.file_attachments.length}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-branch" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'project') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"PROJECT NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-project" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                // </Link>
            )
        } else if (tableType === 'department') { // get all branch table
            return (
                // <Link to = "/department/view" state = {row.id} className='view-deets'>
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"DEPARTMENT NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-department" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                // </Link>
            )
        } else if (tableType === 'position') { // get all branch table
            return (
                // <Link to = "/position/view" state = {row.id} className='view-deets'>
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"POSITION NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-position" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                // </Link>
            )
        }  else if (tableType === 'businessunit') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"BUSINESS UNIT NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-unit" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        }  else if (tableType === 'employmentstatus') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"EMPLOYMENT STATUS NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-status" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'employee_status') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td>
                        <select className="dropdown-btn-status" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'requirements') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td>
                        <select className="dropdown-btn-status" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        }  else if (tableType === 'memo') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{moment(row.memo_date).format('MMM D, YYYY')}</td>
                    <td>{row.subject}</td>
                    <td>{row.memo_from}</td>
                    <td> {
                        (function() {
                            let items = row.details?row.details.map(t => t.memo_to):[];
                            var finalItems = [];
                            items.map((item) => {
                                var i = [];
                                if (item) {
                                    finalItems.push(item);
                                }
                            })
                            let to = row.details?row.details.map(t => t.memo_to):[];
                            return finalItems.join(', ');
                        })()
                    }
                    </td>
                    <td>{row.status}</td>
                    <td>
                        <select className="dropdown-btn-memo" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_memo">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'warning') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.subject}</td>
                    <td>{row.type}</td>
                    <td>{row.employee_name}</td>
                    <td>{moment(row.warning_date).format('MMM D, YYYY')}</td>
                    <td>{row.status}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-warning" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_letter">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>

                </tr>
                // </Link>
            )
        } else if (tableType === 'announcement') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td> {moment(row.announcement_date).format('MMM D, YYYY')}</td>
                    <td>{row.subject}</td>
                    <td>{row.announcement_from}</td>
                    <td> {
                        (function() {
                            let items = row.details?row.details.map(t => t.announcement_to):[];
                            var finalItems = [];
                            items.map((item) => {
                                var i = [];
                                if (item) {
                                    finalItems.push(item);
                                }
                            })
                            let to = row.details?row.details.map(t => t.announcement_to):[];
                            return finalItems.join(', ');
                        })()
                    }
                    </td>
                    <td>{row.status}</td>
                    <td>
                        <select className="dropdown-btn-announcement" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_announcement">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'leave') { // get all leave table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.date_start}</td>
                    <td>{row.no_of_days}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.leave_type}</td>
                    <td>{row.status}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_leave">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            {
                                (userRoleId === '1' || userRoleId === '2') ? (
                                    <option value="edit">Edit</option>
                                ) : ('')
                            }
                            
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'leave_type') { // get all leave type table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.description}</td>
                    <td>{row.credits}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                        </select>
                    </td>
                </tr>
            )
        }  else if (tableType === 'wfa') { // get all leave table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.date_start}</td>
                    <td>{row.no_of_days}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.status}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_wfa">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            {
                                (userRoleId === '1' || userRoleId === '2') ? (
                                    <option value="edit">Edit</option>
                                ) : ('')
                            }
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'holidays') { // get all holidays table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.date}</td>
                    <td>{row.name}</td>
                    <td>{row.bonus}</td>
                    <td >{row.type==="Special_nonworking"?"Special Nonworking":(row.type==="Special_working"?"Special Working":row.type)}</td>
                    <td>{row.with_pay}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-holidays" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'shift') { // get all shift table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"EMPLOYEE NAME"}>{row.name}</td>
                    <td data-heading={"DEDUCTION DATE"}>{row.time_in}</td>
                    <td data-heading={"SSS"}>{row.time_out}</td>
                    <td data-heading={"PHILHEALTH"}>{row.overtime_fee}</td>
                    <td data-heading={"PAG-IBIG"}>{row.break_duration}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-shift" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>                    
            )
        } else if (tableType === 'deductions') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{row.issue_date}</td>
                    <td>{row.sss_amount}</td>
                    <td>{row.phic_amount}</td>
                    <td>{row.hdmf_amount}</td>
                    <td>{row.intellicare_amount}</td>
                    <td>{row.axa_amount}</td>
                    {/* <td>{row.phone_loan}</td>
                     <td>{row.laptop_loan}</td>
                    <td>{row.cash_advance}</td> */}
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === 'forms') { // get all forms table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td style={{textAlign:"left"}}>{row.added_on?moment(row.added_on).format('MMMM D, yyyy'):''}</td>
                    <td style={{textAlign:"left"}}>{row.deadline?moment(row.deadline).format('MMMM D, yyyy'):''}</td>
                    <td style={{textAlign:"left"}}>{row.status}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_form">View</option>
                            {
                                (userRoleId === '1' || userRoleId === '2') ? (
                                    <option value="edit">Edit</option>
                                ) : ('')
                            }
                            <option value="survey">Take Survey</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } 

    }

    // table pagination
    const finalTable = () => {
        if (tableType !== null) {
            return (
                // tableData.map((rawRow, index) => {
                slice.map((rawRow, index) => {
                    return (
                        rowFields(rawRow, index)
                    )
                })
            )
        }
    }

    return (
        <>
			<div className="ebr-table-wrapper me-3">
                <Table borderless size="s" className={tableClass + " ebr-table"}>
                    <thead className='table-text'>
                        <tr>
                            {tableHeaders.map((header, index) => {
                                return (
                                    <th key={index} className=''>{header}</th>
                                )
                            })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <div className='text-left table-text'>
                            {finalTable()}
                        </div>
                    </tbody>
                </Table>
                
                <TableFooter
                    range={range}
                    slice={slice}
                    setPage={setPage}
                    page={page}
                    footerClass={givenClass}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                />
			</div>
        </>
    )
}

export default TableTemplate;