import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import { validateLeaveType } from '../../helpers/Validation/Manage/LeaveTypeValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditLeaveType(props) {
    const userToken = getToken();
    const userId = getUser();

    // Validation
    const [isError, setIsError] = useState({
        name: false
    });
    const [click, setClick] = useState(false);
    const [editLeaveTypeData, setEditLeaveData] = useState(props.editLeaveTypeData);
    
    // SUBMIT EDITED LEAVE
    function submitEditLeave(e) {
        var axios = require('axios');
        var qs = require('qs');

        if (validateLeaveType(editLeaveTypeData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'leave_types/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                leave_type_id: props.editLeaveTypeData.id,
                description: editLeaveTypeData.name
            })
            })
           
            .then(function (response) {
                toast.success("Leave Type Edited Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Edit Leave Type");
                console.log(error.response.data.messages);
                tokenExpired(error);
                setClick(false);
            });
            
        }
    }

    function handle(e) {
        let editedLeaveData = { ...editLeaveTypeData };
        editedLeaveData[e.target.id] = e.target.value;
        setEditLeaveData(editedLeaveData);
    }

    function renderInputError(var_name) {
        let value = editLeaveTypeData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Leave
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll mt-2 p-0'>
                        <Container>
                            <Row>
                                <Form.Label className="h6" htmlFor="name">Leave Name <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={props.editLeaveTypeData?props.editLeaveTypeData.description:""}
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('name')}
                                </InputGroup>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="name">Credits</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={props.editLeaveTypeData?props.editLeaveTypeData.credits:""}
                                        type="text"
                                        aria-label="credits" 
                                        aria-describedby="credits" 
                                        placeholder="" 
                                        name="credits" 
                                        id='credits' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Row>
                        </Container>
                    </div>
                    
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditLeave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}