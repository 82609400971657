import React, { useState } from "react";
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import {Row, Col, Form, InputGroup} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import AddAttendance from './AddAttendance';
import EditPrompt from './UpdateAttendance';
import ExportExcelButton from "../../utilities/export_excel_button.js"
import { CSVLink} from "react-csv";

//icons
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import UploadIcon from '@mui/icons-material/Upload';

// css
import './Attendance.css'
import '../Modals/modal.css'
import "../../utilities/common.css"

//  components
import { validateTimesheet } from "../../helpers/Validation/TimesheetValidation";

function Attendance() {
    const userToken = getToken();
    const userId = getUser();
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const handleShowDelete = () => setShowDelete(true);
    const [showAddAttendance, setShowAddAttendance] = useState(false);
    const [allAttendances, setAllAttendances] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [attendanceID, setAttendanceID] = useState();
    const [attendance, setAttendance] = useState([]);
    const [loading, setLoading] = useState(true);
    const [click, setClick] = useState(false);

    const [isError, setIsError] = useState({
        employee_id: false,
        work_date: false,
        time_in: false,
        time_out: false,
        no_of_hrs: false
    });

    const currentDate = new Date();

    const [filterDate, setFilterDate] = useState({
        date_from: currentDate,
        date_to: currentDate
    })

    function onClickDelete(id) {
        setAttendanceID(id);
        setShowDelete(true);
    }

    function onClickEdit(id) {
        getTimesheetById(id);
        setAttendanceID(id);
        setShowEdit(true);
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    // Function to get timesheet details by timesheet Id
    function getTimesheetById (timesheetId) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'timesheets/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                timesheet_id: timesheetId
            }
        }).then(response => {
            setAttendance(response.data.data[0])
        }).catch(function (error) {
            console.log('Error getting all timesheets');
            tokenExpired(error);
        });
    }

    // Update attendance API
    function submitUpdateAttendance(e) {
        if (validateTimesheet(attendance, setIsError)==true && click===false) {
            setClick(true);
            var axios = require('axios');
            var qs = require('qs');
            axios({
            url: window.$link + 'timesheets/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: attendance.employee_id,
                dtr_id: attendance.dtr_id,
                work_date: dateFormat(attendance.work_date, 'MM/dd/yyyy'),
                timesheet_id: attendance.id,
                time_in: attendance.time_in,
                time_out: attendance.time_out,
                no_of_days: attendance.no_of_days,
                no_of_hrs: attendance.no_of_hrs,
                no_of_mins: attendance.no_of_mins,
                late: attendance.late
            })
            })
            .then(function (response) {
                toast.success("Attendance Updated Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error('Fail to Update Attendance!');
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');

        axios({
            url: window.$link + 'timesheets/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                timesheet_id: attendanceID
            })
        })
        .then(function (response) {
            toast.success("Timesheet Deleted Successfully!");
            setTimeout(refreshPage(), 1000);
        })
        .catch(function (error) {
            toast.error('Deleting Error!');
            console.log(error.response.data);
            tokenExpired(error);
        });
    }

    // Get all attendances
    React.useEffect(() => {
        allAttendances.length = 0;
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'timesheets/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                timesheet_id: ''
            }
        }).then(response => {
            response.data.data.sort((a, b) => new Date(b.work_date.split('/').reverse()) - new Date(a.work_date.split('/').reverse()));
            setAllAttendances(response.data.data)
            setLoading(false)
        }).catch(function (error) {
            setLoading(false)
            console.log('Error getting all timesheets');
            tokenExpired(error);
        });
    }, [filterDate]);


    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">

                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={3} className='d-flex comm-header-employee'>
                            Timesheet  
                        </Col>
                        <Col className="d-flex justify-content-end me-4">
                            <Row className="col-9">
                                <Col xs={3} className="me-5">
                                    <CSVLink data={allAttendances} filename={'Timesheet'}><ExportExcelButton/></CSVLink>
                                </Col>
                                <Col xs={3} className="me-5 ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => setShowAddAttendance(true)}>
                                        <span className="export-text"><CalendarTodayOutlinedIcon/> Add Timesheet</span>
                                    </button>
                                </Col>
                                <Col xs={3} className="ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => navigate('batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>

                {/* Date Filter */}
                <Row className="px-3 mt-5">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row>

                {/* TABLE HERE */}
                <div className="attendance-wrapper mt-2">
                    {
                        (function() {
                            return !loading 
                            ? (
                            <TableTemplate
                                tableType='attendance'
                                tableHeaders={["Employee Name", "DTR ID", "Date", "Time In", "Time Out", "Late", ""]}
                                tableData={allAttendances}
                                deleteHandler={onClickDelete}
                                editHandler={onClickEdit}
                            />
                            ) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }

                    <DeletePrompt
                        name = "ATTENDANCE"
                        show = {showDelete}
                        hide = {() => setShowDelete(false)}
                        remover = {handleRemove}
                    />

                    <EditPrompt
                        show = {showEdit}
                        hide = {() => setShowEdit(false)}
                        attendance = {attendance}
                        setAttendance = {setAttendance}
                        submitUpdateAttendance = {submitUpdateAttendance}
                        isError = {isError}
                    />

                    <AddAttendance show={showAddAttendance} hide={() => setShowAddAttendance(false)}/> 

                </div>
            </div>
        </div>
    );
}

export default Attendance;