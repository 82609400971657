import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Container, InputGroup } from 'react-bootstrap';
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import Navbar from "../Navbar/Navbar"
import {GetAllBusinessUnits, GetAllDepartments, GetAllProjects, GetAllPositions, GetAllBranches, GetAllEmploymentStatuses} from '../../helpers/CommonApi'
import {toast} from 'react-toastify'

import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { validateForm } from '../../helpers/Validation/FormValidation';
import InputError from '../../helpers/InputError/InpuError';

// css
import './Forms.css'
import '../../utilities/common.css'


export default function GenForms() {
    let navigate = useNavigate();
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [isError, setIsError] = useState({
        name: false,
        description: false,
        deadline: false,
        recipient_type_prime: false,
        form_to_prime: false,
        type_prime: false,
        question_prime: false,
        choice_prime: false,
    });

    const [data, setData] = useState({
        name: '',
        description: '',
        deadline: '',
        question_set: [{
            type: '',
            question: '',
            choices: []
        }],
        recipients: [{
            recipient_type: '',
            form_to: ''
        }],
        recipient_type_prime: '',
        form_to_prime: '',
        type_prime: '',
        question_prime: '',
    });

    const allBusinessUnits = GetAllBusinessUnits();
    const allProjects = GetAllProjects();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();

    function handle (e, element=null, choice_index=null) {
        let newFormData = {...data};
        let questionIndex = data["question_set"].map(t => t.id).indexOf(element.id);

        if (e.target.id == "recipient_type" || e.target.id == "form_to") {
            let index = data["recipients"].map(t => t.id).indexOf(element.id);
            newFormData["recipients"][index][e.target.id] = e.target.value;
        } else if (e.target.id == "recipient_type_prime") {
            let index = data["recipients"].map(t => t.id).indexOf(element.id);
            newFormData[e.target.id] = e.target.value;
            newFormData["recipients"][index].recipient_type = e.target.value;
        } else if (e.target.id == "form_to_prime") {
            let index = data["recipients"].map(t => t.id).indexOf(element.id);
            newFormData[e.target.id] = e.target.value;
            newFormData["recipients"][index].form_to = e.target.value;
        } else if (e.target.id == "type" || e.target.id == "question" && element!=null) {
            newFormData["question_set"][questionIndex][e.target.id] = e.target.value;
        } else if (e.target.id == "type_prime" && element!=null) {
            newFormData[e.target.id] = e.target.value;
            newFormData["question_set"][questionIndex].type = e.target.value;
        } else if (e.target.id == "question_prime" && element!=null) {
            newFormData[e.target.id] = e.target.value;
            newFormData["question_set"][questionIndex].question = e.target.value;
        } else if (e.target.id == "choices") {
            newFormData["question_set"][questionIndex]["choices"][choice_index].option = e.target.value;
        } else {
            newFormData[e.target.id] = e.target.value;
        }
        setData(newFormData);
    }


    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addQuestion(e) {
        var recipients = data.question_set;
        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            question:'',
            choices: []
        });

        setData((prev) => {
            return {
                ...prev,
                question_set: recipients
            }
        })
    }

    function removeQuestion(e, element) {
        var recipients = data.question_set;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type==='') {
            data.type_prime = '';
            data.question_prime='';
        }

        setData((prev) => {
            return {
                ...prev,
                question_set: recipients
            }
        })
    }

    function removeOption(e, element, index) {
        var recipients = data["question_set"][index]["choices"];
        for(let i=0;i<recipients.length;i++) {
            if (recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        var rec = data.question_set;

        setData ((prev) => {
            return {
                ...prev, question_set: rec
            }
        })
    }

    function addOption(e, passed_data, index) {
        var recipients = data["question_set"][index]["choices"];
        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(),
            option: ''
        });
        var rec = data.question_set;

        setData((prev) => {
            return {
                ...prev, question_set: rec
            }
        })
    }

    function addRecipient(e) {
        var recipients = data.recipients;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            form_to:''
        });

        setData((prev) => {
            return {
                ...prev,
                recipients: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = data.recipients;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type==='') {
            data.recipient_type_prime = '';
            data.form_to_prime='';
        }

        setData((prev) => {
            return {
                ...prev,
                recipients: recipients
            }
        })
    }

    // API to submit forms
    function submitForm () {
        var axios = require('axios');
        var qs = require('qs');

        if (validateForm(data, setIsError)===true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'forms/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: data.name,
                deadline: data.deadline,
                description: data.description,
                types: data.recipients.map((t) => {return(t.recipient_type)}),
                recipients: data.recipients.map((t) => {return(t.form_to)}),
                question_types: data.question_set.map((t) => {return(t.type)}),
                questions: data.question_set.map((t) => {return(t.question)}),
                choices: data.question_set.map((t) => {return(t.choices&&t.choices.length ? t.choices : null)})
            })
            })
            .then(function (response) {
                toast.success("Form Added Successfully!");
                setTimeout(() => {navigate(-1);
                setTimeout(() => refreshPage(), 1000)}, 1000);
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Failed to Add Form");
                setClick(false);
            });
        }
    }

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }
    
    function renderInputError(var_name) {
        var value = '';
        if (var_name=="recipient_type") {
            data.recipients.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="form_to") {
            data.recipients.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="type") {
            data.question_set.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="question") {
            data.question_set.map((item) => {
                value = item[var_name];
            })
        } else {
            value = data[var_name];
        }
        if (!value || isOnlyWhiteSpace(value)) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className="comm-bg">
            <Navbar />
            <div className="comm-page-container">
                <Row className="" style={{    
                    width: "100%",
                    height: "3em",
                    }}>
                    <Row className="m-0">
                        <Col xs={8} className='d-flex comm-header-branch'>
                            Generate Evaluation Form   
                        </Col>
                    </Row>
                </Row>

                <div className="ebr-container-wrapper me-3 mt-3">
                    <Container className='px-5 pt-2'>
                        <Row>
                            <Col xs={6}>
                                <Form.Label className="h6" htmlFor="name">Form Name<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        className='input-field'
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e,'','')}
                                    />
                                {renderInputError('name')}
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <Form.Label className="h6" htmlFor="name">Deadline<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        className='input-field'
                                        type="date"
                                        aria-label="deadline" 
                                        aria-describedby="deadline" 
                                        placeholder="" 
                                        name="deadline" 
                                        id='deadline' 
                                        onChange={(e) => handle(e,'','')}
                                    />
                                {renderInputError('deadline')}
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label className="h6" htmlFor="description">Description<span className='red'> *</span></Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    className='input-field'
                                    type="text"
                                    aria-label="description" 
                                    aria-describedby="description" 
                                    placeholder="" 
                                    name="description" 
                                    id='description' 
                                    onChange={(e) => handle(e,'','')}
                                />
                                {renderInputError('description')}
                            </InputGroup>
                        </Row>
                    </Container>
                </div>

                <div className="ebr-container-wrapper me-3 mt-3 p-3">
                    <Container>
                        {data["recipients"].map((element, i) => {
                                if (i===0) {
                                    return (
                                    <Row key={element.key}>
                                        <Col xs={5}>
                                            <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="recipient_type_prime" id='recipient_type_prime' onChange={(e) => handle(e, element)}>
                                                <option value=""> --- </option>
                                                <option value="branch">Branch</option>
                                                <option value="business_unit">Business Unit</option>
                                                <option value="department">Department</option>
                                                <option value="employment_status">Employment Status</option>
                                                <option value="position">Position</option>
                                                <option value="project">Project</option>
                                                </Form.Select>
                                            {renderInputError('recipient_type_prime')}
                                            </InputGroup>
                                        </Col>
                                        <Col xs={5}>
                                            <Form.Label className="h6" htmlFor="form_to_prime">Form To <span className='red'> *</span></Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    as="select"
                                                    aria-label="form_to_prime" 
                                                    aria-describedby="form_to_prime" 
                                                    placeholder="" 
                                                    name="form_to_prime" 
                                                    id='form_to_prime' 
                                                    onChange={(e) => handle(e, element,'')}
                                                >
                                                <option value=""> --- </option>
                                                    {
                                                        (element.recipient_type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):(element.recipient_type == "project")?(allProjects.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):(element.recipient_type == "position")?(allPositions.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):(element.recipient_type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):((element.recipient_type == "branch")?(allBranches.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):((element.recipient_type == "department")?(allDepartments.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):""))
                                                    }
                                                </Form.Control>
                                                {renderInputError('form_to_prime')}
                                            </InputGroup>
                                        </Col>
                                        <Col xs={2}>
                                            <Form.Label className="h6"></Form.Label>
                                            <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                )
                                } else {
                                    return (
                                    <Row key={element.key}>
                                        <Col xs={5}>
                                            <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="recipient_type" id='recipient_type' onChange={(e) => handle(e, element)}>
                                                <option value=""> --- </option>
                                                <option value="branch">Branch</option>
                                                <option value="business_unit">Business Unit</option>
                                                <option value="department">Department</option>
                                                <option value="employment_status">Employment Status</option>
                                                <option value="position">Position</option>
                                                <option value="project">Project</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={5}>
                                            <Form.Label className="h6" htmlFor="form_to">Form To</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    as="select"
                                                    aria-label="form_to" 
                                                    aria-describedby="form_to" 
                                                    placeholder="" 
                                                    name="form_to" 
                                                    id='form_to' 
                                                    onChange={(e) => handle(e, element,'')}
                                                >
                                                <option value=""> --- </option>
                                                    {
                                                        (element.recipient_type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):(element.recipient_type == "project")?(allProjects.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):(element.recipient_type == "position")?(allPositions.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):(element.recipient_type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):((element.recipient_type == "branch")?(allBranches.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):((element.recipient_type == "department")?(allDepartments.map( (x,y) => 
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        )):""))
                                                    }
                                                </Form.Control>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={2}>
                                            <Form.Label className="h6"></Form.Label>
                                            <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                )
                                }
                            }
                            )}
                    <Row className='justify-content-end'>
                        <Col xs={2} className='me-4'>
                            <button id="addRow" className="add-button me-3" onClick={(e) => addRecipient(e)}>+ Add Recipient</button>
                        </Col>
                    </Row>
                    </Container>
                </div>

                <div className="create-form-container me-3 mt-3 p-3">
                    <Container>
                        {data["question_set"].map((element, i) => {
                            var index = i;
                            var el = element;
                            if (i===0) {
                                return (
                                    <Row key={element.key}>
                                        <Col xs={3}>
                                            <Form.Label className="h6" htmlFor="type_prime">Type <span className='red'> *</span></Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    as="select"
                                                    aria-label="type_prime" 
                                                    aria-describedby="type_prime" 
                                                    placeholder="" 
                                                    name="type_prime" 
                                                    id='type_prime' 
                                                    onChange={(e) => handle(e, element,'')}
                                                >
                                                <option value="">---</option>
                                                <option value="short">Short Answer</option>
                                                <option value="long">Paragraph</option>
                                                {/* <option value="boolean">True or False</option> */}
                                                <option value="multiple_choice">Multiple Choice</option>
                                                </Form.Control>
                                                {renderInputError('type_prime')}
                                            </InputGroup>
                                        </Col>
                                        <Col xs={7}>
                                            <Form.Label className="h6" htmlFor="question_prime">Question <span className='red'> *</span></Form.Label>
                                            <InputGroup className="mb-3">
                                                {
                                                    (element.type == "short") ? (
                                                        <>
                                                        <Form.Control
                                                            className='input-field'
                                                            type="text"
                                                            aria-label="question_prime" 
                                                            aria-describedby="question_prime" 
                                                            placeholder="Short answer question" 
                                                            name="question_prime" 
                                                            id='question_prime' 
                                                            onChange={(e) => handle(e, element,'')}
                                                        />
                                                        {renderInputError('question_prime')}
                                                        </>
                                                    ):((element.type == "long") ? (
                                                        <>
                                                        <Form.Control
                                                            className='input-field'
                                                            type="text"
                                                            aria-label="question_prime" 
                                                            aria-describedby="question_prime" 
                                                            placeholder="Unlimited answer question" 
                                                            name="question_prime" 
                                                            id='question_prime' 
                                                            onChange={(e) => handle(e, element,'')}
                                                        />
                                                        {renderInputError('question_prime')}</>
                                                        
                                                    ): ((element.type == "multiple_choice") ? (
                                                    <InputGroup>
                                                        <InputGroup>                                                        
                                                            <>
                                                            <Form.Control
                                                                className='input-field'
                                                                type="text"
                                                                aria-label="question_prime" 
                                                                aria-describedby="question_prime" 
                                                                placeholder="Multiple choice question" 
                                                                name="question_prime" 
                                                                id='question_prime' 
                                                                onChange={(e) => handle(e, element,'')}
                                                            />
                                                            {renderInputError('question_prime')}</>
                                
                                                            {
                                                                element['choices'].map((data, i) => {
                                                                    return (
                                                                        <InputGroup className='mt-2' key={data.key}>
                                                                            <Col xs={1} className='primary-color m-0 justify-content-center'><RadioButtonCheckedIcon/></Col>
                                                                            <Col xs={6} className='me-3'>
                                                                                <Form.Control
                                                                                    className='input-field'
                                                                                    type="text"
                                                                                    aria-label="choices" 
                                                                                    aria-describedby="choices" 
                                                                                    placeholder="" 
                                                                                    name="choices" 
                                                                                    id='choices' 
                                                                                    onChange={(e) => handle(e,el,i)}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Row className="mb-3">
                                                                                    <Col xs={2}><button id="removeOption" className="trash-icon" onClick={(e) => removeOption(e, data, i)}><DeleteIcon/></button></Col>
                                                                                    {/* <Col xs={2}><button id="addOption" className="add-icon" onClick={(e) => addOption(e, data, index)}><AddCircleIcon/></button></Col> */}
                                                                                </Row>
                                                                            </Col>
                                                                        </InputGroup>
                                                                    )
                                                                })
                                                            }
                                                            
                                                        </InputGroup>
                                                        <Row className="mb-3 mt-2">
                                                            <Col><button id="addOption" className="add-button" onClick={(e) => addOption(e, data, index)}>Add Option</button></Col>
                                                        </Row>
                                                    </InputGroup>
                                                    ):""))
                                                }
                                            </InputGroup>
                                        </Col>
                                         <Col xs={2}>
                                            <Form.Label className="h6"></Form.Label>
                                            <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeQuestion(e, element)}>Remove</button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                )
                            } else {
                                return (
                                    <Row key={element.key}>
                                        <Col xs={3}>
                                            <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    as="select"
                                                    aria-label="type" 
                                                    aria-describedby="type" 
                                                    placeholder="" 
                                                    name="type" 
                                                    id='type' 
                                                    onChange={(e) => handle(e, element,'')}
                                                >
                                                <option value="">---</option>
                                                <option value="short">Short Answer</option>
                                                <option value="long">Paragraph</option>
                                                {/* <option value="boolean">True or False</option> */}
                                                <option value="multiple_choice">Multiple Choice</option>
                                                </Form.Control>
                                                {renderInputError('type')}
                                            </InputGroup>
                                        </Col>
                                        <Col xs={7}>
                                            <Form.Label className="h6" htmlFor="question">Question</Form.Label>
                                            <InputGroup className="mb-3">
                                                {
                                                    (element.type == "short") ? (
                                                        <>
                                                        <Form.Control
                                                            className='input-field'
                                                            type="text"
                                                            aria-label="question" 
                                                            aria-describedby="question" 
                                                            placeholder="Short answer question" 
                                                            name="question" 
                                                            id='question' 
                                                            onChange={(e) => handle(e, element,'')}
                                                        />
                                                        {renderInputError('question')}
                                                        </>
                                                    ):((element.type == "long") ? (
                                                        <>
                                                        <Form.Control
                                                            className='input-field'
                                                            type="text"
                                                            aria-label="question" 
                                                            aria-describedby="question" 
                                                            placeholder="Unlimited answer question" 
                                                            name="question" 
                                                            id='question' 
                                                            onChange={(e) => handle(e, element,'')}
                                                        />
                                                        {renderInputError('question')}</>
                                                        
                                                    ): ((element.type == "multiple_choice") ? (
                                                    <InputGroup>
                                                        <InputGroup>                                                        
                                                            <>
                                                            <Form.Control
                                                                className='input-field'
                                                                type="text"
                                                                aria-label="question" 
                                                                aria-describedby="question" 
                                                                placeholder="Multiple choice question" 
                                                                name="question" 
                                                                id='question' 
                                                                onChange={(e) => handle(e, element,'')}
                                                            />
                                                            {renderInputError('question')}</>
                                
                                                            {
                                                                element['choices'].map((data, i) => {
                                                                    return (
                                                                        <InputGroup className='mt-2' key={data.key}>
                                                                            <Col xs={1} className='primary-color m-0 justify-content-center'><RadioButtonCheckedIcon/></Col>
                                                                            <Col xs={6} className='me-3'>
                                                                                <Form.Control
                                                                                    className='input-field'
                                                                                    type="text"
                                                                                    aria-label="choices" 
                                                                                    aria-describedby="choices" 
                                                                                    placeholder="" 
                                                                                    name="choices" 
                                                                                    id='choices' 
                                                                                    onChange={(e) => handle(e,el,i)}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Row className="mb-3">
                                                                                    <Col xs={2}><button id="removeOption" className="trash-icon" onClick={(e) => removeOption(e, data, i)}><DeleteIcon/></button></Col>
                                                                                    {/* <Col xs={2}><button id="addOption" className="add-icon" onClick={(e) => addOption(e, data, index)}><AddCircleIcon/></button></Col> */}
                                                                                </Row>
                                                                            </Col>
                                                                        </InputGroup>
                                                                        
                                                                    )
                                                                })
                                                            }
                                                            
                                                        </InputGroup>
                                                        <Row className="mb-3 mt-2">
                                                            <Col><button id="addOption" className="add-button" onClick={(e) => addOption(e, data, index)}>Add Option</button></Col>
                                                        </Row>
                                                    </InputGroup>
                                                    ):""))
                                                }
                                            </InputGroup>
                                        </Col>
                                        <Col xs={2}>
                                            <Form.Label className="h6"></Form.Label>
                                            <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeQuestion(e, element)}>Remove</button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                )
                            }
                            })}
                    </Container>
                <Row className='mt-2 justify-content-end '>
                    <Col xs={2} className='me-5'>
                        <button id="addRow" className="add-button" onClick={(e) => addQuestion(e)}>+ Add Question</button>
                    </Col>
                </Row>
                    {/* <Row className='d-flex justify-content-end me-3'>
                        <Col className="me-5">
                        <button className="back-button" onClick={() => navigate(-1)}>
                                <span className="back-text"> <ArrowBackIcon/>  BACK</span>
                        </button>
                        </Col>
                    </Row> */}
                </div>
                 <div className='d-flex justify-content-end me-3 mt-4 mb-4'>
                    <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                    <button type="submit" className='save-button' onClick={submitForm}>Save</button>
                </div>
            </div>
        </div>
    );
}