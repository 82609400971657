import {handleValidationChange} from '../CommonValidation';

export const validateEmployeeDeduction = (data, setIsError) => {
    var isValid = true;

    if(data.employee_id === "") {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }

    if(data.issue_date === "") {
        handleValidationChange("issue_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("issue_date", false, setIsError);
    }

    if(data.sss_amount === "") {
        handleValidationChange("sss_amount", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("sss_amount", false, setIsError);
    }

    if(data.phic_amount === "") {
        handleValidationChange("phic_amount", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("phic_amount", false, setIsError);
    }

    if(data.hdmf_amount === "") {
        handleValidationChange("hdmf_amount", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("hdmf_amount", false, setIsError);
    }

    return isValid
  
}