import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import * as Icon from 'react-bootstrap-icons';

import { validateProject } from '../../helpers/Validation/Manage/ProjectValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function AddProject(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        name: ''
    });

    const [isError, setIsError] = useState({
        name: ''
    });

    function handle(e, element = null) {
        let newProjectData = { ...data };
        newProjectData[e.target.id] = e.target.value;
        setData(newProjectData);
        // console.log(data);
    }

    // Add Project API
    function submitAddProject(e) {
        var axios = require('axios');
        var qs = require('qs');
        console.log(data);
        if(validateProject(data, setIsError) == true && click==false) {
            setClick(true);
            axios({
            url: window.$link + 'projects/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: data.name
            })
            })
            .then(function (response) {
                console.log(response);
                toast.success("Project Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                setClick(false);
                toast.error("Project Not Added Successfully!");
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
        }
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        // console.log(value)
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Project
                    </div>
                    {/* <hr className='modal-line'/> */}
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Project Name<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        {/* <InputGroup.Text><Icon.FilePerson /></InputGroup.Text> */}
                                        <Form.Control
                                            className='input-field'
                                            type="text"
                                            aria-label="name" 
                                            aria-describedby="name" 
                                            placeholder="" 
                                            name="name" 
                                            id='name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {/* <InputError
                                        isValid={isError.name}
                                        message={"This field is required."}
                                    /> */}
                                    {renderInputError('name')}
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddProject}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}