import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import DatePicker from 'react-datepicker';
import {validateEmployee} from '../../helpers/Validation/Employee/EmployeeValidation'
import InputError from '../../helpers/InputError/InpuError';

export default function UpdateContract(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const data = props.employeeData;
    const [selectedStartDate, setSelectedStartDate] = useState(data.date_hired);
    const [selectedEndDate, setSelectedEndDate] = useState(data.end_of_contract);
    const [selectedDateRegularized, setSelectedDateRegularized] = useState(null);
    const branches = props.branches;
    const employmentStatus = props.employmentStatus;
    const positions = props.positions;
    const businessUnits = props.businessUnits;
    const allEmployees = props.allEmployees;
    const projects = props.projects;
    const departments = props.departments;
    const shifts = props.shifts;
    const employeeStatus = props.employeeStatus;

    //REQUIRED ERROR HANDLING
    const [isError, setIsError] = useState({
        dtr_id: false,
        branch_id: false,
        business_unit_id: false,
        employment_status_id: false,
        current_shift_id: false,
        salary_type: false,
        salary_to: false,
        department_id: false,
        position_id: false,
        date_hired: false
    });
   

/* SAVE EDITED EMPLOYEE INFORMATION. Note that the position & salary 
are updated separately for the employment history report. */
    function submitEditEmployee(e) {
        var axios = require('axios');
        var qs = require('qs');
        var genTimeOut = true;
        var positionTimeOut = true;
        var salaryTimeOut = true;
        var genClick = false;
        var positionClick = false;
        var salaryClick = false;

        var positionDate, positionFrom;
        var salaryDate, salaryFrom;
        if (data.position_id !== data.current_position) {
            positionDate = new Date();
            positionFrom = data.current_position;
        } else {
            positionDate = data.position_date;
            positionFrom = data.position_from;
        }

        if (data.salary_to !== data.current_salary) {
            salaryDate = new Date();
            salaryFrom = data.current_salary;
        } else {
            salaryDate = data.salary_date;
            salaryFrom = data.salary_from;
        }

        if (validateEmployee(data, setIsError) ===true && click===false) {

            axios({
            url: window.$link + 'employees/update/' + props.employeeId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                last_name: data.last_name,
                first_name: data.first_name,
                middle_name: data.middle_name,
                gender: data.gender,
                address: data.address,                
                contact_no: data.contact_no,
                tin: data.tin,
                sss: data.sss,
                hdmf: data.hdmf,
                phic: data.phic,
                intellicare_number: data.intellicare_number,
                axa_policy_number: data.axa_policy_number,
                email: data.email,
                birthdate: data.birthdate,
                remarks: data.remarks,
                file_name: data.file_name,
                wtaxstatus: data.wtaxstatus,
                civil_status: data.civil_status,
                nationality: data.nationality,
                dependent: data.dependent,
                religion: data.religion,
                spouse_name: data.spouse_name,
                spouse_age: data.spouse_age,
                spouse_occupation: data.spouse_occupation,
                mothers_name: data.mothers_name,
                mothers_age: data.mothers_age,
                mothers_occupation: data.mothers_occupation,
                fathers_name: data.fathers_name,
                fathers_age: data.fathers_age,
                fathers_occupation: data.fathers_occupation,
                no_of_children: data.children.length,
                children: data.children.map(t => t.name),
                age: data.children.map(t => t.child_age),
                occupation: data.children.map(t => t.occupation),
                contact_person: data.contact_person,
                contact_person_relationship: data.contact_person_relationship,
                contact_number: data.contact_number,
                contact_address: data.contact_address,
                dtr_id: data.dtr_id,
                branch_id: data.branch_id,
                salary_type: data.salary_type,
                employment_status_id: data.employment_status_id,
                employee_status_id: data.employee_status_id,
                employment_status: data.employment_status_id,
                business_unit_id: data.business_unit_id,
                immediate_head_id: data.immediate_head_id,
                project_id: data.project_id,
                department_id: data.department_id,
                date_hired: data.date_hired,
                end_of_contract: data.end_of_contract,
                date_regularized: data.date_regularized,
                previous_employer: data.previous_employer,
                current_shift_id: data.current_shift_id,
                daily_allowance: data.daily_allowance,
                commu_allowance: data.commu_allowance,
                transpo_allowance: data.transpo_allowance,
                food_allowance: data.food_allowance,
                hmo_allowance: data.hmo_allowance,
                tech_allowance: data.tech_allowance,
                ops_allowance: data.ops_allowance,
                special_allowance: data.special_allowance,
                file_name: data.file_name,
                no_of_days: data.no_of_days,
                grocery_allowance: data.grocery_allowance,
                entertainment_allowance: data.entertainment_allowance,
                medicine_allowance: data.medicine_allowance,
                uniform_allowance: data.uniform_allowance,

                position_to: data.position_id,
                position: data.position_id,
                position_date: positionDate,
                position_from: positionFrom,
                
                salary_to: data.salary_to,
                salary: data.salary_to,
                salary_date: salaryDate,
                salary_from: salaryFrom
            })
            })
            .then(function (response) {
                // setClick(true);
                genClick = true;
                toast.success("Contract Updated Successfully!");
                // setTimeout(() => refreshPage(), 1500);
            })
            .catch(function (error) {
                console.log(error);
                // setClick(false);
                genTimeOut = false;
                toast.error("Failed to Update Contract");
                // tokenExpired(error);
                setClick(false);
            });

            // Update Employee Position
            axios({
            url: window.$link + 'employees/update_employee_position',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: props.employeeId,
                position_from: positionFrom,
                position_to: data.position_id
            })
            })
            .then(function (response) {
                // setClick(true);
                positionClick = true;
                toast.success("Employee Position Updated Successfully!");
                // setTimeout(() => refreshPage(), 1500);
            })
            .catch(function (error) {
                // setClick(false);
                console.log(error);
                positionTimeOut = false;
                toast.error("Failed to Update Position");
                tokenExpired(error);
                setClick(false);
            });

            // Update Employee Salary
            axios({
            url: window.$link + 'employees/update_employee_salary',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: props.employeeId,
                salary_from: salaryFrom,
                salary_to: data.salary_to,
                salary_type: data.salary_type,
            })
            })
            .then(function (response) {
                // setClick(true);
                salaryClick = true;
                toast.success("Employee Salary Updated Successfully!");
                // setTimeout(() => refreshPage(), 1500);
            })
            .catch(function (error) {
                // setClick(false);
                salaryTimeOut = false;
                toast.error("Failed to Update Salary");
                tokenExpired(error);
                setClick(false);
            });

            if (genClick===true&&salaryClick===true&&positionClick===true) {
                setClick(true);
            } else {
                setClick(false);
            }

            if (genTimeOut===true&&positionTimeOut===true&&salaryTimeOut===true) {
                setTimeout(() => refreshPage(), 1500);
            }
        }
            
    }

    const handleDateHired = (e) => {
        setSelectedStartDate(e);
        let newData = { ...data };
        var date = dateFormat(e, 'MM/dd/yyyy');
        newData.date_hired = date;
        props.setEditData(newData);
    };

    const handleEndDate = (e) => {
        setSelectedEndDate(e);
        let newData = { ...data };
        var date = dateFormat(e, 'MM/dd/yyyy');
        newData.end_of_contract = date;
        props.setEditData(newData);
    };

     const handleDateRegularized = (e) => {
        setSelectedDateRegularized(e);
        let newData = { ...data };
        var date = dateFormat(e, 'MM/dd/yyyy');
        newData.date_regularized = date;
        props.setEditData(newData);
    };

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    function handle(e, element = null) {
        let newData = { ...data };
        newData[e.target.id] = e.target.value;
        props.setEditData(newData);
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    React.useEffect(() => {
        let newData = { ...data };
        const date1 = new Date(data.date_hired);
        const date2 = new Date(data.end_of_contract);
        if (date1 && date2) {
            var diffTime = date2.getTime() - date1.getTime();
            var diffDays = diffTime / (1000 * 3600 * 24);
            newData.no_of_days = Math.ceil(diffDays);
        } else {
             newData.no_of_days = null;
        }
    props.setEditData(newData);
    }, [data.date_hired, data.end_of_contract]);


    return(
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Update Contract
                    </div>
                    <button type="button" class="btn-close-employee"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll mt-2 p-0'>
                    <Container>
                            <Row>
                                <Col xs={12}>
                                    <div>Employment Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">DTR ID<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.dtr_id?data.dtr_id:""}
                                        type="text"
                                        aria-label="dtr_id"
                                        aria_describedby="dtr_id"
                                        name="dtr_id"
                                        id="dtr_id"
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('dtr_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Branch<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.branch_id?data.branch_id:""}
                                        aria-label="branch_id"
                                        aria_describedby="branch_id"
                                        name="branch_id"
                                        id="branch_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Branch</option>
                                        {
                                            branches.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('branch_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Business Unit<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.business_unit_id?data.business_unit_id:""}
                                        aria-label="business_unit_id"
                                        aria_describedby="business_unit_id"
                                        name="business_unit_id"
                                        id="business_unit_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Business Unit</option>
                                        {
                                            businessUnits.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('business_unit_id')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Employment Status<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.employment_status_id?data.employment_status_id:""}
                                        aria-label="employment_status_id"
                                        aria_describedby="employment_status_id"
                                        name="employment_status_id"
                                        id="employment_status_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Employment Status</option>
                                        {
                                            employmentStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('employment_status_id')}
                                    </InputGroup>
                                </Col>
                                
                                 <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Salary Type<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.salary_type?data.salary_type:""}
                                        aria-label="salary_type"
                                        aria_describedby="salary_type"
                                        name="salary_type"
                                        id="salary_type"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Type</option>
                                        <option value='hourly'>Hourly</option>
                                        <option value='daily'>Daily</option>
                                        <option value='weekly'>Weekly</option>
                                        <option value='monthly5'>Monthly (5)</option>
                                        <option value='monthly6'>Monthly (6)</option>
                                    </Form.Select>
                                    {renderInputError('salary_type')}
                                    </InputGroup>
                                </Col>

                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Salary<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.salary_to?data.salary_to:""}
                                        type="number"
                                        aria-label="salary_to"
                                        aria_describedby="salary_to"
                                        name="salary_to"
                                        id="salary_to"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('salary_to')}
                                    </InputGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Immediate Head</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.immediate_head_id?data.immediate_head_id:""}
                                        aria-label="immediate_head_id"
                                        aria_describedby="immediate_head_id"
                                        name="immediate_head_id"
                                        id="immediate_head_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Immediate Head</option>
                                        {
                                            allEmployees.map((data) => (
                                                <option value={data.id}>{data.first_name + ' '+ data.middle_name + ' ' + data.last_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {/* {renderInputError('immediate_head_id')} */}
                                    </InputGroup>
                                </Col>
                                
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Department<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.department_id?data.department_id:""}
                                        aria-label="department_id"
                                        aria_describedby="department_id"
                                        name="department_id"
                                        id="department_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Department</option>
                                        {
                                            departments.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('department_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Position<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.position_id?data.position_id:""}
                                        aria-label="position_id"
                                        aria_describedby="position_id"
                                        name="position_id"
                                        id="position_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Position</option>
                                        {
                                            positions.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('position_id')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Date Hired<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <DatePicker
                                            value={data.date_hired?dateFormat(data.date_hired, 'MM/dd/yyyy'):""}
                                            type="date"
                                            selected={selectedStartDate}
                                            onChange = {(e) => handleDateHired(e)}
                                            className={'form-control date_hired'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id={'date_hired'}
                                            name={'date_hired'}
                                            ariaDescribedBy={'date_hired'}
                                            ariaLabelledBy={'date_hired'}
                                        />
                                        {renderInputError('date_hired')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">End of Contract</Form.Label>
                                    <InputGroup className="mb-3">
                                        <DatePicker
                                            value={data.end_of_contract?dateFormat(data.end_of_contract, 'MM/dd/yyyy'):""}
                                            type="date"
                                            selected={selectedEndDate}
                                            // onChange={(e) => {setSelectedEndDate(e); handle(e)}}
                                            onChange={(e) => handleEndDate(e)}
                                            className={'form-control end_of_contract'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id={'end_of_contract'}
                                            name={'end_of_contract'}
                                            ariaDescribedBy={'end_of_contract'}
                                            ariaLabelledBy={'end_of_contract'}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">No. of Days</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.no_of_days?data.no_of_days:""}
                                        aria-label="no_of_days"
                                        aria_describedby=""
                                        name="no_of_days"
                                        id="no_of_days"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                        disabled
                                    />
                                    </InputGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Date Regularized</Form.Label>
                                    <InputGroup className="mb-3">
                                        <DatePicker
                                            value={data.date_regularized?dateFormat(data.date_regularized, 'MM/dd/yyyy'):""}
                                            type="date"
                                            selected={selectedDateRegularized}
                                            onChange = {(e) => handleDateRegularized(e)}
                                            className={'form-control date_regularized'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id={'date_regularized'}
                                            name={'date_regularized'}
                                            ariaDescribedBy={'date_regularized'}
                                            ariaLabelledBy={'date_regularized'}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Previous Employer</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.previous_employer?data.previous_employer:""}
                                        type="text"
                                        aria-label="previous_employer"
                                        aria_describedby="previous_employer"
                                        name="previous_employer"
                                        id="previous_employer"
                                        placeholder='Enter Name'
                                        onChange={(e) => handle(e)}
                                    />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Current Shift</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.current_shift_id?data.current_shift_id:""}
                                        aria-label="current_shift_id"
                                        aria_describedby="current_shift_id"
                                        name="current_shift_id"
                                        id="current_shift_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Shift</option>
                                        {
                                            shifts.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Project</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.project_id?data.project_id:""}
                                        aria-label="project_id"
                                        aria_describedby="project_id"
                                        name="project_id"
                                        id="project_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>---</option>
                                        {
                                            projects.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Employee Status<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.employee_status_id?data.employee_status_id:""}
                                        aria-label="employee_status_id"
                                        aria_describedby="employee_status_id"
                                        name="employee_status_id"
                                        id="employee_status_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Employee Status</option>
                                        {
                                            employeeStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('employee_status_id')}
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditEmployee}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}