import React, { useState } from "react";
import { Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllProjects, GetAllBusinessUnits } from "../../helpers/CommonApi";
import { toast } from "react-toastify";
import moment from "moment";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import ApprovePrompt from "../Modals/ApprovePrompt";
import AddAnnouncement from "./AddAnnouncement";
import EditAnnouncement from "./EditAnnouncement";

// icons
import SearchIcon from "@mui/icons-material/Search";
import AddAlertIcon from '@mui/icons-material/AddAlert';

// css
import './Announcement.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"

import { validateAnnouncement } from "../../helpers/Validation/AnnouncementValidation";
import InputError from "../../helpers/InputError/InpuError";

export default function Announcment() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [allFilter, setAllFilter] = useState(true);
    const [click, setClick] = useState(false);

    // get data
    const [allAnnouncement, setAllAnnouncement] = useState([]);
    const [filteredAnnouncement, setFilteredAnnouncement] = useState([]);

    const [filterData, setFilterData] = useState({});

     const [editAnnouncementData, setEditAnnouncementData] = useState({
        announcement_date: '',
        announcement_from: '',
        subject: '',
        content: '',
        type_prime: '',
        announcement_to_id_prime: ''
    });

    // Validation
    const [isError, setIsError] = useState({
        announcement_date: false,
        announcement_from: false,
        subject: false,
        content: false,
        type_prime: false,
        announcement_to_id_prime: false
    });

    const branches = GetAllBranches();
    const employmentStatus = GetAllEmploymentStatuses();
    const positions = GetAllPositions();
    const businessUnits = GetAllBusinessUnits();
    const projects = GetAllProjects();
    const departments = GetAllDepartments();

    const [addAnnouncement, setAddAnnouncement] = useState(false);
    const handleAddAnnouncement = () => setAddAnnouncement(true);
    const handleCloseAddAnnouncement = () => {setAddAnnouncement(false); refreshPage()};
    const [showApprove, setShowApprove] = useState(false);

    const [editAnnouncement, setEditAnnouncement] = useState(false);
    const handleEditAnnouncement = () => setEditAnnouncement(true);
    const handleCloseEditAnnouncement = () => {setEditAnnouncement(false); refreshPage()};

    const [deleteAnnouncement, setDeleteAnnouncement] = useState(false);
    const handleDeleteAnnouncement = () => setDeleteAnnouncement(true);
    const handleCloseDeleteAnnouncement = () => setDeleteAnnouncement(false);
    const [announcementId, setAnnouncementId] = useState();

    function onClickAddAnnouncement() {
        handleAddAnnouncement();
    }

    function onClickApprove (announcement_id) {
        console.log('APPROVE: ', announcement_id);
        setAnnouncementId(announcement_id);
        setShowApprove(true);
    }

    function handleApprove () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'announcements/approve/'+announcementId,
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            status: "approved"
        })
        })
        .then(function (response) {
            toast.success("Announcement Approved Successfully!");
            setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
            toast.error(error.response.data.messages.error);
            // console.log(error.response.data.messages);
            tokenExpired(error);
        });
    }

    function onClickEditAnnouncement(announcement_id) {
        var axios = require('axios');
        var qs = require('qs');

        editAnnouncementData.length = 0;
        axios({
            method: 'get',
            url: window.$link + 'announcements/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                announcement_id: announcement_id
            }
        }).then(response => {
            setEditAnnouncementData(response.data.data.announcements[0]);
            handleEditAnnouncement();
        }).catch(function (error) {
            console.log(error);
        });
    }

    // Update announcement API
    function submitEditAnnouncement(e) {
        var axios = require('axios');
        var qs = require('qs');
        const userToken = getToken();

        if (validateAnnouncement(editAnnouncementData, setIsError)==true && editAnnouncementData.details.length>0 && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'announcements/update/'+editAnnouncementData.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: getUser(),
                token: userToken.replace(/['"]+/g, ''),
                announcement_date: dateFormat(editAnnouncementData.announcement_date, 'MM/dd/yyyy'),
                subject: editAnnouncementData.subject,
                content: editAnnouncementData.content,
                no_announcement_to: editAnnouncementData.details?editAnnouncementData.details.length:0,
                announcement_to: editAnnouncementData.details?editAnnouncementData.details.map(t => t.announcement_to_id):[],
                announcement_from: editAnnouncementData.announcement_from,
                type: editAnnouncementData.details?editAnnouncementData.details.map(t => t.type):[]
            })
            })
            .then(function (response) {
                toast.success("Announcement Edited Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Update Announcement")
                tokenExpired(error);
                setClick(false);
            });
        }
    }

   // GET BRANCH BY BRANCH ID FOR DELETION
    function onClickDeleteAnnouncement(announcement_id) {
        setAnnouncementId(announcement_id);
        handleDeleteAnnouncement();
    }

    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');
        // if(click === false) {
            axios({
            url: window.$link + 'announcements/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                announcement_id: announcementId
            })
            })
            .then(function (response) {
                toast.success("Announcement Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Error Deleting Announcement")
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allAnnouncement) {
            allAnnouncement.forEach(function(announcement) {
                if((announcement.subject).toLowerCase().includes(term)) {
                    filteredArr.push(announcement);
                }
            })
        }

        if (term == "") {
            setFilteredAnnouncement(allAnnouncement);
        } else {
            setFilteredAnnouncement(filteredArr);
        }
    }
    
    function handleFilters(e) {
        setAllFilter(false)
        const newFilterData = { ...filterData };
        newFilterData[e.target.id] = e.target.value;
        setFilterData(newFilterData);
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    function isOnlyWhiteSpace(str) {
        return !str.trim();
    }

    function renderInputError(var_name) {
        var value = '';
        if (var_name=="type") {
            editAnnouncementData.details.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="announcement_to") {
            editAnnouncementData.details.map((item) => {
                value = item[var_name];
            })
        } else {
            value = editAnnouncementData[var_name];
        }
        if (!value || isOnlyWhiteSpace(value) ===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

     function resetFilter(e) {
        const filter = [{
            branch_id: '',
            employment_status_id: '',
            position_id: '',
            business_unit_id: '',
            project_id: '',
            department_id: ''

        }]
        setFilterData(filter);
    }


     // Get all announcements
    React.useEffect(() => {
        allAnnouncement.length = 0;

        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'announcements/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                announcement_id: "",
                branch_id: filterData.branch_id,
                employment_status_id: filterData.employment_status_id,
                position_id: filterData.position_id,
                business_unit_id: filterData.business_unit_id,
                project_id: filterData.project_id,
                department_id: filterData.department_id
            }
        }).then(response => {
            const res = response.data.data.announcements;
            setAllAnnouncement(response.data.data.announcements);
            setFilteredAnnouncement(response.data.data.announcements);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    }, [filterData]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-announcement'>
                            Announcements    
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-announcement py-2" onClick={onClickAddAnnouncement}>
                                        <span className="export-text"><AddAlertIcon/> Add Announcement</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-announcement">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Announcement"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                {/* NEW FILTER DESIGN */}
                <Row className="mt-3 ms-2">
                    <Col sm={10}>
                        <Row className="filter-container">
                            {/* <Col xs={0} className={allFilter ? "all active-all" : "all"}> */}
                            <Col xs={0}>
                                <button onClick={resetFilter} className='export-button-filter'>All</button>
                            </Col>
                            <Col xs={2} className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.branch_id}
                                        className="selected-filter"
                                        aria-label="branch_id"
                                        aria_describedby="branch_id"
                                        name="branch_id"
                                        id="branch_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Branch</option>
                                        {
                                            branches.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.employment_status_id}
                                        className="selected-filter"
                                        aria-label="employment_status_id"
                                        aria_describedby="employment_status_id"
                                        name="employment_status_id"
                                        id="employment_status_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                        // onChange={(e) => setFilterData({...filterData, employment_status_id: e.target.value})}
                                    >
                                        <option value=''>Status</option>
                                        {
                                            employmentStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.position_id}
                                        className="selected-filter"
                                        aria-label="position_id"
                                        aria_describedby="position_id"
                                        name="position_id"
                                        id="position_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                        // onChange={(e) => setFilterData({...filterData, position_id: e.target.value})}
                                    >
                                        <option value=''>Position</option>
                                        {
                                            positions.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.business_unit_id}
                                        className="selected-filter"
                                        aria-label="business_unit_id"
                                        aria_describedby="business_unit_id"
                                        name="business_unit_id"
                                        id="business_unit_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                        // onChange={(e) => setFilterData({...filterData, business_unit_id: e.target.value})}
                                    >
                                        <option value=''>Business Unit</option>
                                        {
                                            businessUnits.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.project_id}
                                        className="selected-filter"
                                        aria-label="project_id"
                                        aria_describedby="project_id"
                                        name="project_id"
                                        id="project_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Project</option>
                                        {
                                            projects.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.department_id} 
                                        className="selected-filter"
                                        aria-label="department_id"
                                        aria_describedby="department_id"
                                        name="department_id"
                                        id="department_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Department</option>
                                        {
                                            departments.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xs={1}>
                        <button className="export-btn-filter" onClick={submitFilter}><FilterAltIcon/> Filter</button>
                    </Col> */}
                </Row>

                <AddAnnouncement 
                    showmodal1 = {addAnnouncement}
                    hidemodal1 = {handleCloseAddAnnouncement}
                    showmodal1handler = {onClickAddAnnouncement}
                />

                <EditAnnouncement 
                    showmodal1 = {editAnnouncement}
                    hidemodal1 = {handleCloseEditAnnouncement}
                    showmodal1handler = {onClickEditAnnouncement}
                    editAnnouncementData = {editAnnouncementData}
                    setEditAnnouncementData = {setEditAnnouncementData}
                    submitEditAnnouncement = {submitEditAnnouncement}
                    isError = {isError}
                    renderInputError = {renderInputError}
                />

                <DeletePrompt
                    name = "ANNOUNCEMENT"
                    show = {deleteAnnouncement}
                    hide = {handleCloseDeleteAnnouncement}
                    remover = {handleRemove}
                />

                <ApprovePrompt
                    name = "ANNOUNCEMENT"
                    show = {showApprove}
                    hide = {() => setShowApprove(false)}
                    approve = {handleApprove}
                />

                {/* HERE IS WHAT USED TO BE THE HIGHLIGTED ANNOUNCEMNT */}
                 {/* <Container className="message-container justify-content-center p-4">
                    <Row className="">
                        <Col xs={6} className="message-header"><CampaignIcon/> 
                            {highlight ? highlight.subject.toUpperCase() : '---'}
                        </Col>
                        <Col xs={6}>
                            <Row className="d-flex justify-content-end">
                                <Col xs={6} className="date-container me-1 text-center">{highlight  ? moment(highlight.announcement_date).format('MMM D, YYYY') : '---'}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='p-3 justify-content-center'>{highlight  ? highlight.content.toUpperCase() : 'No upcoming announcements'}</Row>
                </Container> */}

                
                <div className="announcement-wrapper mt-3">
                {
                    (function() {
                         return !loading ? (
                         <TableTemplate
                            tableType='announcement'
                            tableHeaders={["DATE POSTED", "SUBJECT", "ANNOUNCEMENT FROM", "ANNOUNCEMENT TO", "STATUS", ""]}
                            tableData={filteredAnnouncement}
                            deleteHandler={onClickDeleteAnnouncement}
                            editHandler={onClickEditAnnouncement}
                            approveHandler={onClickApprove}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}