import React, { useState } from "react";
import {Modal, Row, Col, Form, Container, InputGroup } from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
// css
import './Attendance.css'
import '../Modals/modal.css'
import "../../utilities/common.css"

import InputError from "../../helpers/InputError/InpuError";

export default function UpdateAttendance(props) {
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const attendance = props.attendance;

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    function getTimeDifference(start, end) {
        let startMinutes = start.split(':')[0] * 60 + +start.split(':')[1];
        let endMinutes = end.split(':')[0] * 60 + +end.split(':')[1];
        let difference = endMinutes - startMinutes - 60;

        return `${Math.floor(difference / 60)}:${difference % 60}`;
    }

    function handle(e) {
        let updatedAttendance = { ...attendance };
        updatedAttendance[e.target.id] = e.target.value;

        if (e.target.id == "time_out" && attendance.time_in) {
                var time_in = attendance.time_in;
                var time_out = e.target.value;
                var time_diff = getTimeDifference(time_in, time_out);
                
                updatedAttendance["no_of_hrs"] = time_diff;
        } else if (e.target.id == "time_in" && attendance.time_out) {
                var time_in = e.target.value;
                var time_out = attendance.time_out;
                var time_diff = getTimeDifference(time_in, time_out);
                updatedAttendance["no_of_hrs"] = time_diff;
        }
        props.setAttendance(updatedAttendance);
    }

    function renderInputError(var_name) {
        let value = attendance[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={props.isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return(
        <div>
            {/* Edit Attendance Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                {/* <Form> */}
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Update Timesheet
                        </div>
                        <button type="button" class="btn-close"  onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Employee <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                defaultValue={props.attendance.employee_name}
                                                aria-label="employee_id" 
                                                aria-describedby="employee_id" 
                                                placeholder="Employee"
                                                name="employee_id"
                                                id='employee_id'
                                                className="mb-2"
                                                disabled
                                            >
                                            </Form.Control>
                                            {renderInputError("employee_id")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">DTR ID <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                defaultValue={props.attendance.dtr_id}
                                                aria-label="dtr_id" 
                                                aria-describedby="dtr_id" 
                                                placeholder="Employee"
                                                name="dtr_id"
                                                id='dtr_id'
                                                className="mb-2"
                                                disabled
                                            >
                                            </Form.Control>
                                            {renderInputError("dtr_id")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Work Date <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={attendance.work_date}
                                                type="date"
                                                name="work_date"
                                                id="work_date"
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError('work_date')}
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Time In <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                        <Form.Control type="time" name="time_in" defaultValue={props.attendance.time_in} id='time_in' className="mb-2" onChange={(e) => handle(e)}/>
                                        {renderInputError("time_in")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Time Out <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                         <Form.Control type="time" name="time_out" defaultValue={props.attendance.time_out} id='time_out' className="mb-2" onChange={(e) => handle(e)}/>
                                         {renderInputError("time_out")}
                                         </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Late</Form.Label>
                                        <InputGroup>
                                        <Form.Control type="number" name="late" defaultValue={props.attendance.late} id='late' className="mb-2" onChange={(e) => handle(e)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Number of Days</Form.Label>
                                        <InputGroup>
                                         <Form.Control type="number" name="no_of_days" defaultValue={props.attendance.no_of_days} id='no_of_days' className="mb-2" onChange={(e) => handle(e)}/></InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Number of Hours <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                        <Form.Control type="text" name="no_of_hrs" value={attendance.no_of_hrs} id='no_of_hrs' className="mb-2" disabled/>
                                         {renderInputError("no_of_hrs")}</InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Number of Minutes</Form.Label>
                                        <InputGroup>
                                        <Form.Control type="number" name="no_of_mins" defaultValue={props.attendance.no_of_mins} id='no_of_mins' className="mb-2" onChange={(e) => handle(e)}/></InputGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4 mt-5'>
                            <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                            <button type="submit" className='save-button' onClick={props.submitUpdateAttendance}>Save</button>
                        </div>
                    </Modal.Body>
                {/* </Form> */}
            </Modal>
        </div>
    );
}