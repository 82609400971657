import React from 'react'
import { useRef, useState } from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

// Components
import Navbar from '../Navbar/Navbar'
import { getToken, getUser, tokenExpired } from '../../utilities/common';

// Image
import PrintIcon from '@mui/icons-material/Print';

import 'react-datepicker/dist/react-datepicker.css';

function ViewSSS () {

    const date_today = new Date();
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [SSSDetails, setSSSDetails] = useState({});
    const SSSId = location.state;
    const currentDate = new Date();
    const dateToday = new Date().getDate();

    const [filterDate, setFilterDate] = useState({
        date_from: dateToday <= 15 ? getFirstDayOfMonth() : get16thDayOfMonth(),
        date_to: dateToday <= 15 ? get15thDayOfMonth() : getLastDayOfMonth()
    })

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    // GET SSS BY ID
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'SSSs/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                SSS_id: SSSId
            })
        }).then(response => {
            const SSS = response.data.data[0];
            console.log(SSS);
            var info = SSS;
            info.id = SSS.id;
            info.date_from = SSS.date_from;
            info.date_to = SSS.date_to;
            // setSSSDetails(info);
            
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
        });
    },[filterDate]);

    function getFirstDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

    function get15thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
    }

    function get16thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 16);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
    }

    return (
        <div className='comm-page'>
            <Navbar/>
            <div className='comm-page-container no-margin-top'>
                <div className='print-page-container me-3 mt-0 payslip' ref={componentRef}>
                <Row className='d-flex comm-header-employee'>Social Security System (SSS) Contribution</Row>
                <Row className="px-3 mt-4">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <div className="leftCol">
      <div>
      <li className=''>EMPLOYEE NAME </li>
          <li className=''>SSS NO.</li>
          <li className=''>TOTAL NO. OF MONTHS</li>
      </div>
    </div>

    <div className="midCol">
      <div>
        <li className=''>:</li>
          <li className=''>:</li>
          <li className=''>:</li>
      </div>
    </div>
    <div className="rightCol">
      <div>
        <li className='' xs={5}>ANNALIZA CABASE</li>
          <li className=''>213424165</li>
          <li className=''>12</li>
      </div>
    </div>
                </Row>
                <Row className='p-2 mt-3'>
                <div className='title-SSS'>Employee Contribution Payment Summary</div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Total Employee Share</th>
                                <th>Total Employer Share</th>
                                <th>Total Amount of Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td className='content-SSS'>Php 500.00</td>
                                <td className='content-SSS'>Php 200.00</td>
                                <td className='content-SSS'>Php 700.00</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className='p-2 mt-3'>
                <div className='title-SSS'>Employee Contribution Payment History</div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Year</th>
                                <th>Month</th>
                                <th>Employer Share</th>
                                <th>Employee Share</th>
                                <th>Total Contribution</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className=''>2022</td>
                                <td className=''>January</td>
                                <td className='content-SSS'>Php 500.00</td>
                                <td className='content-SSS'>Php 200.00</td>
                                <td className='content-SSS'>Php 700.00</td>
                                </tr>
                        </tbody>
                    </table>
                </Row>
                <Row className='d-flex justify-content-end mt-5 noprint'>
                    <Col xs={2}>
                        <ReactToPrint
                            trigger={() => (
                                <button className="save-button me-0 noprint">
                                    <span><PrintIcon/> Print</span>
                                </button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"SSS"}
                        />  
                    </Col>
                    <Col xs={2}>
                        <button type="" className='save-button blue noprint' onClick={() => navigate(-1)}>Back</button>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    )
}

export default ViewSSS;