import React, { useState } from "react";

// icons
import DownloadIcon from '@mui/icons-material/Download';
import "./export_excel_button.css"

// css
import './common.css';

function ExportExcelButton() {
    return (
        <button className="export-button py-2">
          <span className="export-text"><DownloadIcon/>Export File</span>
        </button>
    )
}

export default ExportExcelButton;