import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import {toast} from 'react-toastify'
import '../Modals/modal.css'

import InputError from '../../helpers/InputError/InpuError';
import { validateBusinessUnit } from '../../helpers/Validation/Manage/BusinessUnitValidation';

export default function EditRequirement(props) {
    const userToken = getToken();
    const userId = getUser();

    const [isError, setIsError] = useState({
        name: false
    });
    const [click, setClick] = useState(false);
    const [editRequirementData, setEditRequirementData] = useState(props.editRequirementData);

    function handle(e) {
        let editedEmploymentStatusData = { ...props.editRequirementData };
        editedEmploymentStatusData[e.target.id] = e.target.value;
        setEditRequirementData(editedEmploymentStatusData);
    }

    function renderInputError(var_name) {
        let value = editRequirementData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }


    function submitEditRequirement(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateBusinessUnit(editRequirementData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'requirements/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                requirement_id: props.editRequirementData.id,
                name: editRequirementData.name
            })
            })
            .then(function (response) {
                toast.success("Requirement Edited Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error("Failed to Update Requirement")
                tokenExpired(error);
                setClick(false);
                // setTimeout(() => refreshPage(), 1000);
            });
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Requirement
                    </div>
                    <button type="button" class="btn-close"   onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll m-2 p-0'>
                        <Container>
                             <Row>
                                <Form.Label className="h6" htmlFor="name">Requirement Name<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={props.editRequirementData.name}
                                        className='input-field'
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError("name")}
                                </InputGroup>
                            </Row>  
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditRequirement}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}