import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup } from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import {GetAllEmployees} from '../../helpers/CommonApi'
// css
import './Attendance.css'
import '../Modals/modal.css'
import "../../utilities/common.css"

// Components
import { validateTimesheet } from "../../helpers/Validation/TimesheetValidation";
import InputError from '../../helpers/InputError/InpuError';

export default function AddAttendance(props) {
    // add attendance API
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [loading, setLoading] = useState(true);
    const [workDate, setWorkDate] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const allEmployees = GetAllEmployees()

    const [data, setAttendance] = useState({
        employee_id: '',
        dtr_id: '',
        work_date: '',
        time_in: '',
        time_out: '',
        no_of_days: "",
        no_of_hrs: "",
        no_of_mins: "",
        with_holiday: "",
        late: "",
    });

    const [isError, setIsError] = useState({
        employee_id: false,
        work_date: false,
        time_in: false,
        time_out: false,
        no_of_hrs: false,
        dtr_id: false
    });

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    // Add timesheet API
    function submitAddAttendance(e) {
        e.preventDefault();
        if(validateTimesheet(data, setIsError)==true && click===false) {
            setClick(true);
            var axios = require('axios');
            var qs = require('qs');
            axios({
            url: window.$link + 'timesheets/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: data.employee_id,
                work_date: dateFormat(selectedDate, 'MM/dd/yyyy'),
                dtr_id: data.dtr_id,
                time_in: data.time_in,
                time_out: data.time_out,
                no_of_days: data.no_of_days,
                no_of_hrs: data.no_of_hrs,
                no_of_mins: data.no_of_mins,
                with_holiday: data.with_holiday,
                late: data.late
            })
            })
            .then(function (response) {
                toast.success("Timesheet Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                setClick(false);
                console.log(error.response.data.messages[0])
                toast.error(error.response.data.messages[0]);
                // toast.error(error.response.data.messages.error);
                tokenExpired(error);
            });
        }
        setClick(true);
    }

    function handle(e) {
        const newAttendance = { ...data };
        newAttendance[e.target.id] = e.target.value;
        if (e.target.id == "time_out" && data.time_in) {
                var time_in = data.time_in;
                var time_out = e.target.value;
                var time_diff = getTimeDifference(time_in, time_out);
                
                newAttendance["no_of_hrs"] = time_diff;
        } else if (e.target.id == "time_in" && data.time_out) {
                var time_in = e.target.value;
                var time_out = data.time_out;
                var time_diff = getTimeDifference(time_in, time_out);
                newAttendance["no_of_hrs"] = time_diff;
            }
        setAttendance(newAttendance);
    }

    const handleWorkDate = (e) => {
        setSelectedDate(e);
        let newData = { ...data };
        var date = dateFormat(e, 'MM/dd/yyyy');
        newData.work_date = date;
        setAttendance(newData);
    };

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function getTimeDifference(start, end) {
        let startMinutes = start.split(':')[0] * 60 + +start.split(':')[1];
        let endMinutes = end.split(':')[0] * 60 + +end.split(':')[1];
        let difference = endMinutes - startMinutes - 60;

        return `${Math.floor(difference / 60)}:${difference % 60}`;
    }

    return(
        <div>
            {/* Add Attendance Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                <Form>
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Add Timesheet
                        </div>
                        <button type="button" class="btn-close"  onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Employee<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                            as="select"
                                            aria-label="employee_id" 
                                            aria-describedby="employee_id" 
                                            placeholder="Employee" 
                                            name="employee_id" 
                                            id='employee_id' 
                                            className="mb-2"
                                            onChange={(e) => handle(e)}
                                        >
                                          <option value=""> --- </option>
                                            {
                                                allEmployees.map( (x,y) => 
                                                    <option value={x.id} key={x.id}>{x.name}</option>
                                                )
                                            }
                                        </Form.Control>
                                        {renderInputError("employee_id")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">DTR ID<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                aria-label="dtr_id" 
                                                aria-describedby="dtr_id" 
                                                placeholder="" 
                                                name="dtr_id" 
                                                id='dtr_id' 
                                                className="mb-2"
                                                onChange={(e) => handle(e)}
                                            >
                                        </Form.Control>
                                        {renderInputError("dtr_id")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Work Date<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <DatePicker
                                                selected={selectedDate}
                                                className={'mb-2 form-control'}
                                                id={'work_date'}
                                                name={'work_date'}
                                                placeholderText={'mm/dd/yyyy'}
                                                onChange={(e) => handleWorkDate(e)}
                                                // onChange={(e) => handle(e)}
                                            />
                                            {renderInputError("work_date")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Time In<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="time" 
                                                name="time in" 
                                                id='time_in' 
                                                className="mb-2" 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError("time_in")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Time Out<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="time" 
                                                name="time_out" 
                                                id='time_out' 
                                                className="mb-2" 
                                                onChange={(e) => handle(e)}
                                                />
                                            {renderInputError("time_out")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Late (in minutes)</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="number" 
                                                name="late" 
                                                id='late' 
                                                className="mb-2" 
                                                onChange={(e) => handle(e)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Number of Days</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="number" 
                                                name="no_of_days" 
                                                id='no_of_days' 
                                                className="mb-2" 
                                                onChange={(e) => handle(e)}/>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Number of Hours <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                value={data.no_of_hrs}
                                                type="text" 
                                                name="no_of_hrs" 
                                                id='no_of_hrs' 
                                                className="mb-2" 
                                                disabled
                                                // onChange={(e) => handle(e)}
                                                />
                                                {renderInputError("no_of_hrs")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Number of Minutes</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="number" 
                                                name="no_of_mins" 
                                                id='no_of_mins' 
                                                className="mb-2" 
                                                onChange={(e) => handle(e)}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                {/* <Row>
                                    <Col xs={2}><Form.Label className="h6" htmlFor="name">With Holiday</Form.Label></Col>
                                    <Col xs={1}>
                                        <Form.Check type="checkbox" name="with_holiday" className="float-left p-0 m-0" id='with_holiday' onChange={(e) => handleHolidayInput(e)}/>
                                    </Col>
                                </Row> */}
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4 mt-5'>
                            <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                            <button type="submit" className='save-button' onClick={submitAddAttendance}>Save</button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    );
}