import React, { useState, useRef } from "react";
import {Row, Col} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import {toast} from 'react-toastify'
import { getToken, getUser, getRoleId, tokenExpired, refreshPage} from "../../utilities/common";
import { GetUserEmployeeId } from "../../helpers/CommonApi";
// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import GenPayroll from "./GenPayroll";
import PrintPayroll from "./PrintPayroll";
import DeletePrompt from "../Modals/DeletePrompt";

// icons
import PrintIcon from '@mui/icons-material/Print';

// css
import '../Modals/modal.css'
import './Payroll.css';
import "../../utilities/common.css"
import 'react-datepicker/dist/react-datepicker.css'


function Payroll() {
    const [showGenPayroll, setShowGenPayroll] = useState(false);
    const [showPrintPayroll, setShowPrintPayroll] = useState(false);

    //get all employees
    const userToken = getToken();
    const userId = getUser();
    const userRoleId = getRoleId();
    const userEmployeeId = GetUserEmployeeId();
    const componentRef = useRef();
    const [allPayrolls, setAllPayrolls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [payrollId, setPayrollId] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const currentDate = new Date();
    const dateToday = new Date().getDate();

    const [filterDate, setFilterDate] = useState({
        date_from: dateToday <= 15 ? getFirstDayOfMonth() : get16thDayOfMonth(),
        date_to: dateToday <= 15 ? get15thDayOfMonth() : getLastDayOfMonth()
    })


    function onClickDelete(payroll_id) {
        setPayrollId(payroll_id);
        setShowDelete(true);
    }

    function handleRemove () {
        var axios = require('axios');
        var qs = require('qs');
        console.log(payrollId);
        axios({
            url: window.$link + 'payrolls/delete/' + payrollId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
        })
        })
        .then(function (response) {
            toast.success("Payroll Deleted Successfully!");
            setTimeout(() => refreshPage(), 1500);
        })
        .catch(function (error) {
            toast.error('Failed to Delete Payroll');
            tokenExpired(error);
        });
    }

    function getFirstDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

    function get15thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
    }

    function get16thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 16);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
    }

    // GET ALL PAYROLLS - applicable only if user is admin or HR
    function getAllPayrolls () {
        var axios = require('axios');
        var qs = require('qs');       
        axios({
            method: 'post',
            url: window.$link + 'payrolls/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                payroll_id: ''
            })
        }).then(response => {
            setAllPayrolls(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
            setAllPayrolls([]);
        });
    }

    // Get payrolls of a specific employee - applicable if the user is an employee
    function getEmployeePayrolls () {
        var axios = require('axios');
        var qs = require('qs');       
        axios({
            method: 'post',
            url: window.$link + 'payrolls/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                employee_id: userEmployeeId
            })
        }).then(response => {
            setAllPayrolls(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
            setAllPayrolls([]);
        });
    }


    // GET ALL PAYROLLS
    React.useEffect(() => {
        allPayrolls.length = 0;
        if (userRoleId === '1' || userRoleId === '2') {
            getAllPayrolls();
        } else {
            getEmployeePayrolls();
        }
    },[filterDate]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={1} className='d-flex comm-header-employee'>
                            Payroll
                        </Col>
                        {
                            (userRoleId === '1' || userRoleId === "2") ? (
                                <Col className='d-flex justify-content-end me-4'>
                                    <Row>
                                        <Col xs={5} className="me-5">
                                            <ReactToPrint
                                                trigger={() => (
                                                    <button className="export-button">
                                                        <span className="export-text"><PrintIcon/>Print Payroll</span>
                                                    </button>
                                                )}
                                                content={() => componentRef.current}
                                                documentTitle={"Payslip"}
                                            />
                                        </Col>
                                        <Col xs={5} className="">
                                            <button className="export-button nochange" onClick={() => setShowGenPayroll(true)}>
                                                Generate Payroll
                                            </button>
                                        </Col>
                                    </Row>
                                </Col> 
                            ):(
                                <p></p>
                            )
                        }
                    </Row>
                </Row>

                {/* Date Filter */}
                <Row className="px-3 mt-5">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row>

                {/* TABLE HERE */}
                <div className="payroll-wrapper mt-2" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">PAYROLL</h5>
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='payroll'
                                tableHeaders={["Date From", "Date To", "Employee", "TOTAL EARNINGS", "TOTAL DEDUCTIONS", "TOTAL PAYOUT", ""]}
                                tableData={allPayrolls}
                                deleteHandler={onClickDelete}
                            />
                            ) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>
                <GenPayroll show={showGenPayroll} hide={() => setShowGenPayroll(false)}/>

                <PrintPayroll 
                    show={showPrintPayroll} 
                    hide={() => setShowPrintPayroll(false)}
                    allPayrolls={allPayrolls}
                />

                <DeletePrompt
                    name = "PAYROLL"
                    show = {showDelete}
                    hide = {() => setShowDelete(false)}
                    remover = {handleRemove}
                />
            </div>
        </div>
    );
}

export default Payroll;