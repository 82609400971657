import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Row, Col, Form, Container, Table, InputGroup } from 'react-bootstrap';
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import moment from 'moment';

import Navbar from "../Navbar/Navbar"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

// css
import './Forms.css'
import '../../utilities/common.css'

export default function EditForms() {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;
    const form_details = data.form_details;
    const recipients = data.recipients;
    const question_set = data.question_set;

    const [click, setClick] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});

    const [responses, setResponses] = useState({
        form_id: form_details.id,
        form_question_ids: [],
        answers: []
    })

    const [editedData, setEditedData] = useState({
        name: form_details.name,
        deadline: form_details.deadline,
        description: form_details.description,
        recipients: recipients,
        question_set: question_set,
    });


    function handleResponse (e, question_id=null, question_index=null) {
        let newData = {...responses}
        if (e.target.id === "answer") {
            newData['form_question_ids'][question_index] = question_id;
            newData['answers'][question_index] = e.target.value;
        }
        setResponses(newData);
    }

    function handleOptionChange (e) {
        // console.log(e.target);
        setSelectedOption(e.target.value);
    }

    function submitSurveyResponse () {
        var axios = require('axios');
        var qs = require('qs');

        if (click === false) {
            axios ({
                url: window.$link + 'forms/answer/' + form_details.id,
                method: 'post',
                headers: {
                    "api-key": window.$api_key,
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: qs.stringify({
                    requester: getUser(),
                    token: getToken().replace(/['"]+/g, ''),
                    employee_id: getUser(),
                    form_question_ids: responses.form_question_ids.map((data) => {return (data)}),
                    responses: responses.answers.map((data) => {return (data)})
                })
            }).then (function (response) {
                setClick(true);
                toast.success('Succesfully Submitted Response');
                setTimeout(() => {navigate(-1);
                setTimeout(() => refreshPage(), 1000)}, 1000);
            }).catch ( function (error) {
                setClick(false);
                toast.error('Failed to Submit Response');
                console.log(error);
            })
        }
    }

    return (
        <div className="comm-bg">
            <Navbar />
            <div className="comm-page-container">

                <div className="ebr-container-wrapper me-3 py-4">
                    <Container className='pt-2'>
                        <Row className='comm-header-branch px-3'>
                            {editedData.name ? editedData.name : ''}   
                        </Row>
                        <Row className='px-3 pt-1'>
                            {editedData.description ? editedData.description : ''}
                        </Row>
                    </Container>
                </div>

                <div className="create-form-container me-3 mt-3 p-3 px-4">
                    <Container>
                        {(editedData["question_set"] && editedData["question_set"].length) ? editedData["question_set"].map((element, i) => {
                            var question_index = i;
                            var question_id = element.id;
                            return (
                                <Row key={element.key} className='px-4 py-3'>
                                        {/* <InputGroup className="mb-3"> */}
                                            {
                                                (element.question_type == "short") ? (
                                                    <>
                                                        <Row className="h6 question-bg pink-bg">{element.question?element.question:''}</Row>
                                                        <InputGroup>
                                                            <Form.Control
                                                                className='input-field mb-2'
                                                                type="text"
                                                                aria-label="answer" 
                                                                aria-describedby="answer" 
                                                                placeholder="Type answer..." 
                                                                name="answer" 
                                                                id='answer'
                                                                onChange={(e) => handleResponse(e, question_id, question_index)} 
                                                            />
                                                        </InputGroup>
                                                    </>
                                                ):((element.question_type == "long") ? (
                                                    <>
                                                        <Row className="h6 question-bg pink-bg">{element.question?element.question:''}</Row>
                                                        <InputGroup>
                                                            <Form.Control
                                                                className='input-field mb-2'
                                                                as="textarea"
                                                                aria-label="answer" 
                                                                aria-describedby="answer" 
                                                                placeholder="Type answer..." 
                                                                name="answer" 
                                                                id='answer'
                                                                onChange={(e) => handleResponse(e, question_id, question_index)} 
                                                            />
                                                        </InputGroup>
                                                    </>
                                                ): ((element.question_type == "multiple_choice") ? (
                                                <>
                                                    <Row className="h6 question-bg pink-bg">{element.question?element.question:''}</Row>
                                                    <InputGroup>                                                        
                                                        {
                                                            element['choices'].map((data, choice_index) => {
                                                                return (
                                                                    <InputGroup className='mt-2' key={data.key}>
                                                                        <Col xs={2} className=''>
                                                                            <input
                                                                                id="answer"
                                                                                name="answer"
                                                                                type="radio"
                                                                                value={data.choice?data.choice:''}
                                                                                checked={selectedOption === data.choice}
                                                                                onChange={(e) => {handleOptionChange(e); handleResponse(e, question_id, question_index)}}
                                                                            />
                                                                        </Col>
                                                                            
                                                                        <Col xs={5} className=''>
                                                                            {data.choice?data.choice:''}
                                                                        </Col>
                                                                    </InputGroup>
                                                                )
                                                            })
                                                        }
                                                    </InputGroup>
                                                </>
                                                ):""))
                                            }
                                        {/* </InputGroup> */}
                                </Row>
                                
                                )
                            }): <p></p>}
                    </Container>
                </div>
                <div className='d-flex justify-content-end me-3 mt-4 mb-3'>
                    <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                    <button type="submit" className='save-button' onClick={submitSurveyResponse}>Submit</button>
                </div>
            </div>
        </div>
    );
}