import React,{useState} from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddLeaveType from "./AddLeaveType";
import EditLeaveType from "./EditLeaveType";

// icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SearchIcon from "@mui/icons-material/Search";

// css
import './Leave.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"


export default function LeaveType() {
    const userToken = getToken();
    const userId = getUser();
    const [loading, setLoading] = useState(true);
    const [leaveId, setLeaveTypeId] = useState();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // get all Leave
    const [allLeaveTypes, setAllLeaveTypes] = useState([]);
    const [filteredLeaveTypes, setFilteredLeaveTypes] = useState([]);
    const [editLeaveTypeData, setEditLeaveTypeData] = useState({});


    function onClickAddLeaveType() {
        setShowAddModal(true);
    }

    // GET BRACH BY ID FOR EDITING
    function onClickEditLeaveType(leave_type_id) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'leave_types/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                leave_type_id: leave_type_id
            }
        }).then(response => {
            setEditLeaveTypeData(response.data.data[0]);
            setShowEditModal(true);
        }).catch(function (error) {
            console.log(error);
        });
    }

    // GET LEAVE BY LEAVE ID FOR DELETION
    function onClickDeleteLeave(leave_type_id) {
        setLeaveTypeId(leave_type_id);
        setShowDeleteModal(true);
    }

    // DELETING LEAVE
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'leave_types/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                leave_type_id: leaveId
            })
            })
            .then(function (response) {
                toast.success("Leave Type Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Delete Leave Type");
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allLeaveTypes) {
            allLeaveTypes.forEach(function(leave) {
                if((leave.name).toLowerCase().includes(term)) {
                    filteredArr.push(leave);
                }
            })
        }

        if (term == "") {
            setFilteredLeaveTypes(allLeaveTypes);
        } else {
            setFilteredLeaveTypes(filteredArr);
        }
    }
       
    // GET ALL LEAVE TYPES
    React.useEffect(() => {
        allLeaveTypes.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'leave_types/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
            setAllLeaveTypes(response.data.data);
            setFilteredLeaveTypes(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            // console.log('Error getting all Leaves');
            console.log(error);
            setLoading(false);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar/>
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-leave'>
                            Leave Type   
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-leave" onClick={onClickAddLeaveType}>
                                        <span className="export-text"><AccountBalanceIcon/> Add Leave Type</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-leave">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Leave"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddLeaveType
                    showmodal1 = {showAddModal}
                    hidemodal1 = {() => setShowAddModal(false)}
                    showmodal1handler = {onClickAddLeaveType}
                />

                <EditLeaveType 
                    showmodal1 = {showEditModal}
                    hidemodal1 = {() => setShowEditModal(false)}
                    showmodal1handler = {onClickEditLeaveType}
                    editLeaveTypeData = {editLeaveTypeData}
                />

                <DeletePrompt
                    name = "LEAVE TYPE"
                    show = {showDeleteModal}
                    hide = {() => setShowDeleteModal(false)}
                    remover = {handleRemove}
                />

                <div className="leave-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='leave_type'
                            tableHeaders={["Leave Name", "Credits", ""]}
                            tableData={filteredLeaveTypes}
                            deleteHandler={onClickDeleteLeave}
                            editHandler={onClickEditLeaveType}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}