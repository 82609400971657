import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Table} from 'react-bootstrap';
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';

// COMPONENTS
import { GetAllEmployees, GetAllBranches, GetAllEmploymentStatuses, GetAllBusinessUnits } from '../../helpers/CommonApi';

// css
import '../../utilities/common.css'
import './Payroll.css'
import { HolidayVillageTwoTone } from '@mui/icons-material';

export default function GenPayrollPage() {
    const userToken = getToken();
    const userId = getUser();

    const location = useLocation();
    const dateFrom = location.state.from;
    const dateTo = location.state.to;

    const allEmployees = GetAllEmployees();
    const allBranches = GetAllBranches();
    const allEmploymentStatus = GetAllEmploymentStatuses();
    const allBusinessUnits = GetAllBusinessUnits();
    const [filters, setFilters] = useState([]);
    const [click, setClick] = useState(false);
    const [payrollReady, setPayrollReady] = useState(false);

    const navigate = useNavigate();
    const [employee, setEmployee] = useState([{
        branch_id: '',
        basic_rate: '',
        work_days: '',
        regular_pay: '',
        holiday_pay: '',
        basic_pay: '',
        late: '',
        sss_contribution: '',
        philhealth_contribution: '',
        pagibig_contribution: '',
        intellicare_amount: '',
        sss_salary_loan: '',
        sss_calamity_loan: '',
        pagibig_loan: '',
        calamity_loan: '',
        hmo_maxicare: '',
        coop: '',
        wth_tax: '',
        others: '',
        cash_advance: '',
        total_one: '',
        daily_allowance: '',
        comm_allowance: '',
        transpo_allowance: '',
        food_allowance: '',
        hmo_allowance: '',
        tech_allowance: '',
        ops_allowance: '',
        special_allowance: '',
        grocery_allowance: '',
        entertainment_allowance: '',
        medicine_allowance: '',
        uniform_allowance: '',
        total_two: '',
        training_allowance_f2f: '',
        training_allowance_virtual: '',
        regular_ot: '',
        restday_ot: '',
        special_holiday_pay:'',
        night_differential: '',
        salary_adj: '',
        monthly:'',
        semi_monthly:'',
        total_three: '',
        total_net_pay: '',
        thirteenth_month: ''
    }])

    // Handle/update changes in data
    function handleDataEmp(e, index) { 
        const { name, value } = e.target;
        const list = [ ...employee ];
        list[parseInt(index)][name] = value;
        setEmployee(list);
        calculcateTotal(index);
    }

    // Handle filters
    function handleFilters(e) {
        const newFilterData = { ...filters };
        newFilterData[e.target.id] = e.target.value;
        setFilters(newFilterData);
    }

    function filterPayroll(employeeId, objectToFilter) {
        const filtered = objectToFilter.filter(value => {
            return value.employee_id == employeeId
        });
        return filtered;
    }

     function calculcateTotal (index) {
        // Deductions
        var late = employee[index].late ? Number(employee[index].late) : 0.00;
        var axa_amount = employee[index].axa_amount ? Number(employee[index].axa_amount) : 0.00;
        var phone_loan = employee[index].phone_loan ? Number(employee[index].phone_loan) : 0.00;
        var laptop_loan = employee[index].laptop_loan ? Number(employee[index].laptop_loan) : 0.00;
        var sss_contribution = employee[index].sss_contribution ? Number(employee[index].sss_contribution) : 0.00;
        var pagibig_contribution = employee[index].pagibig_contribution ? Number(employee[index].pagibig_contribution) : 0.00;
        var philhealth_contribution = employee[index].philhealth_contribution ? Number(employee[index].philhealth_contribution) : 0.00;
        var sss_salary_loan = employee[index].sss_salary_loan ? Number(employee[index].sss_salary_loan) : 0.00;
        var sss_calamity_loan = employee[index].sss_calamity_loan ? Number(employee[index].sss_calamity_loan) : 0.00;
        var pagibig_loan = employee[index].pagibig_loan ? Number(employee[index].pagibig_loan) : 0.00;
        var calamity_loan = employee[index].calamity_loan ? Number(employee[index].calamity_loan) : 0.00;
        var hmo_maxicare = employee[index].hmo_maxicare ? Number(employee[index].hmo_maxicare) : 0.00;
        var coop = employee[index].coop ? Number(employee[index].coop) : 0.00;
        var wth_tax = employee[index].wth_tax ? Number(employee[index].wth_tax) : 0.00;
        var others = employee[index].others ? Number(employee[index].others) : 0.00;
        var cash_advance = employee[index].cash_advance ? Number(employee[index].cash_advance) : 0.00;
        var intellicare_amount = employee[index].intellicare_amount ?  Number(employee[index].intellicare_amount) : 0.00;
        
        // var gain = regular_pay + holiday_pay;
        var regular_pay = employee[index].regular_pay ? Number(employee[index].regular_pay) : 0.00;
        var holiday_pay = employee[index].holiday_pay ? Number(employee[index].holiday_pay) : 0.00;
        var basic_pay = employee[index].basic_pay ? Number(employee[index].basic_pay) : 0.00;
        
        // TOTAL(1)
        var gain = basic_pay+regular_pay+holiday_pay;
        var deduction = late+sss_contribution+pagibig_contribution+philhealth_contribution+intellicare_amount+sss_salary_loan+sss_calamity_loan+pagibig_loan+calamity_loan+hmo_maxicare+coop+wth_tax+others+cash_advance+axa_amount+phone_loan+laptop_loan;
        var total_1 = gain-deduction;
        employee[index].total_one = total_1.toFixed(2);

        // TOTAL(2)
        var daily = employee[index].daily_allowance ? Number(employee[index].daily_allowance) : 0.00;
        var comm = employee[index].comm_allowance ? Number(employee[index].comm_allowance) : 0.00;
        var transpo = employee[index].transpo_allowance ? Number(employee[index].transpo_allowance) : 0.00;
        var food = employee[index].food_allowance ? Number(employee[index].food_allowance) : 0.00;
        var hmo = employee[index].hmo_allowance ? Number(employee[index].hmo_allowance) : 0.00;
        var tech = employee[index].tech_allowance ? Number(employee[index].tech_allowance) : 0.00;
        var ops = employee[index].ops_allowance ? Number(employee[index].ops_allowance) : 0.00;
        var special = employee[index].special_allowance ? Number(employee[index].special_allowance) : 0.00;
        var grocery = employee[index].grocery_allowance ? Number(employee[index].grocery_allowance) : 0.00;
        var entertainment = employee[index].entertainment_allowance ? Number(employee[index].entertainment_allowance) : 0.00;
        var medicine = employee[index].medicine_allowance ? Number(employee[index].medicine_allowance) : 0.00;
        var uniform = employee[index].uniform_allowance ? Number(employee[index].uniform_allowance) : 0.00;
        var total_two = daily+comm+transpo+food+hmo+tech+ops+special+grocery+entertainment+medicine+uniform;
        employee[index].total_two = total_two.toFixed(2);

        // TOTAL(3)
        var training_allowance_f2f = employee[index].training_allowance_f2f ? parseFloat(employee[index].training_allowance_f2f) : 0.00;
        var training_allowance_virtual = employee[index].training_allowance_virtual ? parseFloat(employee[index].training_allowance_virtual) : 0.00;
        var regular_ot = employee[index].regular_ot ? parseFloat(employee[index].regular_ot) : 0.00;
        var restday_ot = employee[index].restday_ot ? parseFloat(employee[index].restday_ot) : 0.00;
        var special_holiday_pay = employee[index].special_holiday_pay ? parseFloat(employee[index].special_holiday_pay) : 0.00;
        var night_differential = employee[index].night_differential ? parseFloat(employee[index].night_differential) : 0.00;
        var salary_adj = employee[index].salary_adj ? parseFloat(employee[index].salary_adj) : 0.00;
        var monthly = employee[index].monthly ? parseFloat(employee[index].monthly) : 0.00;
        var semi_monthly = employee[index].semi_monthly ? parseFloat(employee[index].semi_monthly) : 0.00;
        var sum_3 = training_allowance_f2f+training_allowance_virtual+regular_ot+restday_ot+special_holiday_pay+night_differential+salary_adj+monthly+semi_monthly;
        employee[index].total_three = sum_3.toFixed(2);

        var net_pay = parseFloat(employee[index].total_one) + parseFloat(employee[index].total_two) + parseFloat(employee[index].total_three)
        employee[index].total_net_pay = net_pay.toFixed(2);
    }

    function renderEmptyRow () {
        let i;
        let tdArray = [];
        for (i=0; i<=parseInt(45); i++) {
            tdArray.push(
                <td><input className='payroll-form-control' name="employee" id="employee" disabled/></td>
            )
        }
        return tdArray;
    }

    function toNumberFormat (number_string) {
        if (typeof number_string !== "string") {
            number_string = toString(number_string);
        }
        var formattedNumber = number_string.replace(/,/g, "");
        return Number(formattedNumber);
    }

    // Submit added payroll API
    function generatePayroll() {
        var axios = require('axios');
        var qs = require('qs');
        if (click===false) {
            setClick(true);
            axios({
            method: 'post',
            url: window.$link + 'payrolls/add',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: dateFrom,
                date_to: dateTo,
                'employees': employee.map((emp) => {return(emp.employee_id)}),
                'branches': employee.map((emp) => {return(emp.branch_id)}),
                'basic_rate': employee.map((emp) => {return(emp.basic_rate)}),
                'work_days': employee.map((emp) => {return(emp.work_days)}),
                'regular_pay': employee.map((emp) => {return(emp.regular_pay)}),
                'holiday_pay': employee.map((emp) => {return(emp.holiday_pay)}),
                'basic_pay': employee.map((emp) => {return(emp.basic_pay)}),
                'late': employee.map((emp) => {return(emp.late)}),
                'axa_amount': employee.map((emp) => {return(emp.axa_amount)}),
                'cash_advance': employee.map((emp) => {return(emp.cash_advance)}),
                'phone_loan': employee.map((emp) => {return(emp.phone_loan)}),
                'laptop_loan': employee.map((emp) => {return(emp.laptop_loan)}),
                'sss_contribution': employee.map((emp) => {return(emp.sss_contribution)}),
                'philhealth_contribution': employee.map((emp) => {return(emp.philhealth_contribution)}),
                'pagibig_contribution': employee.map((emp) => {return(emp.pagibig_contribution)}),
                'intellicare_amount': employee.map((emp) => {return(emp.intellicare_amount)}),
                'sss_salary_loan': employee.map((emp) => {return(emp.sss_salary_loan)}),
                'sss_calamity_loan': employee.map((emp) => {return(emp.sss_calamity_loan)}),
                'pagibig_loan': employee.map((emp) => {return(emp.pagibig_loan)}),
                'calamity_loan': employee.map((emp) => {return(emp.calamity_loan)}),
                'hmo_maxicare': employee.map((emp) => {return(emp.hmo_maxicare)}),
                'coop': employee.map((emp) => {return(emp.coop)}),
                'wth_tax': employee.map((emp) => {return(emp.wth_tax)}),
                'others': employee.map((emp) => {return(emp.others)}),
                'total_one': employee.map((emp) => {return(emp.total_one)}),
                'daily_allowance': employee.map((emp) => {return(emp.daily_allowance)}),
                'comm_allowance': employee.map((emp) => {return(emp.comm_allowance)}),
                'transpo_allowance': employee.map((emp) => {return(emp.transpo_allowance)}),
                'food_allowance': employee.map((emp) => {return(emp.food_allowance)}),
                'hmo_allowance': employee.map((emp) => {return(emp.hmo_allowance)}),
                'tech_allowance': employee.map((emp) => {return(emp.tech_allowance)}),
                'ops_allowance': employee.map((emp) => {return(emp.ops_allowance)}),
                'special_allowance': employee.map((emp) => {return(emp.special_allowance)}),
                'grocery_allowance': employee.map((emp) => {return(emp.grocery_allowance)}),
                'entertainment_allowance': employee.map((emp) => {return(emp.entertainment_allowance)}),
                'medicine_allowance': employee.map((emp) => {return(emp.medicine_allowance)}),
                'uniform_allowance': employee.map((emp) => {return(emp.uniform_allowance)}),
                'total_two': employee.map((emp) => {return(emp.total_two)}),
                'training_allowance_f2f': employee.map((emp) => {return(emp.training_allowance_f2f)}),
                'training_allowance_virtual': employee.map((emp) => {return(emp.training_allowance_virtual)}),
                'regular_ot': employee.map((emp) => {return(emp.regular_ot)}),
                'restday_ot': employee.map((emp) => {return(emp.restday_ot)}),
                'special_holiday_pay': employee.map((emp) => {return(emp.special_holiday_pay)}),
                'night_differential': employee.map((emp) => {return(emp.night_differential)}),
                'salary_adj': employee.map((emp) => {return(emp.salary_adj)}),
                'monthly': employee.map((emp) => {return(emp.monthly)}),
                'semi_monthly': employee.map((emp) => {return(emp.semi_monthly)}),
                'total_three': employee.map((emp) => {return(emp.total_three)}),
                'total_net_pay': employee.map((emp) => {return(emp.total_net_pay)}),
                'thirteenth_month': employee.map((emp) => {return(emp.thirteenth_month)}),
            })
        }).then(response => {
            toast.success("Payroll Added Successfully!");
            setTimeout(() => {navigate(-1);
            setTimeout(() => refreshPage(), 1000)}, 1000);
        }).catch(function (error) {
            setClick(false);
            toast.error(error.response.data.messages.error);
        });
        }
     }


    // Get all row details: auto fields
    useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        employee.length = 0;
        
        axios({
            method: 'post',
            url: window.$link + 'payrolls/get_auto_fields',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: dateFrom,
                date_to: dateTo,
                employee_id: filters.id,
                branch_id: filters.branch_id,
                employment_status_id: filters.employment_status_id,
                business_unit_id: filters.business_unit,
                salary_type: filters.salary_type
            })
        }).then(response => {
            const payroll = response.data.data;
            var finalRowData = [];
            finalRowData = payroll.map((data, index) => {
                var info = {}
                
                info.name = data.employee_name;
                info.branch_id = data.branch_id;
                info.employee_id = data.employee_id;

                // Earnings
                var basic_pay = data ? (data.basic_pay ? toNumberFormat(data.basic_pay) : 0.00) : 0.00;
                var regular_pay = data ? (data.regular_pay ? toNumberFormat(data.regular_pay) : 0.00) : 0.00;
                var holiday_pay = data ? (data.holiday_pay ? data.holiday_pay : 0.00) : 0.00;
                var work_days = data ? (data.work_days ? data.work_days : 0) : 0;
                var basic_rate = data ? (data.basic_rate ? data.basic_rate: 0.00) : 0.00;

                // Deductions
                var late = data ? (data.late ? toNumberFormat(data.late) : 0.00) : 0.00;
                var sss_amount = data ? (data.sss_amount ? toNumberFormat(data.sss_amount) : 0.00) : 0.00;
                var phic_amount = data ? (data.phic_amount ? toNumberFormat(data.phic_amount) : 0.00) : 0.00;
                var hdmf_amount = data ? (data.hdmf_amount ? toNumberFormat(data.hdmf_amount): 0.00) : 0.00;
                var intellicare_amount = data ? (data.intellicare_amount ? toNumberFormat(data.intellicare_amount): 0.00) : 0.00;                
                var sss_salary_loan = data ? (data.sss_salary_loan ? toNumberFormat(data.sss_salary_loan) : 0.00) : 0.00;
                var sss_calamity_loan = data ? (data.sss_calamity_loan ? toNumberFormat(data.sss_calamity_loan) : 0.00) : 0.00;
                var pagibig_loan = data ? (data.pagibig_loan ? toNumberFormat(data.pagibig_loan) : 0.00) : 0.00;
                var calamity_loan = data ? (data.calamity_loan ? toNumberFormat(data.calamity_loan) : 0.00) : 0.00;
                var hmo_maxicare = data ? (data.hmo_maxicare ? toNumberFormat(data.hmo_maxicare) : 0.00) : 0.00;
                var coop = data ? (data.coop ? toNumberFormat(data.coop) : 0.00) : 0.00;
                var wth_tax = data ? (data.wth_tax ? toNumberFormat(data.wth_tax) : 0.00) : 0.00;
                var axa_amount = data ? (data.axa_amount ? toNumberFormat(data.axa_amount): 0.00) : 0.00;
                var phone_loan = data ? (data.phone_loan ? toNumberFormat(data.phone_loan): 0.00) : 0.00;
                var laptop_loan = data ? (data.laptop_loan ? toNumberFormat(data.laptop_loan): 0.00) : 0.00;
                var cash_advance = data ? (data.cash_advance ? toNumberFormat(data.cash_advance): 0.00) : 0.00;
                var others = data ? (data.others ? toNumberFormat(data.others) : 0.00) : 0.00;

                
                var gains = Number(basic_pay) + Number(holiday_pay);
                var deductions = late+sss_amount + hdmf_amount + phic_amount + intellicare_amount + axa_amount+cash_advance+phone_loan+laptop_loan+sss_salary_loan+sss_calamity_loan+pagibig_loan+calamity_loan+hmo_maxicare+coop+wth_tax+others;
                var total_one = (gains - deductions).toFixed(2);
                info.total_one = total_one;

                // Allowances
                var daily = data ? (data.daily_allowance ? toNumberFormat(data.daily_allowance) : 0.00) : 0.00;
                var comm = data ? (data.comm_allowance ? toNumberFormat(data.comm_allowance) : 0.00) : 0.00;
                var transpo = data ? (data.transpo_allowance ? toNumberFormat(data.transpo_allowance.replace(/,/g, "")) : 0.00) : 0.00;
                var food = data ? (data.food_allowance ? toNumberFormat(data.food_allowance): 0.00) : 0.00;
                var hmo = data ? (data.hmo_allowance ? toNumberFormat(data.hmo_allowance):0.00) : 0.00;
                var tech = data ? (data.tech_allowance ? toNumberFormat(data.tech_allowance):0.00) : 0.00;
                var ops = data ? (data.ops_allowance ? toNumberFormat(data.ops_allowance):0.00) : 0.00;
                var special = data ? (data.special_allowance ? toNumberFormat(data.special_allowance):0.00) : 0.00;
                var grocery = data ? (data.grocery_allowance ? toNumberFormat(data.grocery_allowance):0.00) : 0.00;
                var entertainment = data ? (data.entertainment_allowance ? toNumberFormat(data.entertainment_allowance):0.00) : 0.00;
                var medicine = data ? (data.medicine_allowance ? toNumberFormat(data.medicine_allowance):0.00) : 0.00;
                var uniform = data ? (data.uniform_allowance ? toNumberFormat(data.uniform_allowance):0.00) : 0.00;
                var total_2 = (daily)+(comm)+(transpo)+(food)+(hmo)+(tech)+(ops)+(special)+(grocery)+(entertainment)+(medicine)+(uniform);
                info.thirteenth_month = data ? (data.thirteenth_month ? toNumberFormat(data.thirteenth_month):0.00) : 0.00;
                info.total_two = total_2;

                // Store Corresponding allowances and deductions to each employee
                info.work_days = work_days;
                info.basic_pay = basic_pay;
                info.regular_pay = regular_pay;
                info.holiday_pay = holiday_pay;
                info.basic_rate = basic_rate;
                info.late = late;
                info.sss_contribution = sss_amount;
                info.philhealth_contribution = phic_amount;
                info.pagibig_contribution = hdmf_amount;
                info.intellicare_amount = intellicare_amount;
                info.sss_salary_loan = sss_salary_loan;
                info.sss_calamity_loan = sss_calamity_loan;
                info.pagibig_loan = pagibig_loan;
                info.calamity_loan = calamity_loan;
                info.hmo_maxicare = hmo_maxicare;
                info.coop = coop;
                info.wth_tax = wth_tax;
                info.axa_amount = axa_amount;
                info.cash_advance = cash_advance;
                info.phone_loan = phone_loan;
                info.laptop_loan = laptop_loan;
                info.others = others;
                info.daily_allowance = daily;
                info.comm_allowance = comm;
                info.transpo_allowance = transpo;
                info.food_allowance = food;
                info.hmo_allowance = hmo;
                info.tech_allowance = tech;
                info.ops_allowance = ops;
                info.special_allowance = special;
                info.grocery_allowance = grocery;
                info.entertainment_allowance = entertainment;
                info.medicine_allowance = medicine;
                info.uniform_allowance = uniform;
                info.total_three = 0.00;
            
                info.total_net_pay = Number(total_one)+Number(total_2);

                // setEmployee((prev) => [...prev, info]);
                return info;
            });
            setEmployee(finalRowData);
            setPayrollReady(true);
        }).catch(function (error) {
            tokenExpired(error);
            console.log(error);
            setEmployee([]);
        });
    }, [filters]);


    return (
        <div className="comm-bg">
            <div className="genpayroll-container">
                <Row className="m-0 mt-5" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-employee'>
                            Generate Payroll    
                        </Col>
                    </Row>
                     <Row className='mt-4 ms-2'>
                        <Col >
                            <Form.Label className="h6 m-0 p-0" htmlFor="employee_id">Employee</Form.Label>                                
                            <Form.Control
                                as="select"
                                aria-label="name" 
                                aria-describedby="name" 
                                placeholder="Employees" 
                                name="id" 
                                id='id' 
                                className="mb-2"
                                onChange={(e) => handleFilters(e)}
                            >
                                <option value=""> --- </option>
                                {
                                    allEmployees.map( (x,y) => 
                                        <option value={x.id}>{x.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Col>
                        <Col >
                            <Form.Label className="h6 m-0 p-0" htmlFor="branch">Branches</Form.Label>                                
                            <Form.Control
                                as="select"
                                aria-label="branch_id" 
                                aria-describedby="branch_id" 
                                placeholder="Branches" 
                                name="branch_id" 
                                id='branch_id' 
                                className="mb-2"
                                onChange={(e) => handleFilters(e)}
                            >
                                <option value=""> --- </option>
                                {
                                    allBranches.map( (x,y) => 
                                        <option value={x.id}>{x.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Col>
                        <Col >
                            <Form.Label className="h6 m-0 p-0" htmlFor="employment_status_is">Employment Status</Form.Label>                                
                            <Form.Control
                                as="select"
                                aria-label="employment_status_id" 
                                aria-describedby="employment_status_id" 
                                placeholder="Employment Status" 
                                name="employment_status_id" 
                                id='employment_status_id' 
                                className="mb-2"
                                onChange={(e) => handleFilters(e)}
                            >
                                <option value=""> --- </option>
                                {
                                    allEmploymentStatus.map((x, y) => 
                                        <option value={x.id}>{x.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label className="h6 m-0 p-0" htmlFor="business_unit">Business Unit</Form.Label>                                
                            <Form.Control
                                as="select"
                                aria-label="business_unit" 
                                aria-describedby="business_unit" 
                                placeholder="Employment Status" 
                                name="business_unit" 
                                id='business_unit' 
                                className="mb-2"
                                onChange={(e) => handleFilters(e)}
                            >
                                <option value=""> --- </option>
                                {
                                    allBusinessUnits.map((x, y) => 
                                        <option value={x.id}>{x.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label className="h6 m-0 p-0" htmlFor="salary_type">Salary Type</Form.Label>
                            <Form.Control
                                as="select"
                                aria-label="salary_type" 
                                aria-describedby="salary_type" 
                                placeholder="Salary Type" 
                                name="salary_type" 
                                id='salary_type' 
                                className="mb-2"
                                onChange={(e) => handleFilters(e)}
                            >
                                <option value=""> --- </option>
                                <option value="hourly">Hourly</option> 
                                <option value="weekly">Daily</option>
                                <option value="daily">Weekly</option>
                                <option value="monthly5">Monthly (5)</option>
                                <option value="monthly6">Monthly (6)</option>
                            </Form.Control>
                        </Col>
                        <Col xs={1} className='filter-btn-wrapper'>
                                {/* <button className="export-btn-filter" onClick={submitFilter}><FilterAltIcon/> Filter</button> */}
                        </Col>
                    </Row>
                </Row>
                
                <div className='ebr-table-wrapper'>
                    <div className='genpayroll-wrapper'>
                        {
                            ( function () {
                                return payrollReady ? (
                                    <Table bordered responsive className="text-center payroll-table">
                                            <tr>
                                                <th>Employee</th>
                                                <th>Rate</th>
                                                <th>Work Days</th>
                                                <th>Regular Pay</th>
                                                <th>Holiday Pay</th>
                                                <th>Basic Pay</th>
                                                <th>Late</th>
                                                <th>SSS</th>
                                                <th>HDMF</th>
                                                <th>PhilHealth</th>
                                                <th>Intellicare</th>
                                                <th>SSS Salary Loan</th>
                                                <th>SSS Calamity Loan</th>
                                                <th>PAGIBIG Loan</th>
                                                <th>Calamity Loan</th>
                                                <th>HMO MAXICARE</th>
                                                <th>COOP</th>
                                                <th>Withholding Tax</th>
                                                <th>AXA</th>
                                                <th>Phone Loan</th>
                                                <th>Laptop Loan</th>
                                                <th>Cash Advance</th>
                                                <th>Others</th>
                                                <th>TOTAL (1)</th>
                                                <th>DAILY Allowance</th>
                                                <th>COMM Allowance</th>
                                                <th>TRANSPO Allowance</th>
                                                <th>FOOD Allowance</th>
                                                <th>HMO Allowance</th>
                                                <th>TECH Allowance</th>
                                                <th>OPS Allowance</th>
                                                <th>Special Allowance</th>
                                                <th>Grocery Allowance</th>
                                                <th>Entertainment Allowance</th>
                                                <th>Medicine Allowance</th>
                                                <th>Uniform Allowance</th>
                                                <th>TOTAL (2)</th>
                                                <th>Training Allowance (F2F)</th>
                                                <th>Training Allowance (Virtual)</th>
                                                <th>Regular OT</th>
                                                <th>Restday OT</th>
                                                <th>Special Holiday Pay</th>
                                                <th>Night Differential</th>
                                                <th>Salary Adj</th>
                                                <th>Monthly </th>
                                                <th>Semi Monthly</th>
                                                <th>TOTAL (3)</th>
                                                <th>TOTAL NET PAY</th>
                                                <th>13th Month</th>
                                            </tr>
                                        {
                                            (employee && employee.length) ? (
                                            employee.map((data, index) => {
                                                return (
                                                    <tr>
                                                    <td><input className='payroll-form-control' name="employee" id="employee" value={data.name?data.name.toUpperCase():''} disabled/></td>
                                                    <td><input className='payroll-form-control' name="basic_rate" id="basic_rate" value={data.basic_rate} disabled/></td>
                                                    <td><input className='payroll-form-control' name="work_days" value={data.work_days} disabled/> </td>
                                                    <td><input className='payroll-form-control' type="number" name="regular_pay" id="regular_pay" value={data.regular_pay} disabled/></td>
                                                    <td><input className='payroll-form-control' type="number" name="holiday_pay" id="holiday_pay" value={data.holiday_pay} disabled/></td>
                                                    <td><input className='payroll-form-control' type="number" name="basic_pay" id="basic_pay" value={data.basic_pay} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="late" id="late" value={data.late} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="sss_contribution" id="sss_contribution" value={data.sss_contribution} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="pagibig_contribution" id="pagibig_contribution" value={data.pagibig_contribution} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="philhealth_contribution" id="philhealth_contribution" value={data.philhealth_contribution} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="intellicare_amount" id="intellicare_amount" value={data.intellicare_amount} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="sss_salary_loan" id="sss_salary_loan" value={data.sss_salary_loan} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="sss_calamity_loan" id="sss_calamity_loan" value={data.sss_calamity_loan} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="pagibig_loan" id="pagibig_loan" value={data.pagibig_loan} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="calamity_loan" id="calamity_loan" value={data.calamity_loan} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="hmo_maxicare" id="hmo_maxicare" value={data.hmo_maxicare} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="coop" id="coop" value={data.coop} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="wth_tax" id="wth_tax" value={data.wth_tax} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="axa_amount" id="axa_amount" value={data.axa_amount} onChange={(e) => {handleDataEmp(e, index)}} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="phone_loan" id="phone_loan" value={data.phone_loan} onChange={(e) => {handleDataEmp(e, index)}} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="laptop_loan" id="laptop_loan" value={data.laptop_loan} onChange={(e) => {handleDataEmp(e, index)}} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="cash_advance" id="cash_advance" value={data.cash_advance} onChange={(e) => {handleDataEmp(e, index)}} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="others" id="others" value={data.others} onChange={(e) => {handleDataEmp(e, index)}} /></td>
                                                    <td><input className='payroll-form-control pink-bg' name="total_one" value={employee[index].total_one} disabled/></td>

                                                    <td><input className='payroll-form-control' type="number" name="daily_allowance" id="daily_allowance" value={data.daily_allowance ? data.daily_allowance: '0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="comm_allowance" id="comm_allowance"  value={data.comm_allowance ? data.comm_allowance : '0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="transpo_allowance" id="transpo_allowance"  value={data.transpo_allowance ? data.transpo_allowance : '0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="food_allowance" id="food_allowance"  value={data.food_allowance ? data.food_allowance: '0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="hmo_allowance" id="hmo_allowance"  value={data.hmo_allowance ? data.hmo_allowance:'0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="tech_allowance" id="tech_allowance"  value={data.tech_allowance ? data.tech_allowance:'0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="ops_allowance" id="ops_allowance"  value={data.ops_allowance ? data.ops_allowance:'0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="special_allowance" id="special_allowance"  value={data.special_allowance ? data.special_allowance:'0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="grocery_allowance" id="grocery_allowance"  value={data.grocery_allowance ? data.grocery_allowance:'0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="entertainment_allowance" id="entertainment_allowance"  value={data.entertainment_allowance ? data.entertainment_allowance:'0'} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="medicine_allowance" id="medicine_allowance"  value={data.medicine_allowance} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control' type="number" name="uniform_allowance" id="uniform_allowance"  value={data.uniform_allowance} onChange={(e) => handleDataEmp(e, index)}/></td>
                                                    <td><input className='payroll-form-control pink-bg' name="total_two" value={data.total_two} disabled/></td>

                                                    <td><input className='payroll-form-control' type="number" name="training_allowance_f2f" id="training_allowance_f2f" defaultValue={data.training_allowance_f2f?data.training_allowance_f2f:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="training_allowance_virtual" id="training_allowance_virtual" defaultValue={data.training_allowance_virtual?data.training_allowance_virtual:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="regular_ot" id="regular_ot" defaultValue={data.regular_ot?data.regular_ot:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="restday_ot" id="restday_ot" defaultValue={data.restday_ot?data.restday_ot:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="special_holiday_pay" id="special_holiday_pay" defaultValue={data.special_holiday_pay?data.special_holiday_pay:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="night_differential" id="night_differential" defaultValue={data.night_differential?data.night_differential:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="salary_adj" id="salary_adj" defaultValue={data.salary_adj?data.salary_adj:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="monthly" id="monthly" defaultValue={data.monthly?data.monthly:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control' type="number" name="semi_monthly" id="semi_monthly" defaultValue={data.semi_monthly?data.semi_monthly:'0'} onChange={(e) => handleDataEmp(e, index)} /></td>
                                                    <td><input className='payroll-form-control pink-bg' name="total_three" value={data.total_three} disabled/></td>
                                                    <td><input className='payroll-form-control pink-bg' name="total_net_pay" value={data.total_net_pay} disabled/></td>
                                                    <td><input className='payroll-form-control' type="number" name="thirteenth_month" id="thirteenth_month" defaultValue={data.thirteenth_month?data.thirteenth_month:0.00} disabled/></td>
                                                    </tr>
                                                )})) : (<tr>{renderEmptyRow()}</tr>)
                                        }
                                    </Table>
                                ) : (
                                    <div style={{marginTop:"0%"}} className="newtons-cradle position-relative start-50">
                                        <div className="newtons-cradle__dot"></div>
                                        <div className="newtons-cradle__dot"></div>
                                        <div className="newtons-cradle__dot"></div>
                                        <div className="newtons-cradle__dot"></div>
                                    </div>
                                )
                            })()
                        }
                    </div>
                    <div className='d-flex justify-content-end me-3 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                        <button type="submit" className='save-button' onClick={generatePayroll}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}