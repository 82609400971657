import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'

import { GetAllEmployees } from '../../helpers/CommonApi';
import { validateSalaryloan } from '../../helpers/Validation/SalaryloanValidation';
import InputError from '../../helpers/InputError/InpuError'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

export default function ApplySalaryloan(props) {    
    
    // REACT QUILL
    const { quill, quillRef } = useQuill();

    const userToken = getToken();
    const userId = getUser();

    // Get all employees
    const allEmployees = GetAllEmployees();

    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        employee_id: '',
        type: '',
        subject: '',
        content: '',
        apply_date: '',
    });

    const [isError, setIsError] = useState({
        employee_id: false,
        type: false,
        subject: false,
        content: false,
        apply_date: false
    });
    

    function handle(e, element = null) {
        let newSalaryloanData = { ...data };
        newSalaryloanData[e.target.id] = e.target.value;
        setData(newSalaryloanData);
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }


    // APPLY SSS SALARY LOAN API
    function submitApplySalaryloan(e) {
        var axios = require('axios');
        var qs = require('qs');
        if(validateSalaryloan(data, setIsError)===true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'loans/apply',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                salaryloan_to: data.employee_id,
                salaryloan_from: data.salaryloan_from,
                type: data.type,
                salaryloan_date: dateFormat(data.salaryloan_date, 'MM/dd/yyyy'),
                subject: data.subject,
                content: data.content,
            })
            })
            .then(function (response) {
                toast.success("Letter Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Apply SSS Salary Loan");
                tokenExpired(error);
                setClick(false);
            });
        }
    }   
    
    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // FETCH THE CONTENT OF QUILL
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
            // console.log('Text change!');
            // console.log(quill.getText()); // Get text only
            // console.log(quill.getContents()); // Get delta contents
            // console.log(quill.root.innerHTML); // Get innerHTML using quill
            // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
            var temp_content = quillRef.current.firstChild.innerHTML;
            data.content = temp_content;
          });
          }
      }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Apply SSS Salary Loan
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head'>
                        <Container>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="employee_name">Employee<span className='red'> *</span></Form.Label>
                                    <InputGroup>
                                    <Form.Select name="employee_id" id='employee_id' className="mb-2" onChange={(e) => handle(e)}>
                                            <option>Select Employee</option>
                                            {allEmployees.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                    </Form.Select>
                                    {renderInputError('employee_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Apply Date<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            aria-label="salaryloan_date" 
                                            aria-describedby="salaryloan_date" 
                                            placeholder="dd/mm/yyyy" 
                                            name="salaryloan_date" 
                                            id='salaryloan_date' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('salaryloan_date')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Amount<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="type" 
                                            aria-describedby="type" 
                                            placeholder="Type" 
                                            name="type" 
                                            id='type' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('type')}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Interest Rate<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="type" 
                                            aria-describedby="type" 
                                            placeholder="Type" 
                                            name="type" 
                                            id='type' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('type')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Form.Label className="h6" htmlFor="subject">Reason<span className='red'> *</span></Form.Label>
                            <div ref={quillRef} />
                            <div style={{ width: 500, height: 20}}></div>
                                {/*<Form.Label className="h6" htmlFor="subject">Content<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        aria-label="content" 
                                        aria-describedby="content" 
                                        placeholder="Enter content" 
                                        name="content" 
                                        id='content' 
                                        onChange={(e) => handle(e)}
                                        />
                                {renderInputError('content')}
                                </InputGroup> */}
                        </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitApplySalaryloan}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}