import {handleValidationChange} from '../CommonValidation';

export const validateShift = (data, setIsError) => {
    var isValid = true;

    if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("name", false, setIsError);
    }

    if(data.time_in === "") {
        handleValidationChange("time_in", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("time_in", false, setIsError);
    }

    if(data.time_out === "") {
        handleValidationChange("time_out", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("time_out", false, setIsError);
    }

    if(data.overtime_fee === "") {
        handleValidationChange("overtime_fee", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("overtime_fee", false, setIsError);
    }

    if(data.break_duration === "") {
        handleValidationChange("break_duration", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("break_duration", false, setIsError);
    }

    return isValid
  
}