import {handleValidationChange} from '../Validation/CommonValidation';

export const validateForm = (data, setIsError) => {
    var isValid = true;

    if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("name", false, setIsError);
    }

    if(data.description === "") {
        handleValidationChange("description", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("description", false, setIsError);
    }

    if(data.deadline === "") {
        handleValidationChange("deadline", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("deadline", false, setIsError);
    }

    if(data.recipient_type_prime === "") {
        handleValidationChange("recipient_type_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("recipient_type_prime", false, setIsError);
    }

    if(data.form_to_prime === "") {
        handleValidationChange("form_to_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("form_to_prime", false, setIsError);
    }

    if(data.type_prime === "") {
        handleValidationChange("type_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("type_prime", false, setIsError);
    }

    if(data.question_prime === "") {
        handleValidationChange("question_prime", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("question_prime", false, setIsError);
    }

    // if(data.choices === "") {
    //     handleValidationChange("choices", true, setIsError);
    //     isValid=false;
    // } else {
    //     handleValidationChange("choices", false, setIsError);
    // }
    
    

    return isValid
  
}