import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { getToken, getUser } from "../../utilities/common";
import "../Modals/modal.css";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Navbar from "../Navbar/Navbar";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import NewLogo from '../../images/NewLogo.png'


export default function ViewMemo(props) {
  const userToken = getToken();
  const userId = getUser();
  const [data, setData] = useState({
    requester: getUser(),
    token: getToken(),
  });
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const [announcementId, setAnnouncementId] = useState(location.state);
  const [announcement, setAnnouncement] = useState({});
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  // Get announcement details by ID
  React.useEffect(() => {
    announcement.length = 0;

    var axios = require("axios");
    var qs = require("qs");

    axios({
      method: "get",
      url: window.$link + "announcements/get",
      headers: {
        "api-key": window.$api_key,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        requester: userId,
        token: userToken.replace(/['"]+/g, ""),
        announcement_id: announcementId,
      },
    })
      .then((response) => {
        setAnnouncement(response.data.data.announcements[0]);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="comm-bg">
      <Navbar />
      <div className="comm-page-container">
        <div className="print-page-container me-3" ref={componentRef}>
          {/* <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className='payslip-header d-flex justify-content-end'>ANNOUNCEMENT</Row>
                        <Row className='d-flex justify-content-end'>Date: {(function() {
                                return moment(announcement.announcement_date).format('MMMM DD, YYYY');
                            })()}
                    </Row>

                    <Row className='col-6 p-1 mt-3'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr className='payslip-blue-bg'>
                                    <th colSpan={2}>Announcement No. {announcement.id ? announcement.id : ''}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bold'>Subject</td>
                                    <td>{announcement.subject ? announcement.subject : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>From</td>
                                    <td>{announcement.announcement_from ? announcement.announcement_from : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>To</td>
                                    <td>{(function() {
                                        let to = announcement.details?announcement.details.map(t => t.announcement_to):[];
                                        return to.join(", ");
                                    })()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='p-1 mt-3'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <div dangerouslySetInnerHTML={{__html: announcement.content ? announcement.content : ''}}/>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='d-flex justify-content-end me-3 mt-4'>
                        <Col className='d-flex justify-content-end'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="print-button me-0 noprint me-1">
                                        <span className="export-text"><PrintIcon/> Print</span>
                                    </button>
                                )}
                                content={() => componentRef.current}
                                documentTitle={"Announcement"}
                            />
                            <button className="save-button blue noprint" onClick={()=> navigate(-1)}>
                                <span className="export-text">Back</span>
                            </button>
                        </Col>
                    </Row>
                    
                </Row>  */}

          <Row
            className="p-1"
            style={{
              width: "100%",
            }}
          >
            <div className="topCol me-2">
              <div>
                <img
                  src={NewLogo}
                  alt={"logo"}
                  className="payslip-logo ms-0"
                ></img>
              </div>
            </div>
            <div className="toprightCol">
              <div>
                <ul className="payslip-header d-flex justify-content">
                  MYT SoftDev Solutions Inc.
                </ul>
                <ul className="headeraddress">
                   301 The Greenery, Pope John Paul II Ave, Kasambagan, Cebu City, 6000 Cebu
                </ul>
              </div>
            </div>
            <div className="headerline"></div>
            <div className="announcementheader bold d-flex justify-content-center mb-5">
              ANNOUNCEMENT NO. {currentYear}-00{announcement.id ? announcement.id : ""}
            </div>

        {/* DATE */}
            <Row className="bold">
              <Col xs={2}>DATE</Col>
              <Col xs={1}>:</Col>
              <Col >{(function () {
                    return moment(announcement.announcement_date).format(
                      "MMMM DD, YYYY"
                    );
                  })()}</Col>
            </Row>
            <Row className="bold">
              <Col xs={2}>TO</Col>
              <Col xs={1}>:</Col>
              <Col >{(function () {
                    let to = announcement.details
                      ? announcement.details.map((t) => t.announcement_to)
                      : [];
                    return to.join(", ");
                  })()}
                </Col>
            </Row>
            <Row className="bold">
              <Col xs={2}>FROM</Col>
              <Col xs={1}>:</Col>
              <Col >{announcement.announcement_from? announcement.announcement_from: ""}</Col>
            </Row>
            <Row className="bold mb-3">
              <Col xs={2}>SUBJECT</Col>
              <Col xs={1}>:</Col>
              <Col >{announcement.subject ? announcement.subject : ""}</Col>
            </Row>

            <div className="headerline"></div>
            <div className="updatedon">
              {/* {announcement.content ? announcement.content : ""} */}
                <div dangerouslySetInnerHTML={{__html: announcement.content?announcement.content:''}}/>
            </div>
            <Row className="mt-5">
              <Col className="mt-5">
                <Row className="assigned d-flex justify-content-left margin-bottom">
                  Prepared by:
                </Row>
                <Row className="d-flex justify-content-left">
                  ____________________________________
                </Row>
                <Row className="role d-flex justify-content-left">
                  Executive Assistant
                </Row>

                <Row className="assigned d-flex justify-content-left margin-bottom">
                  Approved by:
                </Row>
                <Row className="d-flex justify-content-left">
                  ____________________________________
                </Row>
                <Row className="role d-flex justify-content-left">
                  Operations Head
                </Row>
              </Col>
            </Row>

            <Row className="d-flex justify-content-end me-3 mt-4">
              <Col className=" print d-flex justify-content-end">
                <ReactToPrint
                  trigger={() => (
                    <button className="print-button me-0 noprint me-1">
                      <span className="export-text">
                        <PrintIcon /> Print
                      </span>
                    </button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={"Announcement"}
                />
                <button
                  className="save-button blue noprint"
                  onClick={() => navigate(-1)}
                >
                  <span className="export-text">Back</span>
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </div>
  );
}
