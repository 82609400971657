import axios from 'axios';
import { toast } from 'react-toastify';

//Common Utility Functions

export const refreshPage = () => {
    window.location.reload();
}

export const getTime = (date) => {
    return  date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}


/***************************
 * Local Storage Utilities
 ***************************/


//return user data from local storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if(userStr) return JSON.parse(userStr);
    else return null;
}

// return user name
export const getUserName = () => {
    const nameStr = localStorage.getItem('name');
    if (nameStr) return JSON.parse(nameStr);
    else return null;
}

//return role id from local storage
export const getRoleId = () => {
    // return localStorage.getItem('role_id') || null;
    const userStr = localStorage.getItem('role_id');
    if(userStr) return JSON.parse(userStr);
    else return null;
}

//return token from local storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
}

//return token expiry from local storage
export const getTokenExpiry = () => {
    return localStorage.getItem('token_expiry') || null;
}

//remove token from local storage
export const removeUserSession = () => {
    logout();
    refreshPage();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('token_expiry');
}

//set the token and user from local storage
export const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
}

// logout
export const logout = () => {
    const userToken = getToken();
    const userId = getUser();
    var axios = require('axios');
    var qs = require('qs');
    
    axios({
        url: window.$link + 'logout',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, '')
        })
    })
}

// tokenExpired
export const tokenExpired = (error) => {
    if(error.response.data.messages.error === 'Token Expired') {
        toast.warning('Token has expired. Please log in again.')
        setTimeout(() => {
            removeUserSession();
        }, 1000)
    }
}