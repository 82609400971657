import React, { useState } from "react";
import {Form, Modal, Row, Col, InputGroup, Container } from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//css
import '../../utilities/common.css'
import 'react-toastify/dist/ReactToastify.css';
// icons
import PaymentIcon from '@material-ui/icons/Payment';

import { validateEmployeeDeduction } from "../../helpers/Validation/Manage/EmployeeDeductionValidation";
import InputError from "../../helpers/InputError/InpuError";

export default function AddEmployeeDeduction(props) {
    const userToken = getToken();
    const userId = getUser();

    const employees = props.allEmployees;
    const [click, setClick] = useState(false);

    const [data, setData] = useState({
        employee_id: '',
        issue_date: '',
        sss_amount: '',
        phic_amount: '',
        hdmf_amount: ''
    });

    const [isError, setIsError] = useState({
        employee_id: '',
        issue_date: '',
        sss_amount: '',
        phic_amount: '',
        hdmf_amount: ''
    });

    function handle(e) {
        const newData = { ...data };
        newData[e.target.id] = e.target.value;
        setData(newData);

    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function submitDeduction(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateEmployeeDeduction(data, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'deductions/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: data.employee_id,
                issue_date: data.issue_date,
                sss_amount: data.sss_amount,
                phic_amount: data.phic_amount,
                hdmf_amount: data.hdmf_amount,
                intellicare_amount: data.intellicare_amount,
                axa_amount: data.axa_amount,
                cash_advance: data.cash_advance,
                phone_loan: data.phone_loan,
                laptop_loan: data.laptop_loan
            })
            }).then(function (response) {
                toast.success("Deduction Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                tokenExpired(error);
                setTimeout(() => refreshPage(), 500);
                setClick(false);
            });
        }
    }

    return(
        <div className="account-details">
            {/* Add/Edit Shift Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Add Deduction
                        </div>
                        
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="name">Deduction Date<span className='red'> *</span></Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                               type="date"
                                                onChange={(e) => handle(e)}
                                                placeholderText={'dd/mm/yyyy'}
                                                id={'issue_date'}
                                                name={'issue_date'}
                                                ariaDescribedBy={'issue_date'}
                                                ariaLabelledBy={'issue_date'}
                                            />
                                        {renderInputError("date")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6">Employee<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                as="select"
                                                aria-label="employee_id" 
                                                aria-describedby="employee_id" 
                                                placeholder="" 
                                                name="employee_id" 
                                                id='employee_id' 
                                                onChange={(e) => handle(e)}
                                            >
                                                <option value=""> --- </option>
                                                {
                                                    employees.map((data) => (
                                                        <option value={data.id}>{data.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                            {renderInputError("employee_id")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                               
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">SSS Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control 
                                                type="number" 
                                                name="sss_amount" 
                                                id="sss_amount"
                                                aria-label="sss_amount" 
                                                aria-describedby="sss_amount" 
                                                onChange={(e) => handle(e)} 
                                            />
                                            {renderInputError("sss_amount")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">PhilHealth Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control 
                                                type="number" 
                                                name="phic_amount" 
                                                id="phic_amount" 
                                                aria-label="phic_amount" 
                                                aria-describedby="phic_amount"
                                                onChange={(e) => handle(e)} 
                                            />
                                        {renderInputError("phic_amount")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Pag-Ibig Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control 
                                                type="number" 
                                                name="hdmf_amount" 
                                                id="hdmf_amount" 
                                                aria-label="hdmf_amount" 
                                                aria-describedby="hdmf_amount"
                                                onChange={(e) => handle(e)} 
                                            />
                                        {renderInputError("hdmf_amount")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Intellicare Amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number" 
                                                name="intellicare_amount" 
                                                id="intellicare_amount" 
                                                aria-label="intellicare_amount" 
                                                aria-describedby="intellicare_amount"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Cash Advance</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number" 
                                                name="cash_advance" 
                                                id="cash_advance" 
                                                aria-label="cash_advance" 
                                                aria-describedby="cash_advance"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">AXA Amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number" 
                                                name="axa_amount" 
                                                id="axa_amount" 
                                                aria-label="axa_amount" 
                                                aria-describedby="axa_amount"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Phone Loan</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number" 
                                                name="phone_loan" 
                                                id="phone_loan" 
                                                aria-label="phone_loan" 
                                                aria-describedby="phone_loan"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Laptop Loan</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number" 
                                                name="laptop_loan" 
                                                id="laptop_loan" 
                                                aria-label="laptop_loan" 
                                                aria-describedby="laptop_loan"
                                                onChange={(e) => handle(e)} 
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                            <button type="submit" className='save-button' onClick={submitDeduction}>Save</button>
                        </div>
                    </Modal.Body>
            </Modal>
        </div>
    );
}