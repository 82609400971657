import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddDeduction from "./AddDeduction";
import EditDeduction from "./EditDeduction";

// icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SearchIcon from "@mui/icons-material/Search";

// css
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"
import './Deduction.css'

import { validateBranches } from "../../helpers/Validation/Manage/BanchesValidation";

export default function Deduction() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [deductionId, setDeductionId] = useState()

    // Validation
    const [isError, setIsError] = useState({
        name: false
    });

    // get all Deductions
    const [allDeductions, setAllDeductions] = useState([]);
    const [filteredDeductions, setFilteredDeductions] = useState([]);

    // GET ALL BRANCHES
    React.useEffect(() => {
        allDeductions.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'deductions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                deduction_id: ""
            }
        }).then(response => {
            setAllDeductions(response.data.data);
            setFilteredDeductions(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [addDeduction, setAddDeduction] = useState(false);
    const handleAddDeduction = () => setAddDeduction(true);
    const handleCloseAddDeduction = () => setAddDeduction(false);

    const [editDeduction, setEditDeduction] = useState(false);
    const handleEditDeduction = () => setEditDeduction(true);
    const handleCloseEditDeduction = () => setEditDeduction(false);


    const [deleteDeduction, setDeleteDeduction] = useState(false);
    const handleDeleteDeduction = () => setDeleteDeduction(true);
    const handleCloseDeleteDeduction = () => setDeleteDeduction(false);


    function onClickAddDeduction() {
        handleCloseAddDeduction();
        handleAddDeduction();
    }


    const [editDeductionData, setEditDeductionData] = useState({});

    // GET BRACH BY ID FOR EDITING
    function onClickEditDeduction(deduction_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'deductions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                deduction_id: deduction_id
            }
        }).then(response => {
            setEditDeductionData(response.data.data[0]);
            handleCloseEditDeduction();
            handleEditDeduction();
        }).catch(function (error) {
            console.log('Error getting all Deductions');
            console.log(error);
        });
    }
    
    const [deleteDeductionData, setDeleteDeductionData] = useState({});

    // GET BRANCH BY BRANCH ID FOR DELETION
    function onClickDeleteDeduction(deduction_id) {
        setDeductionId(deduction_id)
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'deductions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                deduction_id: deduction_id
            }
        }).then(response => {
            setDeleteDeductionData(response.data.data[0]);
            handleCloseDeleteDeduction();
            handleDeleteDeduction();
        }).catch(function (error) {
            console.log('Error getting all Deductions');
            console.log(error);
        });
    }

    // DELETING BRANCH
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
        // if(click === false) {
            axios({
            url: window.$link + 'deductions/delete/' + deductionId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, '')
            })
            })
            .then(function (response) {
                console.log(response);
                toast.success("Deduction Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                // setClick(false);
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
        // }
        // setClick(true);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allDeductions) {
            allDeductions.forEach(function(deduction) {
                if((deduction.name).toLowerCase().includes(term)) {
                    filteredArr.push(deduction);
                }
            })
        }

        if (term == "") {
            setFilteredDeductions(allDeductions);
        } else {
            setFilteredDeductions(filteredArr);
        }
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-branch'>
                            Deductions 
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-branch" onClick={onClickAddDeduction}>
                                        <span className="export-text"><AccountBalanceIcon/> Add Deduction</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-branch">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Deduction"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddDeduction 
                    showmodal1 = {addDeduction}
                    hidemodal1 = {handleCloseAddDeduction}
                    showmodal1handler = {onClickAddDeduction}
                />

                <EditDeduction 
                    showmodal1 = {editDeduction}
                    hidemodal1 = {handleCloseEditDeduction}
                    showmodal1handler = {onClickEditDeduction}
                    editDeductionData = {editDeductionData}
                />

                <DeletePrompt
                    name = "BRANCH"
                    show = {deleteDeduction}
                    hide = {handleCloseDeleteDeduction}
                    remover = {handleRemove}
                />

                <div className="system-deduction-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='deductions'
                            tableHeaders={["Deduction Name", "Description", ""]}
                            tableData={filteredDeductions}
                            deleteHandler={onClickDeleteDeduction}
                            editHandler={onClickEditDeduction}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}