import {handleValidationChange} from '../Validation/CommonValidation';

export const validateSchedule = (data, setIsError) => {
    var isValid = true;

    if(data.employee_id === "" || data.employee_id === null) {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }

    if(data.shift_id === "" || data.shift_id === null) {
        handleValidationChange("shift_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("shift_id", false, setIsError);
    }

    if(data.date_from === "" || data.date_from === null) {
        handleValidationChange("date_from", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date_from", false, setIsError);
    }

    if(data.date_to === "" || data.date_to === null) {
        handleValidationChange("date_to", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date_to", false, setIsError);
    }

    return isValid
  
}