import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllProjects, GetAllBusinessUnits } from "../../helpers/CommonApi";
import { toast } from 'react-toastify';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'

import { GetAllEmployees } from '../../helpers/CommonApi';
import { validateLetter } from '../../helpers/Validation/LetterValidation';
import InputError from '../../helpers/InputError/InpuError'

export default function AddCommunicationLetters(props) {    
    const userToken = getToken();
    const userId = getUser();
    const {quill, quillRef} = useQuill(); // REACT QUILL
    // Get all employees
    const allEmployees = GetAllEmployees();
    const allBusinessUnits = GetAllBusinessUnits();
    const allProjects = GetAllProjects();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();
    const letterTypes = props.letterTypes;
    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        letter_type_id: '',
        subject: '',
        content: '',
        letter_date: '',
        letter_details: [{
            type: '',
            letter_to: ''
        }],
        type_prime: '',
        letter_to_prime: ''
    });

    const [isError, setIsError] = useState({
        employee_id: false,
        letter_type_id: false,
        subject: false,
        content: false,
        letter_date: false,
        type_prime: false,
        letter_to_prime: false
    });
    

    function handle(e, element = null) {
        let newWarningData = { ...data };
        if (e.target.id == "type" || e.target.id == "letter_to" ) {
            if (element != null) {
                let index = data["letter_details"].map(t => t.id).indexOf(element.id);
                newWarningData["letter_details"][index][e.target.id] = e.target.value;
            }
        } else if (e.target.id == "type_prime" || e.target.id == "letter_to_prime") {
            if (element != null) {
                let index = data["letter_details"].map(t => t.id).indexOf(element.id);
                newWarningData[e.target.id] = e.target.value;
                if (e.target.id == "type_prime") {
                    newWarningData["letter_details"][index].type = e.target.value;
                } else {
                    newWarningData["letter_details"][index].letter_to = e.target.value;
                }
            }
        } else {
            newWarningData[e.target.id] = e.target.value;
        }
        setData(newWarningData);
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }


    // ADD WARNING LETTER API
    function submitAddCommunicationLetter(e) {
        var axios = require('axios');
        var qs = require('qs');
        console.log(data);
        if (validateLetter(data, setIsError)==true && click===false) {
            axios({
            url: window.$link + 'warnings/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                letter_from: data.letter_from,
                letter_type_id: data.letter_type_id,
                letter_date: dateFormat(data.letter_date, 'MM/dd/yyyy'),
                subject: data.subject,
                content: data.content,
                no_of_letter_to: data.letter_details.length,
                letter_details: data.letter_details
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("Letter Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Add Letter");
                tokenExpired(error);
                setClick(false);
            });
        }
    }   
    
    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addRecipient(e) {
        var recipients = data.letter_details;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            letter_to:''
        });

        setData((prev) => {
            return {
                ...prev,
                letter_details: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = data.letter_details;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type == '') {
            data.type_prime = '';
            data.letter_to_prime = '';
        }

        setData((prev) => {
            return {
                ...prev,
                letter_details: recipients
            }
        })
    }

    // Fetch the quill content
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                // data.content = temp_content;
                setData((prev) => {
                    return {
                        ...prev,
                        content: temp_content
                    }
                });
          });
        }
    }, [quill]);

    return (
        <div className='account-letter_details'>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Add Communication Letter
                    </div>
                    <div className='body-head'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="type">Date<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            aria-label="letter_date" 
                                            aria-describedby="letter_date" 
                                            placeholder="dd/mm/yyyy" 
                                            name="letter_date" 
                                            id='letter_date' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('letter_date')}
                                    </InputGroup>
                                </Col>
                                 <Col>
                                    <Form.Label className="h6" htmlFor="subject">From<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="letter_from" 
                                            aria-describedby="letter_from" 
                                            placeholder="" 
                                            name="letter_from" 
                                            id='letter_from' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('letter_from')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="type">Type<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select 
                                            aria-label="letter_type_id"
                                            aria_describedby="letter_type_id"
                                            name="letter_type_id"
                                            id="letter_type_id"
                                            placeholder=''
                                            onChange={(e) => handle(e)}
                                        >
                                            <option value=''>Select Type</option>
                                            {
                                                letterTypes.map((data) => (
                                                    <option value={data.id}>{data.name}</option>
                                                ))
                                            }
                                        </Form.Select>

                                        {renderInputError('type')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="subject">Subject<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="subject" 
                                        aria-describedby="subject" 
                                        placeholder="" 
                                        name="subject" 
                                        id='subject' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('subject')}
                                </InputGroup>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                {renderInputError('content')}
                                <div ref={quillRef} />
                                <div style={{ width: 500, height: 20}}></div>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Recipient</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                            {data["letter_details"].map((element, i) => {
                                if (i===0) {
                                return (
                                <Row key={element.key}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}>
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employee">Employee</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                            {renderInputError("type_prime")}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="letter_to_prime">Letter To <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select 
                                                aria-label="letter_to" 
                                                aria-describedby="letter_to" 
                                                placeholder="Letter To" 
                                                name="letter_to_prime" 
                                                id='letter_to_prime' 
                                                onChange={(e) => handle(e, element)}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type == "business_unit") ? (
                                                        allBusinessUnits.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "project") ? (
                                                        allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "position") ? (
                                                        allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "employment_status") ? (
                                                        allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "branch") ? (
                                                        allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "department") ? (
                                                        allDepartments.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "employee") ? (
                                                        allEmployees.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Select>
                                            {renderInputError("letter_to_prime")}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                ) } else {
                                    return (
                                <Row key={element.key}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}>
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="letter_to">Letter To</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select 
                                                aria-label="letter_to" 
                                                aria-describedby="letter_to" 
                                                placeholder="Letter To" 
                                                name="letter_to" 
                                                id='letter_to' 
                                                onChange={(e) => handle(e, element)}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type == "business_unit") ? (
                                                        allBusinessUnits.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "project") ? (
                                                        allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "position") ? (
                                                        allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "employment_status") ? (
                                                        allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "branch") ? (
                                                        allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "department") ? (
                                                        allDepartments.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                )
                                }
                            }
                            )}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddCommunicationLetter}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}