import {handleValidationChange} from '../Validation/CommonValidation';

export const validateWorkFromAnywhere = (data, setIsError) => {
   
    var isValid = true;

    if(data.employee_id === "" || data.employee_id === null) {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }

    if(data.date_start === "" || data.date_start === null) {
        handleValidationChange("date_start", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date_start", false, setIsError);
    }

    if(data.no_of_days === "" || data.no_of_days === null) {
        handleValidationChange("no_of_days", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("no_of_days", false, setIsError);
    }

    if(data.remarks === "" || data.remarks === null) {
        handleValidationChange("remarks", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("remarks", false, setIsError);
    }

    return isValid;

}