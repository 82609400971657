import React, { useState } from 'react';
import {Modal, Row, Col, Form, Container, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker'

import { validateSchedule } from '../../helpers/Validation/ScheduleValidation';
import InputError from '../../helpers/InputError/InpuError'

//css
import './Schedule.css'
import '../../utilities/common.css'

export default function AddSchedule(props) {
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null)
    const [selectedDateTo, setSelectedDateTo] = useState(null)

    // Get all employee & shifts
    const allEmployees = props.allEmployees;
    const allShifts = props.allShifts;

    const [data, setData] = useState({
        employee_id: '',
        shift_id: '',
        date_from: '',
        date_to: ''
    });

    const [isError, setIsError] = useState({
        employee_id: false,
        shift_id: false,
        date_from: false,
        date_to: false
    });


    // Add schedule
    function submitAddSchedules(e) {
        e.preventDefault();
        var axios = require('axios');
        var qs = require('qs');
        if (validateSchedule(data, setIsError) === true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'schedules/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: data.employee_id,
                shift_id: data.shift_id,
                date_from: data.date_from,
                date_to: data.date_to
            })
            })
            .then(function (response) {
                toast.success("Schedule Added Successfully!");
                refreshPage();
            })
            .catch(function (error) {
                toast.error('Adding Error!');
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function handle(e) {
        const newData = { ...data };
        setData(newData);
        newData[e.target.id] = e.target.value;
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return(
        <div>
            {/* Add/Edit Schedule Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                <Form>
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Add Schedule
                        </div>
                        <button type="button" class="btn-close" onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Employee <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Select name="employee_id" id='employee_id' className="mb-2" onChange={(e) => handle(e)}>
                                                <option value={''}>Select Employee</option>
                                                {allEmployees.map((data) => {
                                                    return (
                                                        <option value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {renderInputError("employee_id")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Shift <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Select name="shift_id" id='shift_id' className="mb-2" onChange={(e) => handle(e)}>
                                                <option value={''}>Select Shift</option>
                                                {allShifts.map((data) => {
                                                    return (
                                                        <option value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                             {renderInputError("shift_id")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Date From <span className='red'> *</span></Form.Label>
                                        <Form.Control 
                                            type="date" 
                                            id="date_from" 
                                            name="date_from" 
                                            className="mb-2" 
                                            onChange={(e) => handle(e)}
                                        />
                                         {renderInputError("date_from")}
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Date To <span className='red'> *</span></Form.Label>
                                        <Form.Control 
                                            type="date" 
                                            id="date_to" 
                                            name="date_to" 
                                            className="mb-2" 
                                            onChange={(e) => handle(e)}
                                        />
                                         {renderInputError("date_to")}
                                    </Col>
                                </Row>                               
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                            <button type="submit" className='save-button' onClick={submitAddSchedules}>Save</button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    );
}