import React, { useState, useRef } from "react";
import { getToken, getUser, tokenExpired, refreshPage} from "../../utilities/common";
import moment from "moment";
import {Row, Col} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import {toast} from 'react-toastify'

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import ApplySalaryloan from "./ApplySalaryloan";

// icons
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';

// css
import '../Modals/modal.css'
import './Salaryloan.css';
import "../../utilities/common.css"
import 'react-datepicker/dist/react-datepicker.css'


function Salaryloan() {

    //get all employees
    const userToken = getToken();
    const userId = getUser();
    const componentRef = useRef()
    // const [allSalaryloans, setAllSalaryloans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [salaryloanId, setSalaryloanId] = useState();
    const [printDelete, setShowDelete] = useState(false);
    const currentDate = new Date();
    const dateToday = new Date().getDate();

    const [filterDate, setFilterDate] = useState({
        date_from: dateToday <= 15 ? getFirstDayOfMonth() : get16thDayOfMonth(),
        date_to: dateToday <= 15 ? get15thDayOfMonth() : getLastDayOfMonth()
    })

    const allSalaryloans = [
        {
            employee_name: 'John Doe',
            salaryloan_amount: 'Php 200.00',
            salaryloan_balance: 'Php 200.00',
            salaryloan_amount_paid: 'Php 200.00',
            salaryloan_remaining_balance: 'Php 300.00',
        }
    ];


    function onClickDelete(salaryloan_id) {
        setSalaryloanId(salaryloan_id);
        setShowDelete(true);
    }

    function handleRemove () {
        var axios = require('axios');
        var qs = require('qs');
        console.log(salaryloanId);
        axios({
            url: window.$link + 'salaryloans/delete/' + salaryloanId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
        })
        })
        .then(function (response) {
            toast.success("Salaryloan Deleted Successfully!");
            setTimeout(() => refreshPage(), 1500);
        })
        .catch(function (error) {
            toast.error('Failed to Delete Salaryloan');
            tokenExpired(error);
        });
    }

    const [applySalaryloan, setApplySalaryloan] = useState(false);
    const handleApplySalaryloan = () => setApplySalaryloan(true);
    const handleCloseApplySalaryloan = () => setApplySalaryloan(false);

    // APPLY SALARY LOAN HANDLER
    function onClickApplySalaryloan() {
        handleCloseApplySalaryloan();
        handleApplySalaryloan();
    }

    function getFirstDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

    function get15thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
    }

    function get16thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 16);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
    }


    // GET ALL SalaryloanS
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();
        allSalaryloans.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'salaryloans/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                salaryloan_id: ''
            })
        }).then(response => {
            console.log(response.data.data);
            // setAllSalaryloans(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
            // setAllSalaryloans([]);
        });
    },[filterDate]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={5} className='d-flex comm-header-employee'>
                            SSS Salary Loan
                        </Col>
                        <Col className='d-flex justify-content-end me-4'>
                            <Row>
                                <Col className="me-5">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button-apply py-2">
                                                <span className="export-text"><PrintIcon/> Print</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"SSS Salary Loan"}
                                    />
                                </Col>
                                <Col className="me-5">
                                    <button className="export-button-apply py-2" onClick={onClickApplySalaryloan}>
                                        <span className="export-text"><AddBoxIcon/> Apply</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col> 
                    </Row>
                </Row>

                <ApplySalaryloan
                    showmodal1 = {applySalaryloan}
                    hidemodal1 = {handleCloseApplySalaryloan}
                    showmodal1handler = {onClickApplySalaryloan}
                />

                {/* Date Filter */}
                <Row className="px-3 mt-4">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row>

                {/* TABLE HERE */}
                <div className="salaryloan-wrapper mt-2" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">SSS Salary Loan</h5>
                    
                    <TableTemplate
                                tableType='salaryloan'
                                tableHeaders={["Employee Name", "Loan Amount", "Remaining Balance", ""]}
                                tableData={allSalaryloans}
                                deleteHandler={onClickDelete}
                            />

                </div>
            </div>
        </div>
    );
}

export default Salaryloan;