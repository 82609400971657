import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import moment from "moment";
import * as Icon from 'react-bootstrap-icons';

// components
import Navbar from "../Navbar/Navbar"
import Dashboard from "../Dashboard/Dashboard"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddBranch from "./AddBranch";
import EditBranch from "./EditBranch";

// icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SearchIcon from "@mui/icons-material/Search";

// css
import './Branch.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"

import { validateBranches } from "../../helpers/Validation/Manage/BanchesValidation";

export default function Branch() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [branchId, setBranchId] = useState()

    // Validation
    const [isError, setIsError] = useState({
        name: false
    });

    // get all Branches
    const [allBranches, setAllBranches] = useState([]);
    const [filteredBranches, setFilteredBranches] = useState([]);

    // GET ALL BRANCHES
    React.useEffect(() => {
        allBranches.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'branches/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then(response => {
            // console.log(response.data.data);
            setAllBranches(response.data.data);
            setFilteredBranches(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            // console.log('Error getting all Branches');
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [addBranch, setAddBranch] = useState(false);
    const handleAddBranch = () => setAddBranch(true);
    const handleCloseAddBranch = () => setAddBranch(false);

    const [editBranch, setEditBranch] = useState(false);
    const handleEditBranch = () => setEditBranch(true);
    const handleCloseEditBranch = () => setEditBranch(false);


    const [deleteBranch, setDeleteBranch] = useState(false);
    const handleDeleteBranch = () => setDeleteBranch(true);
    const handleCloseDeleteBranch = () => setDeleteBranch(false);


    function onClickAddBranch() {
        handleCloseAddBranch();
        handleAddBranch();
    }


    const [editBranchData, setEditBranchData] = useState({});

    // GET BRACH BY ID FOR EDITING
    function onClickEditBranch(branch_id) {
        // console.log("THE BRANCH ID", branch_id);
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'branches/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: branch_id
            }
        }).then(response => {
            setEditBranchData(response.data.data[0]);
            handleCloseEditBranch();
            handleEditBranch();
        }).catch(function (error) {
            console.log('Error getting all Branches');
            console.log(error);
        });
    }
    
    const [deleteBranchData, setDeleteBranchData] = useState({});

    // GET BRANCH BY BRANCH ID FOR DELETION
    function onClickDeleteBranch(branch_id) {
        setBranchId(branch_id)
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'branches/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: branch_id
            }
        }).then(response => {
            setDeleteBranchData(response.data.data[0]);
            handleCloseDeleteBranch();
            handleDeleteBranch();
        }).catch(function (error) {
            console.log('Error getting all Branches');
            console.log(error);
        });
    }

    // DELETING BRANCH
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
        // if(click === false) {
            axios({
            url: window.$link + 'branches/delete/' + branchId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, '')
            })
            })
            .then(function (response) {
                console.log(response);
                toast.success("Branch Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                // setClick(false);
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
        // }
        // setClick(true);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allBranches) {
            allBranches.forEach(function(branch) {
                if((branch.name).toLowerCase().includes(term)) {
                    filteredArr.push(branch);
                }
            })
        }

        if (term == "") {
            setFilteredBranches(allBranches);
        } else {
            setFilteredBranches(filteredArr);
        }
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-branch'>
                            Branch   
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-branch" onClick={onClickAddBranch}>
                                        <span className="export-text"><AccountBalanceIcon/> Add Branch</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-branch">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Branch"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddBranch 
                    showmodal1 = {addBranch}
                    hidemodal1 = {handleCloseAddBranch}
                    showmodal1handler = {onClickAddBranch}
                />

                <EditBranch 
                    showmodal1 = {editBranch}
                    hidemodal1 = {handleCloseEditBranch}
                    showmodal1handler = {onClickEditBranch}
                    editBranchData = {editBranchData}
                />

                <DeletePrompt
                    name = "BRANCH"
                    show = {deleteBranch}
                    hide = {handleCloseDeleteBranch}
                    remover = {handleRemove}
                />

                <div className="branch-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='branch'
                            tableHeaders={["Branch Name", ""]}
                            tableData={filteredBranches}
                            deleteHandler={onClickDeleteBranch}
                            editHandler={onClickEditBranch}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}