import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import * as Icon from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';

import { validateBranches } from '../../helpers/Validation/Manage/BanchesValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditDeduction(props) {
    const userToken = getToken();
    const userId = getUser();

    // Validation
    const [isError, setIsError] = useState({
        name: false
    });
    const [click, setClick] = useState(false);

    const [editDeductionData, setEditDeductionData] = useState(props.editDeductionData);
    // SUBMIT EDITED BRANCH
    function submitEditDeduction(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateBranches(editDeductionData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'deductions/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                deduction_id: editDeductionData.id,
                name: editDeductionData.name,
                description: editDeductionData.description
            })
            })
            .then(function (response) {
                // console.log(response);
                toast.success("Deduction Edited Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                console.log(editDeductionData);
                toast.success("Deduction not successfuly added!");
                console.log(error.response.data.messages);
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function handle(e, element = null) {
        let editedDeductionData = { ...props.editDeductionData };
        editedDeductionData[e.target.id] = e.target.value;
        setEditDeductionData(editedDeductionData);
    }

    function renderInputError(var_name) {
        let value = editDeductionData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Deduction
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll mt-2 p-0'>
                        <Container>
                            <Row>
                                <Form.Label className="h6" htmlFor="name">Deduction Name <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={props.editDeductionData?props.editDeductionData.name:""}
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('name')}
                                </InputGroup>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="name">Description</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={props.editDeductionData?props.editDeductionData.description:""}
                                        type="text"
                                        aria-label="descripiption" 
                                        aria-describedby="descripiption" 
                                        placeholder="" 
                                        name="descripiption" 
                                        id='descripiption' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('name')}
                                </InputGroup>
                            </Row>
                        </Container>
                    </div>
                    
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditDeduction}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}