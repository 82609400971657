import React, { useState } from "react";
import { getUser, getToken, refreshPage, getRoleId, tokenExpired } from "../../utilities/common";
import {Modal, Row, Col, Form, Container, InputGroup} from 'react-bootstrap'
import { toast } from "react-toastify";
import moment from "moment";
import * as Icon from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';


// components
import TableTemplate from "../../utilities/table_template";
import DeletePrompt from "../Modals/DeletePrompt";
import Navbar from "../Navbar/Navbar"
import GenForms from "./GenForms";
import DeleteForms from "./DeleteForms";
import EditForms from "./EditForms";

// icons
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SearchIcon from "@mui/icons-material/Search";

//css
import './Forms.css'
import '../Modals/modal.css'
import '../../utilities/common.css'

export default function Forms() {

    const navigate = useNavigate();
    const userToken = getToken();
    const userId = getUser();
    const userRoleId = getRoleId();
    const [loading, setLoading] = useState(true);

    const [deleteForms, setDeleteBranch] = useState(false);
    const handleDeleteBranch = () => setDeleteBranch(true);
    const handleCloseDeleteBranch = () => setDeleteBranch(false);

    const [formId, setFormId] = useState();
    const [allForms, setAllForms] = useState([]);
    const [filteredForms, setFilteredForms] = useState([]);

    function getFormDetailsById (form_id, task) {
        var axios = require('axios');
        var qs = require('qs');
        axios ({
            url: window.$link + 'forms/details',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                id: form_id
            })
        }).then (function (response) {
            const res = response.data.data;
            const question_set = [...res.open_ended_questions?res.open_ended_questions:'', ...res.close_ended_questions?res.close_ended_questions:''];
            var question_info = question_set.map((data) => {
                var info = data;
                info.question_type = data.type;
                return info;
            })
            const sortedQuestion = question_info.sort((a, b) => {return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0});
            if (task==='edit') {
                navigate('edit', {state: {form_details: res.form[0], recipients: res.recipients, question_set: sortedQuestion}});
            } else if (task ==='survey') {
                navigate('survey', {state: {form_details: res.form[0], recipients: res.recipients, question_set: sortedQuestion}});
            }
        }).catch(function (error) {
            console.log(error)
        })
    }


    function onClickEditForms(forms_id) {
        getFormDetailsById(forms_id, 'edit');
    }

    function onClickSurvey (forms_id) {
        getFormDetailsById(forms_id, 'survey');
    }

    function onClickDeleteForms(form_id) {
        setFormId(form_id);
        handleDeleteBranch();
    }

    // Search by Form Name
    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allForms) {
            allForms.forEach(function(form) {
                if((form.name).toLowerCase().includes(term)) {
                    filteredArr.push(form);
                }
            })
        }

        if (term == "") {
            setFilteredForms(allForms);
        } else {
            setFilteredForms(filteredArr);
        }
    }

    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');

        axios ({
            url: window.$link + 'forms/delete/'+formId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            })
        }).then (function (response) {
            toast.success("Successfully Deleted Form!");
            setTimeout(() => refreshPage(), 500);
        }).catch(function (error) {
            toast.error("Failed to Delete Form")
            console.log(error);
        })
    }

     // Get all forms
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        setAllForms([]);
        setFilteredForms([]);

        axios ({
            url: window.$link + 'forms/get',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                id: ''
            })
        }).then (function (response) {
            setAllForms(response.data.data);
            setFilteredForms(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        })
    }, []);

    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1 m-0" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-employee'>
                            Evaluation Form   
                        </Col>
                        {
                            (userRoleId === '1' || userRoleId === '2') ? (
                                <Col className='d-flex justify-content-end me-3'>
                                <Row>
                                    <Col className="me-5">
                                    <Link to="/forms/generate" state={{from: "", to: ""}}>
                                    <div>
                                        <button className="export-button p-1">
                                            <span className="export-text"><QuestionAnswerIcon/> Create Form</span>
                                        </button>
                                        </div>
                                    </Link>
                                    </Col>
                                </Row>
                            </Col> 
                            ) : (
                                <p></p>
                            )
                        }    
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup>
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Forms"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <DeletePrompt
                    name = "FORM"
                    show = {deleteForms}
                    hide = {handleCloseDeleteBranch}
                    remover = {handleRemove}
                />


                <div className="form-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='forms'
                                tableHeaders={["Form Name", "Date Created", "Deadline", "Status", ""]}
                                tableData={filteredForms}
                                editHandler={onClickEditForms}
                                deleteHandler={onClickDeleteForms}
                                surveyHandler={onClickSurvey}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>
            </div>
        </div>
    );
}