import { useState } from 'react';
import { Modal, Container, Row, Col, Form, InputGroup } from 'react-bootstrap'
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import '../Modals/modal.css'
import "../../utilities/common.css"

import { validateHoliday } from '../../helpers/Validation/Manage/HolidayValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function AddHoliday(props) {
    // add holiday API
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);

    const [data, setData] = useState({
        name: '',
        date: '',
        bonus: '',
        type: '',
        with_pay: ''
    });

    const [isError, setIsError] = useState({
        name: '',
        date: '',
    });

    function submitAddHoliday(e) {
        e.preventDefault();
        
        var axios = require('axios');
        var qs = require('qs');
        
        if (validateHoliday(data, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'holidays/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: data.name,
                date: data.date,
                bonus: data.bonus,
                remarks: data.type,
                with_pay: data.with_pay,
            })
            })
            .then(function (response) {
                // console.log(response);
                toast.success("Holiday Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error('Failed to Add Holiday');
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function handle(e) {
        const newHoliday = { ...data };
        newHoliday[e.target.id] = e.target.value;
        newHoliday["with_pay"] = e.target.checked?1:0;
        // console.log(newHoliday)
        setData(newHoliday);
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return(
        <div>
            {/* Add/Edit Shift Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                <Modal.Body>
                    <form>
                        <div className="modal-header p-0">
                            Add Holiday
                        </div>
                        <button type="button" class="btn-close" onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        {/* <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span> */}
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Name<span className='red'> *</span></Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                type="text" 
                                                name="name"
                                                id="name"
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                            {/* <InputError
                                                isValid={isError.name}
                                                message={"This field is required."}
                                            /> */}
                                        {renderInputError('name')}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Date<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                className='input-field'
                                                type="date"
                                                placeholder="" 
                                                name="date" 
                                                id='date' 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError('date')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Add-on Rate (%)</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="number" 
                                                name="bonus" 
                                                id="bonus" 
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Type</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                as="select"
                                                aria-label="type" 
                                                aria-describedby="type" 
                                                placeholder="type" 
                                                name="type" 
                                                id='type' 
                                                onChange={(e) => handle(e)}
                                                >
                                                <option value=""> --- </option>
                                                <option value="regular"> Regular </option>
                                                <option value="special_nonworking"> Special Non-Working </option>
                                                <option value="special_working"> Special Working</option>
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row><span className='white'>---</span></Row>
                                        <Row>
                                            <Col xs={1}>
                                                <Form.Check type="checkbox" name="with_pay" className="float-right form-checkbox ms" id='with_pay' onChange={(e) => handle(e)}/>
                                            </Col>
                                            <Col>
                                            <Form.Label className="h6" htmlFor="name">With Pay</Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={props.purpose === 'add' ? (e) => submitAddHoliday(e) : props.hide}>Save</button>
                    </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}