import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import moment from "moment";
import * as Icon from 'react-bootstrap-icons';

// components
import Navbar from "../Navbar/Navbar"
import Dashboard from "../Dashboard/Dashboard"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddProject from "./AddProject";
import EditProject from "./EditProject";
import DeleteProject from "./DeleteProject";
import "../../utilities/loader_1.css"

// icons
import SearchIcon from "@mui/icons-material/Search";
import WorkIcon from '@mui/icons-material/Work';

// css
import './Project.css';
import '../Modals/modal.css'
import "../../utilities/common.css"

export default function Project() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);

    // get all Project
    const [allProjects, setAllProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);

    React.useEffect(() => {
        allProjects.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'projects/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                project_id: ""
            }
        }).then(response => {
            // console.log(response.data.data);
            setAllProjects(response.data.data);
            setFilteredProjects(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log('Error getting all Projects');
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [addProject, setAddProject] = useState(false);
    const handleAddProject = () => setAddProject(true);
    const hancleCloseAddProject = () => setAddProject(false);

    const [editProject, setEditProject] = useState(false);
    const handleEditProject = () => setEditProject(true);
    const hancleCloseEditProject = () => setEditProject(false);


    const [deleteProject, setDeleteProject] = useState(false);
    const handleDeleteProject = () => setDeleteProject(true);
    const handleCloseDeleteProject = () => setDeleteProject(false);


    function onClickAddProject() {
        hancleCloseAddProject();
        handleAddProject();
    }


    const [editProjectData, setEditProjectData] = useState({});

    function onClickEditProject(project_id) {
        console.log("THE PROJECT ID", project_id);
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'projects/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                project_id: project_id
            }
        }).then(response => {
            setEditProjectData(response.data.data[0]);
            hancleCloseEditProject();
            handleEditProject();
        }).catch(function (error) {
            console.log('Error getting all Project');
            console.log(error);
        });
    }


    const [deleteProjectData, setDeleteProjectData] = useState({});

    function onClickDeleteProject(project_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'projects/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                project_id: project_id
            }
        }).then(response => {
            setDeleteProjectData(response.data.data[0]);
            handleCloseDeleteProject();
            handleDeleteProject();
        }).catch(function (error) {
            console.log('Error getting all Projects');
            console.log(error);
        });
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allProjects) {
            allProjects.forEach(function(project) {
                if((project.name).toLowerCase().includes(term)) {
                    filteredArr.push(project);
                }
            })
        }

        if (term == "") {
            setFilteredProjects(allProjects);
        } else {
            setFilteredProjects(filteredArr);
        }
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-project'>
                            Project   
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-project" onClick={onClickAddProject}>
                                        <span className="export-text"><WorkIcon/> Add Project</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-project">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Project"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddProject 
                    showmodal1 = {addProject}
                    hidemodal1 = {hancleCloseAddProject}
                    showmodal1handler = {onClickAddProject}
                />

                <EditProject 
                    showmodal1 = {editProject}
                    hidemodal1 = {hancleCloseEditProject}
                    showmodal1handler = {onClickEditProject}
                    editProjectData = {editProjectData}
                />

                <DeleteProject  
                    showmodal1 = {deleteProject}
                    hidemodal1 = {handleCloseDeleteProject}
                    showmodal1handler = {onClickDeleteProject}
                    deleteProjectData = {deleteProjectData}
                />

                <div className="project-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='project'
                            tableHeaders={["Project Name", ""]}
                            tableData={filteredProjects}
                            deleteHandler={onClickDeleteProject}
                            editHandler={onClickEditProject}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}