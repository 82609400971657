import React, { useState } from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import ApprovePrompt from "../Modals/ApprovePrompt";
import AddCommunicationLetter from "./AddCommunicationLetters";
import EditCommunicationLetter from "./EditCommunicationLetters";

// icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { validateLetter } from "../../helpers/Validation/LetterValidation";

// css
import './WarningLetters.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"

export default function WarningLetters() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [letterId, setLetterId] = useState()

    // get data
    const [allWarning, setAllCommunicationLetters] = useState([]);
    const [filteredWarning, setFilteredCommunicationLetters] = useState([]);
    const [letterTypes, setLetterTypes] = useState([]);
    const [showApprove, setShowApprove] = useState(false);
    const [click, setClick] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [isError, setIsError] = useState({
        employee_id: false,
        type: false,
        subject: false,
        content: false,
        warning_date: false
    });

    // ADD WARNING LETTER HANDLER
    function onClickAddCommunicationLetter() {
        setShowAddModal(true);
    }

    // Get warning letter details for editing
    const [editCommunicationLetterData, setEditCommunicationLetterData] = useState({});
    function onClickEditCommunicationLetter(letter_id) {
        var axios = require('axios');
        var qs = require('qs');

        editCommunicationLetterData.length = 0;
        axios({
            method: 'get',
            url: window.$link + 'warnings/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                letter_id: letter_id
            }
        }).then(response => {
            setEditCommunicationLetterData(response.data.data.warning[0]);
            setShowEditModal(true);
        }).catch(function (error) {
        });
    }

     // UPDATE WARNING LETTER API
    function submitEditCommunicationLetter(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateLetter(editCommunicationLetterData, setIsError)===true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'warnings/update/'+editCommunicationLetterData.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: getUser(),
                token: userToken.replace(/['"]+/g, ''),
                subject: editCommunicationLetterData.subject,
                content: editCommunicationLetterData.content,
                warning_to: editCommunicationLetterData.warning_to,
                warning_from: editCommunicationLetterData.warning_from,
                type: editCommunicationLetterData.type,
                warning_date: editCommunicationLetterData.warning_date
            })
            })
            .then(function (response) {
                toast.success("Warning Letter Edited Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Update Warning Letter");
                setClick(false);
            });
        }
    }

    // Delete Warning Handler
    function onClickDeleteWarning(letter_id) {
        setLetterId(letter_id);
        setShowDeleteModal(true);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allWarning) {
            allWarning.forEach(function(warning) {
                if((warning.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(warning);
                }
            })
        }

        if (term == "") {
            setFilteredCommunicationLetters(allWarning);
        } else {
            setFilteredCommunicationLetters(filteredArr);
        }
    }

    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'letter/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                letter_id: letterId,
            })
            })
            .then(function (response) {
                toast.success("Letter Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Fail to Delete Letter")
                console.log(error);
                tokenExpired(error);
            });
    }

    function onClickApprove (letter_id) {
        setLetterId(letter_id);
        setShowApprove(true);
    }

    function handleApprove () {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'warnings/approve/'+letterId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                status: "approved"
            })
            })
            .then(function (response) {
                toast.success("Letter Approved Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error(error.response.data.messages.error);
                // tokenExpired(error);
            });
    }

    // GET ALL WARNING LETTERS
    React.useEffect(() => {
        allWarning.length = 0;

        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'letters/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                letter_id: ""
            }
        }).then(response => {
            console.log(response.data.data)
            setAllCommunicationLetters(response.data.data);
            setFilteredCommunicationLetters(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });

        // GET ALL LEAVE TYPES
        axios({
            method: 'post',
            url: window.$link + 'letter_types/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                letter_type_id: ''
            }
        }).then(response => {
            console.log(response.data.data);
            setLetterTypes(response.data.data);
        }).catch(function (error) {
        // ERROR
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-warning'>
                            Communication Letters   
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-warning py-2" onClick={onClickAddCommunicationLetter}>
                                        <span className="export-text"><AssignmentLateIcon/> Add Communication Letter</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-warning">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddCommunicationLetter 
                    show = {showAddModal}
                    hide = {() => {setShowAddModal(false); refreshPage()}}
                    letterTypes = {letterTypes}
                />

                <EditCommunicationLetter 
                    show = {showEditModal}
                    hide = {() => setShowEditModal(false)}
                    editCommunicationLetterData = {editCommunicationLetterData}
                    setEditCommunicationLetterData = {setEditCommunicationLetterData}
                    submitEditCommunicationLetter = {submitEditCommunicationLetter}
                    isError = {isError}
                    letterTypes = {letterTypes}
                />

                <DeletePrompt
                    name = "LETTER"
                    show = {showDeleteModal}
                    hide = {() => setShowDeleteModal(false)}
                    remover = {handleRemove}
                />

                <ApprovePrompt
                    name = "WARNING LETTER"
                    show = {showApprove}
                    hide = {() => setShowApprove(false)}
                    approve = {handleApprove}
                />

                <div className="warning-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (
                        <TableTemplate
                            tableType='warning'
                            tableHeaders={["SUBJECT", "TYPE", "EMPLOYEE", "DATE", "STATUS", ""]}
                            tableData={filteredWarning}
                            deleteHandler={onClickDeleteWarning}
                            editHandler={onClickEditCommunicationLetter}
                            approveHandler={onClickApprove}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}