import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

export default function DeleteProject(props) {    


    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        requester: getUser(),
        token: getToken(),
        name: ''
    });

    function submitDeleteProject(e) {
        var axios = require('axios');
        var qs = require('qs');
        if(click === false) {
            axios({
            url: window.$link + 'projects/delete/'+props.deleteProjectData.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, '')
            })
            })
            .then(function (response) {
                console.log(response);
                toast.success("Project Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                setClick(false);
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
        }
        setClick(true);
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll pt-4'>
                        <Container>
                            <Row>
                                <p className='text-center'><WarningRoundedIcon className='warning warning-icon'/></p>
                            </Row>
                            
                            <Row className='pt-3'>
                                <p className='text-center'>ARE YOU SURE YOU WANT TO DELETE THIS PROJECT?</p>
                            </Row>

                            <div className='d-flex justify-content-end me-2 mt-4'>
                                <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                                <button type="submit" className='delete-button' onClick={submitDeleteProject}>Delete</button>
                            </div>

                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}