import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import {toast} from 'react-toastify'
import '../Modals/modal.css'

import { validateBusinessUnit } from '../../helpers/Validation/Manage/BusinessUnitValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditBusinessUnit(props) {
    const userToken = getToken();
    const userId = getUser();
    const [editBusinessUnitData, setEditBusinessUnitData] = useState(props.editBusinessUnitData);

    const [isError, setIsError] = useState({
        name: false
    })
    const [click, setClick] = useState(false);

    function handle(e, element = null) {
        let editedBusinessUnitData = { ...props.editBusinessUnitData };
        editedBusinessUnitData[e.target.id] = e.target.value;
        setEditBusinessUnitData(editedBusinessUnitData);
    }

    function renderInputError(var_name) {
        let value = editBusinessUnitData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function submitEditBusinessUnit(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateBusinessUnit(editBusinessUnitData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'business_units/update/'+editBusinessUnitData.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: editBusinessUnitData.name
            })
            })
            .then(function (response) {
                console.log(response);
                toast.success("Business Unit Edited Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                console.log(editBusinessUnitData);
                toast.success("Business Unit not successfuly added!");
                console.log(error.response.data.messages);
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Business Unit
                    </div>
                    <button type="button" class="btn-close"   onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll m-2 p-0'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Business Unit Name <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            defaultValue={props.editBusinessUnitData?props.editBusinessUnitData.name:""}
                                            type="text"
                                            aria-label="name" 
                                            aria-describedby="name" 
                                            placeholder="Business Unit Name" 
                                            name="name" 
                                            id='name' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("name")}
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditBusinessUnit}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}