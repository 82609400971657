import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken, getUser, tokenExpired } from '../../utilities/common';
import moment from 'moment';
import {Row, Col} from 'react-bootstrap'

// components
import TableTemplate from '../../utilities/table_template';

// icons
import payrollLogo1 from '../../images/logo.png';

// css
import '../../utilities/common.css'
import './Payroll.css'

export default function PrintPayrollPage(props) {
    // dateFrom and DateTo receiver
    const location = useLocation();
    const dateFrom = location.state.from;
    const dateTo = location.state.to;

    let navigate = useNavigate();

    //get payroll details
    const [allPayrolls, setAllPayrolls] = useState([]);
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();
        allPayrolls.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'payrolls/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                payroll_id: ''
            })
        }).then(response => {
            // console.log(dateFrom, dateTo)
            const result = response.data.data;
            result.sort((a, b) => {return (a.employee_name < b.employee_name) ? -1 : (a.employee_name > b.employee_name) ? 1 : 0});
            result.map((emp) => {
                // console.log(emp.date_from, emp.date_to)
                if(emp.date_from == dateFrom && emp.date_to == dateTo) {
                    setAllPayrolls(oldArray => [...oldArray, emp]);
                }
            })
        }).catch(function (error) {
            tokenExpired(error);
        });
    },[]);

    return (
        // <div className="comm-bg">
            <div className="print-container">
                <Row className="p-1 m-0" style={{    
                    width: "100%",
                }}>
                    <Row className="pt-4">
                        <Col className='d-flex comm-header-employee'>
                            Payroll Preview    
                        </Col>
                        <Col xs={1} className='d-flex justify-content-end '>
                            <button className="export-button-employee" onClick={() => navigate(-1)}>
                                <span className="export-text">BACK</span>
                            </button>
                        </Col>
                        
                    </Row>
                    <Row className='comm-subheader'>
                        <p>{moment(dateFrom).format('MMM D, YYYY')} - {moment(dateTo).format('MMM D, YYYY')}</p>
                    </Row>
                </Row>

                <div className='printpayroll-wrapper'>
                    <TableTemplate
                        tableType='print_payroll'
                        tableHeaders={["ID", "Employee", "Hours", "Hourly Rate", "Overtime", "Overtime Rate", "Undertime", "Undertime Rate", "Absents", "Absent Rate", "SSS", "PhilHealth", "Pag-Ibig", "SSS Loan", "Philhealth Loan", "Coop Loan", "Coop Savings", "Cash Advance","Grand Total"]}
                        tableData={allPayrolls}
                    />
                </div>
            </div>
        // </div>
    );
}