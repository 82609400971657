import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllProjects, GetAllBusinessUnits } from "../../helpers/CommonApi";
import { toast } from 'react-toastify';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'

import InputError from '../../helpers/InputError/InpuError';

export default function EditCommunicationLetters(props) {   
    const userToken = getToken();
    const editData = props.editCommunicationLetterData;
    const letterTypes = props.letterTypes;
    const allBusinessUnits = GetAllBusinessUnits();
    const allProjects = GetAllProjects();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();
    const {quill, quillRef} = useQuill();

    function handle(e, element=null) {
        let newLetterData = { ...props.editCommunicationLetterData };
        if(e.target.id == "type" || e.target.id == "letter_to" ) {
            if(element != null) {
                let index = props.editCommunicationLetterData["letter_details"].map(t => t.id).indexOf(element.id);
                newLetterData["letter_details"][index][e.target.id] = e.target.value;
            }
        } else if (e.target.id == "type_prime" || e.target.id == "letter_to_prime") {
            if (element != null) {
                let index = props.editCommunicationLetterData["letter_details"].map(t => t.id).indexOf(element.id);
                newLetterData[e.target.id] = e.target.value;
                if (e.target.id == "type_prime") {
                    newLetterData["letter_details"][index].type = e.target.value;
                } else {
                    newLetterData["letter_details"][index].letter_to = e.target.value;
                }
            }
        } else {
            newLetterData[e.target.id] = e.target.value;
        }
        props.setEditCommunicationLetterData(newLetterData);
    }

    function renderInputError(var_name) {
        let value = props.editCommunicationLetterData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={props.isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addRecipient(e) {
        var recipients = editData.letter_details;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            letter_to:''
        });

         props.setEditCommunicationLetterData((prev) => {
            return {
                ...prev,
                letter_details: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = editData.letter_details;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type == '') {
            editData.type_prime = '';
            editData.letter_to_prime = '';
        }

         props.setEditCommunicationLetterData((prev) => {
            return {
                ...prev,
                letter_details: recipients
            }
        })
    }

    React.useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(editData.content?editData.content:'');
            quill.on('text-change', () => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                editData.content = temp_content;
            })
        }
    }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Communication Letter
                    </div>
                    {/* <button type="button" class="btn-close"  onClick={props.hide}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button> */}
                    <div className='body-head'>
                        <Container>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Date <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            defaultValue={editData.letter_date}
                                            type="date"
                                            aria-label="type" 
                                            aria-describedby="type" 
                                            placeholder="Type" 
                                            name="letter_date" 
                                            id='letter_date' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("letter_date")}
                                    </InputGroup>
                                </Col>
                                 <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="letter_from">From <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            defaultValue={editData.letter_from}
                                            type="text"
                                            aria-label="letter_from" 
                                            aria-describedby="letter_from" 
                                            placeholder="" 
                                            name="letter_from" 
                                            id='letter_from' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("letter_from")}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select 
                                            defaultValue={editData.letter_type_id}
                                            aria-label="letter_type_id"
                                            aria_describedby="letter_type_id"
                                            name="letter_type_id"
                                            id="letter_type_id"
                                            placeholder=''
                                            onChange={(e) => handle(e)}
                                        >
                                            <option value=''>Select Type</option>
                                            {
                                                letterTypes.map((data) => (
                                                    <option value={data.id}>{data.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        {renderInputError("letter_type_id")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="subject">Subject <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={editData.subject}
                                        type="text"
                                        aria-label="subject" 
                                        aria-describedby="subject" 
                                        placeholder="Subject" 
                                        name="subject" 
                                        id='subject' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError("subject")}
                                </InputGroup>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                <div ref={quillRef} />
                                <div style={{ width: 500, height: 20}}></div>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Recipient</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                        
                            {(props.editCommunicationLetterData["letter_details"] && props.editMemoData["letter_details"].length) ? props.editMemoData["letter_details"].map((element, i) => {
                                if (i===0) {
                                    return (
                                        <Row key={element.id}>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.type?element.type:""} placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                    <option value="branch">Branch</option>
                                                    <option value="business_unit">Business Unit</option>
                                                    <option value="department">Department</option>
                                                    <option value="employment_status">Employement Status</option>
                                                    <option value="position">Position</option>
                                                    <option value="project">Project</option>
                                                    </Form.Select>
                                                    {props.renderInputError("type_prime")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="memo_to">Memo To <span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.memo_to_id?element.memo_to_id:""} placeholder="Memo To" aria-describedby="memo_to" aria-label="memo_to" name="memo_to_id_prime" id='memo_to_id_prime' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                        {
                                                            (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):""))
                                                        }
                                                    </Form.Select>
                                                    {props.renderInputError("memo_to_id_prime")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                } else {
                                    return (
                                        <Row key={element.id}>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="type">Type </Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.type?element.type:""} placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                    <option value="branch">Branch</option>
                                                    <option value="business_unit">Business Unit</option>
                                                    <option value="department">Department</option>
                                                    <option value="employment_status">Employement Status</option>
                                                    <option value="position">Position</option>
                                                    <option value="project">Project</option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="memo_to">Memo To</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.memo_to_id?element.memo_to_id:""} placeholder="Memo To" aria-describedby="memo_to" aria-label="memo_to" name="memo_to_id" id='memo_to_id' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                        {
                                                            (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):""))
                                                        }
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                }
                                }):<p></p>}                
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={props.submitEditCommunicationLetter}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}