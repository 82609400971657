import React, { useState, useRef } from 'react';
import {Row, Col} from 'react-bootstrap'
import { getToken, getUser} from '../../utilities/common';
import { useLocation, useNavigate} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import moment from "moment";
import Navbar from '../Navbar/Navbar'
import PrintIcon from '@mui/icons-material/Print';
import NewLogo from '../../images/NewLogo.png'


export default function ViewWarningLetters(props) {    

    const userToken = getToken();
    const userId = getUser();
    const location = useLocation();
    const componentRef = useRef();
    const navigate = useNavigate();
    const [warningId, setWarningDetailsId] = useState(location.state);
    const [warningDetails, setWarningDetails] = useState({});
    const currentYear = new Date().getFullYear();

    // Get warning details by ID
    React.useEffect(() => {
        warningDetails.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'warnings/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                warning_id: warningId
            }
        }).then(response => {
            setWarningDetails(response.data.data.warning[0]);
        }).catch(function (error) {
            console.log(error);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className='comm-page-container'>
                <div className='print-page-container me-3' ref={componentRef}>
                {/* <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className='payslip-header d-flex justify-content-end'>WARNING LETTER</Row>
                        <Row className='d-flex justify-content-end'>DATE: {(function() {
                                return moment(warningDetails.warning_date).format('MMMM DD, YYYY');
                            })()}
                    </Row>

                    <Row className='col-6 p-1 mt-3'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr className='payslip-blue-bg'>
                                    <th colSpan={2}>Warning Letter No. {warningDetails.id ? warningDetails.id : ''}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bold'>Subject</td>
                                    <td>{warningDetails.subject ? warningDetails.subject : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>From</td>
                                    <td>{warningDetails.warning_from ? warningDetails.warning_from : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>To</td>
                                    <td>{warningDetails.employee_name ? warningDetails.employee_name : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='p-1 mt-3'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <div dangerouslySetInnerHTML={{__html: warningDetails.content ? warningDetails.content : ''}} />
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='d-flex justify-content-end me-3 mt-4'>
                        <Col className='d-flex justify-content-end'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="print-button me-0 noprint me-1">
                                        <span className="export-text"><PrintIcon/> Print</span>
                                    </button>
                                )}
                                content={() => componentRef.current}
                                documentTitle={"Memorandum"}
                            />
                            <button className="save-button blue noprint" onClick={()=> navigate(-1)}>
                                <span className="export-text">Back</span>
                            </button>
                        </Col>
                    </Row>
                    
                </Row> */}

                <Row
            className="p-1"
            style={{
              width: "100%",
            }}
          >
            <div className="topCol me-2">
              <div>
                <img
                  src={NewLogo}
                  alt={"logo"}
                  className="payslip-logo ms-0"
                ></img>
              </div>
            </div>

            <div className="toprightCol">
              <div>
                <ul className="payslip-header d-flex justify-content">
                  MYT SoftDev Solutions Inc.
                </ul>
                <ul className="headeraddress">
                  301 The Greenery, Pope John Paul II Ave, Kasambagan, Cebu City, 6000 Cebu
                </ul>
              </div>
            </div>
            <div className="headerline"></div>
            <div className="warningheader bold d-flex justify-content-center mb-5">
              WARNING LETTER NO. {currentYear}-00{warningDetails.id ? warningDetails.id : ""}
            </div>

            <Row className="bold">
              <Col xs={2}>DATE</Col>
              <Col xs={1}>:</Col>
              <Col >{(function () {
                    return moment(warningDetails.warning_date).format(
                      "MMMM DD, YYYY"
                    );
                  })()}
                </Col>
            </Row>
            <Row className="bold mb-3 wrap-text">
              <Col xs={2}>SUBJECT</Col>
              <Col xs={1}>:</Col>
              <Col xs={9}>{warningDetails.subject ? warningDetails.subject : ""}</Col>
            </Row>
            <Row className="bold mb-3">
              <Col xs={2}>TYPE</Col>
              <Col xs={1}>:</Col>
              <Col >{warningDetails.type ? warningDetails.type : ""}</Col>
            </Row>
            <Row className="bold">
              <Col xs={2}>TO</Col>
              <Col xs={1}>:</Col>
              <Col>{warningDetails.employee_name? warningDetails.employee_name: ""}</Col>
            </Row>
            <Row className="bold wrap-text">
              <Col xs={2}>FROM</Col>
              <Col xs={1}>:</Col>
              <Col xs={9}>{warningDetails.warning_from? warningDetails.warning_from: ""}</Col>
            </Row>

            <div className="mt-4 headerline"></div>

            {/* <Row className="p-1 mt-3">
              <table className="table table-bordered">
                <tbody className="">
                  <div dangerouslySetInnerHTML={{ __html: warningDetails.content?warningDetails.content:'' }} />
                </tbody>
              </table>
            </Row> */}

             <Row className="updatedon">
                <div dangerouslySetInnerHTML={{__html: warningDetails.content?warningDetails.content:''}}/>
            </Row>

            <Row className="d-flex justify-content-end me-3 mt-4">
              <Col className=" print d-flex justify-content-end">
                <ReactToPrint
                  trigger={() => (
                    <button className="print-button me-0 noprint me-1">
                      <span className="export-text">
                        <PrintIcon /> Print
                      </span>
                    </button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={"Memorandum"}
                />
                <button
                  className="save-button blue noprint"
                  onClick={() => navigate(-1)}
                >
                  <span className="export-text">Back</span>
                </button>
              </Col>
            </Row>
          </Row>
                </div>
            </div>
        </div>
    );
}