import React, { useEffect } from "react";
import { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import {Row, Col, Container} from 'react-bootstrap'

import "./TableFooter.css";

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const TableFooter = ({
  range,
  setPage,
  page,
  slice,
  footerClass,
  setRowsPerPage,
  rowsPerPage,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);

  function prev() {
    var end = endIndex;
    var start = startIndex;
    if (range.length <= 5) {
      if (page > 1) {
        setPage(page-1)
      }
    } else {
      if (startIndex > 0) {
        setEndIndex(end - 5);
        setStartIndex(start - 5);
      }
    }
  }

  function next() {
    var end = endIndex;
    var start = startIndex;
    if (range.length <= 5) {
      setPage(page+1)
    } else {
      if (endIndex <= range.length - 1) {
        setEndIndex(end + 5);
        setStartIndex(start + 5);
        setPage(page+1)
      }
    }
  }

  function start() {
    if (range.length !== 0) {
      setPage(range[0]);
      setEndIndex(5);
      setStartIndex(0);
    }
  }

  function end() {
    if (range.length !== 0) {
      setPage(range.length);
      setEndIndex(range.length);
      setStartIndex(range.length - 5);
    }
  }

  function setPageNav(el) {
    var division = Math.floor(el / 5);
    if (Math.floor(el % 5) !== 0) {
      division += 1;
    }
    setEndIndex(division * 5);
    setStartIndex(division * 5 - 5);
    setPage(parseFloat(el));
  }

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  return (
    <Container className='m-0 p-0 noprint'>
    <div className={"tableFooter " + footerClass}>
      
      <Col className="page-count-cont">
        <span>DISPLAY {page} OUT OF {range.length} </span>
      </Col>

      <Col className=" pages-cont d-flex justify-content-center">
        <button className="button navigateButton" onClick={() => start()}>
          <KeyboardDoubleArrowLeftIcon/>
        </button>
            <button className="button navigateButton" onClick={prev}>
              <ChevronLeftIcon/>
            </button> 
        {range.slice(startIndex, endIndex).map((el, index) => (
          <button
            key={index}
            className={`${"button"} ${
              page === el ? "activeButton" : "inactiveButton"
            }`}
            onClick={() => setPage(el)}
          >
            {el}
          </button>
          
        ))}
          <button className="button navigateButton" onClick={next}>
            <ChevronRightIcon/>
          </button>

          <button className="button navigateButton" onClick={() => end()}>
            <KeyboardDoubleArrowRightIcon/>
          </button>
      </Col>

      <Col className="page-show-cont">
          <Row>
            <Col className=""><span>SHOW</span></Col>
            <Col className="">
              <select
                className="rows-input"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={500}>500</option>
              </select>
            </Col>
          </Row>
      </Col>
     
    </div>
    </Container>
  );
};

export default TableFooter;
