import React, { useState, useRef } from "react";
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import {Row, Col, Container, Form} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import Navbar from "../Navbar/Navbar"
import DeletePrompt from "../Modals/DeletePrompt";
import UploadImg from '../../images/UploadIcon.png'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal, InputGroup } from "react-bootstrap";
import './CompanyFile.css'

export default function AddCompanyFile (props) {
    const userToken = getToken();
    const userId = getUser();
    const inputRef = useRef();
    const navigate = useNavigate();
    const [fileId, setFileId] = useState(null);
    const [click, setClick] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showEditModal, setShowEditModal]=useState(false);

    const [editData, setEditData] = useState();
    const [allCompanyFolders, setAllCompanyFolders] = useState([]);
    const [fileData, setFileData] = useState({
        name: '',
        description: '',
        no_of_company_files: '',
        company_files: [],
        raw_files: []
    });


    function handleClick (e) {
    // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    const handleFiles = (e, file_index=null) => {
        e.preventDefault();
        if (e.target.id === "file") {
            var files_temp = e.target.files;
            // console.log(files_temp)
            Array.from(files_temp).forEach(file => {
                fileData["raw_files"].push(file);
                setFileData((prev) => {
                    return {
                        ...prev,
                    raw_files: files_temp
                    }
                });
                var info = {};
                info.file_name = '';
                getBase64(file).then(base64 => {
                    info.file_attachment = base64;
                });
                fileData["company_files"].push(info);
            });
        } else if (e.target.id === "file_name") {
            fileData["company_files"][file_index].file_name = e.target.value;
        }
    }

    function handle (e) {
        let newData = {...fileData};
        newData[e.target.id] = e.target.value;
        setFileData(newData);
    }


    const handleOnChange = (e) => {
        e.preventDefault();
        // setFiles(e.target.files);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const fileReceived = e.dataTransfer.files;
        // console.log(fileReceived);
        // setFiles(fileReceived);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };    

    const deleteFolder = (e, folder_id) => {
        setFileId(folder_id);
        setShowDelete(true);
    }

    function removeFile (e, index) {
        var currentCompanyFiles = fileData.company_files;
        var currentRawFiles = fileData.raw_files;
        for (let i=0; i<currentCompanyFiles.length; i++) {
            if (i === index) {
                currentCompanyFiles.splice(i, 1);
                currentRawFiles.splice(i, 1);
                break;
            }
        }

        setFileData((prev) => {
            return {
                ...prev,
             company_files: currentCompanyFiles,
             raw_files: currentRawFiles
            }
        });
    }

    // API TO ADD FOLDER
    function handleSubmit () {
        var axios = require('axios');
        var qs = require('qs');

        axios({
            url: window.$link + 'companies/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: fileData.name,
                description: fileData.description,
                no_of_company_files: fileData.company_files.length,
                company_files: fileData.company_files
            })
        }).then(function (response) {
            toast.success("Folder Added Successfully!");
            setTimeout(() => refreshPage(), 1000);
        }).catch (function (error) {
            toast.error("Failed to Add Folder")
        });
    };

    return (
        <div className='account-details'>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Folder
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hide}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll p-0 mt-2'>
                       <Row className="my-1 mt-3 primary-color p-4">
                            <Row className=''>
                                <Col xs={2}>
                                    <Form.Label className="h6" htmlFor="name">Save Folder As</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="Folder name"
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e)}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={2}>
                                    <Form.Label className="h6" htmlFor="name">Description</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        as="textarea"
                                        aria-label="description" 
                                        aria-describedby="description" 
                                        placeholder="Description"
                                        name="description" 
                                        id='description' 
                                        onChange={(e) => handle(e)}
                                    />
                                </Col>
                            </Row>
                            <Container className="browse-file-container pointer p-2 mt-2" onClick={(e) => handleClick(e)} onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)}>
                                <Row className="justify-content-center">
                                    <img src={UploadImg} className="upload-icon"/>
                                </Row>
                                <Row className="mt-3 justify-content-center text-color bold">
                                    Browse Files
                                </Row>
                                <Row className="justify-content-center text-color mt-2">
                                    Select a file or drag here
                                </Row>
                                <Row className="input-file-btn-container mt-3">
                                    <input
                                        id = "file"
                                        name = "file"
                                        style={{ display: "none" }}
                                        type={"file"}
                                        onChange={(e) => handleFiles(e, '')}
                                        ref={inputRef}
                                        className="input-file-btn"
                                        multiple
                                    />
                                </Row>
                            </Container>
                            {
                                fileData["raw_files"].map((file, index) => {
                                    if (file) {
                                        return (
                                            <Row>
                                                <Col xs={7}>
                                                <Row className="question-bg text-color mt-2 ms-0 p-0">
                                                    <div class="input-group p-0">
                                                    <div class="input-group-prepend me-3 p-0">
                                                        <span class="input-group-text"><FolderOpenIcon/></span>
                                                    </div>
                                                        <span className="mt-1 smaller-font">{file?file.name:''}</span>
                                                    </div>
                                                </Row>
                                                </Col>
                                                <Col xs={4} className='mt-2'>
                                                    <Form.Control
                                                        type="text"
                                                        aria-label="file_name" 
                                                        aria-describedby="file_name" 
                                                        placeholder="Filename"
                                                        name="file_name" 
                                                        id='file_name' 
                                                        onChange={(e) => handleFiles(e, index)}
                                                    />
                                                </Col>
                                                <Col xs={1}>
                                                    <button id="removeRow" className="trash-icon mt-1" onClick={(e) => removeFile(e, index)}><DeleteOutlineIcon/></button>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                })
                            }
                       </Row>
                       
                        <div className='d-flex justify-content-end me-2 mt-2 me-5 me-5 me-3 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                            <button type="submit" className='save-button' onClick={(e) => handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}