import React, { useState } from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar";
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import "../../utilities/loader_1.css"
import AddRequirement from "./AddRequirement";
import EditRequirement from "./EditRequirement";

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from "@mui/icons-material/Search";


export default function Requirements() {
    const userToken = getToken();
    const userId = getUser();
    const [loading, setLoading] = useState(true);

    // get all EmploymentStatus
    const [allRequirements, setAllRequirements] = useState([]);
    const [filteredRequirements, setFilteredRequirements] = useState([]);
    const [requirementId, setRequirementId] = useState()
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function onClickaddRequirement() {
        setShowAddModal(true);
    }

    const [editRequirementData, setEditRequirementData] = useState({});

    function onClickEditRequirement(requirement_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'requirements/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                requirement_id: requirement_id
            }
        }).then(response => {
            setEditRequirementData(response.data.data[0]);
            setShowEditModal(true);
        }).catch(function (error) {
            console.log(error);
        });
    }

    function onClickdeleteRequirement(employee_status_id) {
        setRequirementId(employee_status_id);
        setShowDeleteModal(true);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allRequirements) {
            allRequirements.forEach(function(employment_statuses) {
                if((employment_statuses.name).toLowerCase().includes(term)) {
                    filteredArr.push(employment_statuses);
                }
            })
        }

        if (term == "") {
            setFilteredRequirements(allRequirements);
        } else {
            setFilteredRequirements(filteredArr);
        }
    }

    // Delete Requirement API
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'requirements/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                requirement_id: requirementId
            })
            })
            .then(function (response) {
                toast.success("Requirement Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Delete Requirement")
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
    }

    
    // Get all requirements
    React.useEffect(() => {
        allRequirements.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'requirements/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
            setAllRequirements(response.data.data);
            setFilteredRequirements(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-status'>
                            Requirements 
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-status p-2" onClick={onClickaddRequirement}>
                                        <span className="export-text"><AccountCircleIcon/> Add Requirement</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-status">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Requirement"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <DeletePrompt
                    name = "REQUIREMENT"
                    show = {showDeleteModal}
                    hide = {() => setShowDeleteModal(false)}
                    remover = {handleRemove}
                />

                <AddRequirement
                    showmodal1 = {showAddModal}
                    hidemodal1 = {() => setShowAddModal(false)}
                />

                <EditRequirement
                    showmodal1 = {showEditModal}
                    hidemodal1 = {() => setShowEditModal(false)}
                    editRequirementData = {editRequirementData}
                />

                <div className="employment-status-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='requirements'
                            tableHeaders={["Name", ""]}
                            tableData={filteredRequirements}
                            deleteHandler={onClickdeleteRequirement}
                            editHandler={onClickEditRequirement}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle EmploymentStatus-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}