import { useState } from "react";
import {Modal, Row, Col, Form, Container, InputGroup } from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import '../../utilities/common.css'

import { validateShift } from "../../helpers/Validation/Manage/ShiftValidation";
import InputError from "../../helpers/InputError/InpuError";

export default function AddShift(props) {
    // add/edit shift API
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);

    const [data, setData] = useState({
        name: '',
        time_in: '',
        time_out: '',
        overtime_fee: '',
        break_duration: ''
    });

    // Validation
    const [isError, setIsError] = useState({
        name: '',
        time_in: '',
        time_out: '',
        overtime_fee: '',
        break_duration: ''
    });
    
    // add shift axios
    function submitAddShift(e) {
        e.preventDefault();
        if(validateShift(data, setIsError)==true && click===false) {
            setClick(true);
            var axios = require('axios');
            var qs = require('qs');
            axios({
            url: window.$link + 'shifts/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: data.name,
                time_in: data.time_in,
                time_out: data.time_out,
                overtime_fee: data.overtime_fee,
                break_duration: data.break_duration
            })
            })
            .then(function (response) {
                // console.log(response);
                toast.success("Shift Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error('Failed to Add Shift');
                setClick(false);
                tokenExpired(error);
            });
        }
    }

    function handle(e) {
        const newData = { ...data };
        setData(newData);
        newData[e.target.id] = e.target.value;
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        // console.log(value)
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return(
        <div>
            {/* Add/Edit Shift Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                <Form>
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Add Shift
                        </div>
                        <button type="button" class="btn-close"  onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Name<span className='red'> *</span></Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                type="text" 
                                                name="name"
                                                id="name"
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {/* <InputError
                                            isValid={isError.name}
                                            message={"This field is required."}
                                        /> */}
                                        {renderInputError('name')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Time In<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="time" 
                                                name="time_in" 
                                                id="time_in" 
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {/* <InputError
                                            isValid={isError.time_in}
                                            message={"This field is required."}
                                        /> */}
                                        {renderInputError('time_in')}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Time Out<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="time" 
                                                name="time_out" 
                                                id="time_out" 
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {/* <InputError
                                            isValid={isError.time_out}
                                            message={"This field is required."}
                                        /> */}
                                        {renderInputError('time_out')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Overtime Rate (%)<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number" 
                                                name="overtime_fee"
                                                id="overtime_fee" 
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {/* <InputError
                                            isValid={isError.overtime_fee}
                                            message={"This field is required."}
                                        /> */}
                                        {renderInputError('overtime_fee')}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Break Duration (minutes)<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number" 
                                                name="break_duration" 
                                                id="break_duration" 
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {/* <InputError
                                            isValid={isError.break_duration}
                                            message={"This field is required."}
                                        /> */}
                                        {renderInputError('break_duration')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                            <button type="submit" className='save-button' onClick={(e) => submitAddShift(e)}>Save</button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    );
}