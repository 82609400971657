import React, { useState, useRef } from "react";
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import {Row, Col, Container, Form} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import Navbar from "../Navbar/Navbar"
import DeletePrompt from "../Modals/DeletePrompt";
import UploadImg from '../../images/UploadIcon.png'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal, InputGroup } from "react-bootstrap";
import './CompanyFile.css'
import AddCompanyFile from "./AddCompanyFile";
import TableTemplate from "../../utilities/table_template";

export default function CompanyFiles () {
    const userToken = getToken();
    const userId = getUser();
    const inputRef = useRef();
    const navigate = useNavigate();
    const [fileId, setFileId] = useState(null);
    const [click, setClick] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showEditModal, setShowEditModal]=useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [editData, setEditData] = useState();
    const [allCompanyFolders, setAllCompanyFolders] = useState([]);
    const [fileData, setFileData] = useState({
        name: '',
        description: '',
        no_of_company_files: '',
        company_files: [],
        raw_files: []
    });


    function handleClick (e) {
    // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    const handleFiles = (e, file_index=null) => {
        e.preventDefault();
        var newFileData = editData.company_files;
        if (e.target.id === "file") {
            var files_temp = e.target.files;
            Array.from(files_temp).forEach(file => {
                var info = {};
                info.file_name = file.name;
                getBase64(file).then(base64 => {
                    info.file_attachment = base64;
                });
                newFileData.push(info);
            });
        } else if (e.target.id === "file_name") {
            editData[file_index].file_name = e.target.value;
        }
        
        setEditData((prev) => {
            return {
                ...prev,
                company_files: newFileData,
                raw_files: newFileData
            }
        });
    }

    function handle (e) {
        let newData = {...editData};
        newData[e.target.id] = e.target.value;
        setEditData(newData);
    }


    const handleOnChange = (e) => {
        e.preventDefault();
        // setFiles(e.target.files);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const fileReceived = e.dataTransfer.files;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };    

    const deleteFolder = (folder_id) => {
        setFileId(folder_id);
        setShowDelete(true);
    }

    // Get the folder details including the files under it
    function editFolder (folder_id) {

        var axios = require('axios');
        var qs = require('qs');
        axios({
            url: window.$link + 'companies/get',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                company_id: folder_id
            })
        }).then(function (response) {
            const res = response.data.data.company[0];
            var info = {};
            info.id = res.id;
            info.name = res.name;
            info.description = res.description;
            info.company_files = res.file_attachments;
            info.raw_files = res.file_attachments;
            setEditData(info);
            setShowEditModal(true);
        }).catch (function (error) {
            console.log(error);
        });
    }

    function removeFile (e, index) {
        var currentCompanyFiles = editData.company_files;
        var currentRawFiles = editData.raw_files;
        for (let i=0; i<currentCompanyFiles.length; i++) {
            if (i === index) {
                currentCompanyFiles.splice(i, 1);
                currentRawFiles.splice(i, 1);
                break;
            }
        }

        setEditData((prev) => {
            return {
                ...prev,
             company_files: currentCompanyFiles,
             raw_files: currentRawFiles
            }
        });
    }

    function onClickAddFolder () {
        setShowAddModal(true);
    }

    // API TO DELETE FOLDER
    function handleRemove () {
        var axios = require('axios');
        var qs = require('qs');

        axios({
            url: window.$link + 'companies/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                company_id: fileId
            })
        }).then(function (response) {
            toast.success("Folder Deleted Successfully!");
            setTimeout(() => refreshPage(), 1000);
        }).catch (function (error) {
            toast.error("Failed to Delete Folder")
        });
    }

    // API TO UPDATE FOLDER
    function submitEditedFolder () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            url: window.$link + 'companies/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                company_id: editData.id,
                name: editData.name,
                description: editData.description,
                no_of_company_files: editData.company_files.length,
                company_files: editData.company_files
            })
        }).then(function (response) {
            toast.success("Folder Edited Successfully!");
            setTimeout(() => refreshPage(), 1000);
        }).catch (function (error) {
            toast.error("Failed to Edit Folder")
        });
    };

    // Get all company folders & files
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            url: window.$link + 'companies/get',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            })
        }).then(function (response) {
            setAllCompanyFolders(response.data.data.company);
            setLoading(false);
        }).catch (function (error) {
            console.log(error);
        });
    }, []);


    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1  limit-container-height" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-employee'>
                            Company Files
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-branch" onClick={onClickAddFolder}>
                                        <span className="export-text">Add Folder</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>

                    <div className="company-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (<TableTemplate
                                tableType='company_files'
                                tableHeaders={["Folder Name", "Number of Files", ""]}
                                tableData={allCompanyFolders}
                                deleteHandler={deleteFolder}
                                editHandler={editFolder}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                    </div>
                </Row>

                <DeletePrompt
                    name = "FILE"
                    show = {showDelete}
                    hide = {() => setShowDelete(false)}
                    remover = {handleRemove}
                />

                <AddCompanyFile
                    show = {showAddModal}
                    hide = {() => setShowAddModal(false)}
                />
            </div>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                {/* <Form> */}
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Edit Company Files
                        </div>
                        <button type="button" class="btn-close"  onClick={() => setShowEditModal(false)}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <div className='body-head px-4'>
                                <Row className="my-1">
                                        <Row className=''>
                                            <Col xs={2}>
                                                <Form.Label className="h6" htmlFor="name">Save Folder As</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    aria-label="name" 
                                                    aria-describedby="name" 
                                                    placeholder="Folder name"
                                                    name="name" 
                                                    id='name' 
                                                    value={editData?editData.name:''}
                                                    onChange={(e) => handle(e)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col xs={2}>
                                                <Form.Label className="h6" htmlFor="name">Description</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    as="textarea"
                                                    aria-label="description" 
                                                    aria-describedby="description" 
                                                    placeholder="Description"
                                                    name="description" 
                                                    id='description' 
                                                    value={editData?editData.description:''}
                                                    onChange={(e) => handle(e)}
                                                />
                                            </Col>
                                        </Row>
                                        <Container className="browse-file-container pointer p-2 mt-2" onClick={(e) => handleClick(e)} onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)}>
                                            <Row className="justify-content-center">
                                                <img src={UploadImg} className="upload-icon"/>
                                            </Row>
                                            <Row className="mt-3 justify-content-center text-color bold">
                                                Browse Files
                                            </Row>
                                            <Row className="justify-content-center text-color mt-2">
                                                Select a file or drag here
                                            </Row>
                                            <Row className="input-file-btn-container mt-3">
                                                <input
                                                    id = "file"
                                                    name = "file"
                                                    style={{ display: "none" }}
                                                    type={"file"}
                                                    onChange={(e) => handleFiles(e, '')}
                                                    ref={inputRef}
                                                    className="input-file-btn"
                                                    multiple
                                                />
                                            </Row>
                                        </Container>
                                        {
                                            editData ? (
                                                editData["raw_files"].map((file, index) => {
                                                if (file) {
                                                    return (
                                                        <Row>
                                                            <Col xs={7}>
                                                            <Row className="question-bg text-color mt-2 ms-0 p-0">
                                                                <div class="input-group p-0">
                                                                <div class="input-group-prepend me-3 p-0">
                                                                    <span class="input-group-text"><FolderOpenIcon/></span>
                                                                </div>
                                                                    <span className="mt-1 smaller-font">{file.file_name?file.file_name:'Unnamed File'}</span>
                                                                </div>
                                                            </Row>
                                                            </Col>
                                                            <Col xs={4} className='mt-2'>
                                                                <Form.Control
                                                                    type="text"
                                                                    aria-label="file_name" 
                                                                    aria-describedby="file_name" 
                                                                    placeholder="Filename"
                                                                    name="file_name" 
                                                                    id='file_name' 
                                                                    defaultValue={file.file_name}
                                                                    onChange={(e) => handleFiles(e, index)}
                                                                />
                                                            </Col>
                                                            <Col xs={1}>
                                                                <button id="removeRow" className="trash-icon mt-1" onClick={(e) => removeFile(e, index)}><DeleteOutlineIcon/></button>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            })
                                            ) : (<p></p>)
                                        }
                                </Row>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={() => setShowEditModal(false)}>Cancel</button>
                            <button type="submit" className='save-button' onClick={submitEditedFolder}>Save</button>
                        </div>
                    </Modal.Body>
                {/* </Form> */}
            </Modal>
        </div>
    );
}