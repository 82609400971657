import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Container} from 'react-bootstrap';
import { getToken, getUser} from '../../utilities/common';
import { Chart } from "react-google-charts";
import Navbar from "../Navbar/Navbar"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import './Forms.css'
import '../../utilities/common.css'

export default function ViewForms() {
    const navigate = useNavigate();
    const location = useLocation();
    const form_id = location.state;
    const [questionSet, setQuestionSet] = useState([]);
    const [formDetails, setFormDetails] = useState({});

    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');

        // Get the form details
        axios ({
            url: window.$link + 'forms/get',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: getUser(),
                token: getToken().replace(/['"]+/g, ''),
                id: form_id
            })
        }).then (function (response) {
            setFormDetails(response.data.data[0]);
        }).catch(function (error) {
            console.log(error);
        })


        // Get the result of the survey
        axios ({
            url: window.$link + 'forms/results/'+form_id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: getUser(),
                token: getToken().replace(/['"]+/g, ''),
            })
        }).then (function (response) {
            const res = response.data.data;
            var result = res.map((data) => {
                var info = {}
                info.type = data.type;
                info.question = data.question;

                if (data.type === "multiple_choice") {
                    var with_header = [];
                    var initial = ['Response', 'frequency'];
                    with_header.push(initial);
                    data.responses.map((res) => {
                       var info = [];
                       info.push(res.response, parseInt(res.frequency))
                    //    return info;
                        with_header.push(info);
                    })
                    info.response = with_header;
                } else {
                    var final_responses = data.responses.map((res) => {
                       var response = res.response;
                       return response;
                    })
                    info.response = final_responses;
                }

                return info;
            });
            setQuestionSet(result);
        }).catch(function (error) {
            console.log(error)
        })
    }, []);


    return (
        <div className="comm-bg">
            <Navbar />
            <div className="comm-page-container">

                <div className="ebr-container-wrapper me-3 py-4">
                    <Container className='pt-2'>
                        <Row className='comm-header-branch px-3'>
                            {formDetails.name?formDetails.name:''}  
                        </Row>
                        <Row className='px-4 py-1 mt-2'>
                            {formDetails.description?formDetails.description:''}  
                        </Row>
                        <Row className='px-4 py-1 bold red'>
                            {questionSet.length===1 ? questionSet.length+' response':questionSet.length+' responses'}
                            
                        </Row>
                    </Container>
                </div>
                
                {/* <div className="ebr-container-wrapper me-3 mt-3"> */}
                <div className="create-form-container me-3 mt-3 p-3">
                    { questionSet.map((data) => {
                        if (data.type === "multiple_choice") { return (
                            <Container>
                                <Chart
                                    className='pink-bg'
                                    chartType="PieChart"
                                    data={data.response}
                                    options={{
                                        title: data.question,
                                        is3D: true,
                                    }}
                                    width={"100%"}
                                />
                                <hr/>
                            </Container>
                        )} else {
                           return (
                             <Container className=''>
                                <Row className='view-question'>{data.question}</Row>
                                <Row className='view-answer question-bg mt-1 p-2'>
                                    {
                                        data.response.map((answer) => {
                                            return (
                                                <span className='p-1'><CheckBoxOutlineBlankIcon className='primary-color'/> {answer}</span>
                                            )
                                        })
                                    }
                                </Row>
                                <hr/>
                            </Container>
                           )
                        }
                    })}
                </div>
                <div className='d-flex justify-content-end me-3 mt-4 mb-3'>
                    <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>
        </div>
    );
}