import React from 'react'
import { useRef, useState } from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

// Components
import Navbar from '../Navbar/Navbar'
import { getToken, getUser, tokenExpired } from '../../utilities/common';

// Image
import NewLogo from "../../images/NewLogo.png";
import PrintIcon from '@mui/icons-material/Print';

function EmployeePayslip () {

    const date_today = new Date();
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [payrollDetails, setPayrollDetails] = useState({});
    const payrollId = location.state;

    const [allEarningsValues, setAllEarningsValues] = useState([]);
    const [allDeductionValues, setAllDeductionValues] = useState([]);
    const allEarningsLabels = ["Basic Pay","Holiday Pay","Night Differential","Overtime","Regular OT","Regular Pay","Restday OT","Monthly","Semi-monthly","Salary Adjustment","Special Holiday Pay","Communication Allowance", "Daily Allowance","Food Allowance","HMO Allowance","OPS Allowance","Special Allowance","Tech Allowance","Transpo Allowance","Training Allowance (F2F)","Training Allowance (Virtual)","Grocery Allowance","Entertainment Allowance","Medicine Allowance","Uniform Allowance", "Thirteenth Month"];
    const allDeductionLabels = ["Absent","PhilHealth Contribution","PhilHealth Loans","PAGIBIG Contribution","PAGIBIG Loans","SSS Contribution","SSS Loans","SSS Calamity Loans","SSS Salary Loans","Calamity Loans","Company Loans", "Cooperative Loans", "Cooperative Savings","HMO Maxicare","Late","AXA","Phone Loan", "Laptop Loan","Cash Advance","Undertime","Withholding Tax", "Coop", "Others", "Intellicare Amount"];
    const [totalEarnings, setTotalEarnings] = useState(null);
    const [totalDeductions, setTotalDeductions] = useState(null);
    const [netPay, setNetPay] = useState(null);

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    // GET PAYROLL BY ID
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'payrolls/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                payroll_id: payrollId
            })
        }).then(response => {
            const payroll = response.data.data[0];
            console.log(payroll);
            var earningValues = [];
            var deductionValues = [];
            var info = payroll;
            info.id = payroll.id;
            var salary = Number (payroll.basic_pay);
            info.salary = salary;
            info.date_from = payroll.date_from;
            info.date_to = payroll.date_to;
            earningValues.push(payroll.basic_pay,payroll.holiday_pay,payroll.night_differential,payroll.overtime,payroll.regular_ot,payroll.regular_pay,payroll.restday_ot,payroll.monthly,payroll.semi_monthly,payroll.salary_adj,payroll.special_holiday_pay,payroll.comm_allowance, payroll.daily_allowance,payroll.food_allowance,payroll.hmo_allowance,payroll.ops_allowance,payroll.special_allowance,payroll.tech_allowance,payroll.transpo_allowance,payroll.training_allowance_f2f,payroll.training_allowance_virtual,payroll.grocery_allowance,payroll.entertainment_allowance,payroll.medicine_allowance,payroll.uniform_allowance,payroll.thirteenth_month);
            deductionValues.push(payroll.absent,payroll.philhealth_contribution,payroll.philhealth_loans,payroll.pagibig_contribution,payroll.pagibig_loan,payroll.sss_contribution,payroll.sss_loans,payroll.sss_calamity_loan,payroll.sss_salary_loan,payroll.calamity_loan,payroll.company_loan,payroll.cooperative_loans,payroll.cooperative_savings,payroll.hmo_maxicare,payroll.late,payroll.axa_amount,payroll.phone_loan,payroll.laptop_loan,payroll.cash_advance,payroll.undertime,payroll.wth_tax,payroll.coop,payroll.others,payroll.intellicare_amount);
            setPayrollDetails(info);
            setAllEarningsValues(earningValues);
            setAllDeductionValues(deductionValues);
            var total_earning = 0;
            earningValues.forEach(item => {
                total_earning += Number(item);
            })
            setTotalEarnings(total_earning);
            var total_deduction = 0;
            deductionValues.forEach(item => {
                if (item) {
                    total_deduction += Number(item)
                }
            })
            setTotalDeductions(total_deduction);
            var net_pay = Number(total_earning)-Number(total_deduction);
            setNetPay(net_pay);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
        });
    },[]);

    return (
        <div className='comm-page'>
            <Navbar/>
            <div className='comm-page-container no-margin-top'>
                <div className='print-page-container me-3 mt-0 payslip' ref={componentRef}>
                <Row className='payslip-header d-flex justify-content-end'>PAYSLIP</Row>
                <Row className='d-flex justify-content-end'>Date Printed: {date_today.toLocaleDateString('en-GB', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                })}
                </Row>
                <Row><img src = {NewLogo} alt={"logo"} className="payslip-logo ms-0"></img></Row>
                <Row className='bold p-1'>MYT SoftDev Solutions Inc.</Row>
                <Row className='p-1'>301 The Greenery, Pope John Paul II Ave</Row>
                <Row>Kasambagan, Cebu City, 6000 Cebu</Row>
                <Row className='p-1 mt-3'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th colSpan={2}>Employee Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='bold'>Name</td>
                                <td>{payrollDetails.employee_name ? SentenceCaseName(payrollDetails.employee_name) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold'>Address</td>
                                <td>{payrollDetails.address ? SentenceCaseName(payrollDetails.address) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold'>Contact Number</td>
                                <td>{payrollDetails.contact_no ? payrollDetails.contact_no : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row className='p-1 mt-3'>
                    <div>
                        <span className='bold'>For the month of: </span>
                        <span>{moment(payrollDetails.date_from).format('MMM D, YYYY')} - {moment(payrollDetails.date_to).format('MMM D, YYYY')}</span>
                    </div>
                </Row>
                
                <Row className='p-1 mt-3'>
                    <Col xs={6}>
                        <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Earnings</th>
                                <th className='d-flex justify-content-end'>Amount</th>
                            </tr>
                        </thead>
                      
                        <tbody>
                            {
                                allEarningsValues.map((val, index) => {
                                    if (parseFloat(val) > 0) {
                                        return (
                                            <tr>
                                                <td>{allEarningsLabels[index]}</td>
                                                <td className='d-flex justify-content-end'>{Number(val).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                        )
                                    } 
                                })
                            }
                        </tbody>
                    </table>
                    </Col>
                    <Col xs={6} className='ms-0'>
                        <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Deductions</th>
                                <th className='d-flex justify-content-end'>Amount</th>
                            </tr>
                        </thead>
                      
                        <tbody>
                            {
                                allDeductionValues.map((val, index) => {
                                    if (parseFloat(val) > 0) {
                                        return (
                                            <tr>
                                                <td>{allDeductionLabels[index]}</td>
                                                <td className='d-flex justify-content-end'>{Number(val).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                        )
                                    } 
                                })
                            }
                        </tbody>
                    </table>
                    </Col>
                </Row>
                <Row className='p-1'>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr className='payslip-total'>
                                <td>Gross Earnings</td>
                                <td className='d-flex justify-content-end'>Php {totalEarnings ? totalEarnings.toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : '0.00'}</td>
                                <td>Total Deductions</td>
                                <td className='d-flex justify-content-end'>Php {totalDeductions ? totalDeductions.toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : '0.00'}</td>
                            </tr>
                            <tr>
                                <td className=''></td>
                                <td></td>
                                <td className='bold'>Net Salary</td>
                                <td className='d-flex justify-content-end bold'>Php {netPay ? netPay.toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row className='mt-5'>
                    <Col className='mt-5'>
                        <Row className='d-flex justify-content-center no-margin-bottom'>DARMAE M. TAN</Row>
                        <Row  className='d-flex justify-content-center p-0 m-0'>____________________________________</Row>
                        <Row  className='d-flex justify-content-center'>Employer's Signature</Row>
                    </Col>
                    <Col className='mt-5'>
                        <Row className='d-flex justify-content-center no-margin-bottom'>{payrollDetails.employee_name ? payrollDetails.employee_name.toUpperCase() : ''}</Row>
                        <Row  className='d-flex justify-content-center'>____________________________________</Row>
                        <Row  className='d-flex justify-content-center'>Employee's Signature</Row>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-end mt-5 noprint'>
                    <Col xs={2}>
                        <ReactToPrint
                            trigger={() => (
                                <button className="print-button me-0 noprint">
                                    <span className="export-text"><PrintIcon/> Print</span>
                                </button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"Payslip"}
                        />  
                    </Col>
                    <Col xs={2}>
                        <button type="" className='cancel-button noprint' onClick={() => navigate(-1)}>Back</button>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    )
}

export default EmployeePayslip;