import {handleValidationChange} from '../CommonValidation';

export const validateLeaveType = (data, setIsError) => {
    var isValid = true;

    if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("name", false, setIsError);
    }
    if(data.credits === "") {
        handleValidationChange("credits", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("credits", false, setIsError);
    }
    return isValid
  
}