import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';

// reused this since they both require 'name' lang
import { validateBusinessUnit } from '../../helpers/Validation/Manage/BusinessUnitValidation';
import InputError from '../../helpers/InputError/InpuError';
import {GetAllEmploymentStatuses} from "../../helpers/CommonApi";

export default function AddRequirement(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        name: ''
    });

    const [isError, setIsError] = useState({
        name: false
    })

    function handle(e, element = null) {
        let newRequirementData = { ...data };
        newRequirementData[e.target.id] = e.target.value;
        setData(newRequirementData);
    }

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value || isOnlyWhiteSpace(value)===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // Add Requirement API
    function submitAddRequirement(e) {
        var axios = require('axios');
        var qs = require('qs');
        console.log(data);
        if(validateBusinessUnit(data, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'requirements/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: data.name
            })
            })
            .then(function (response) {
                console.log(response);
                toast.success("Requirement Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                setClick(false);
                toast.error("Requirement Not Added Successfully!");
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Requirement
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                            <Row>
                                <Form.Label className="h6" htmlFor="name">Requirement Name<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        className='input-field'
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e, data)}
                                    />
                                    {renderInputError("name")}
                                </InputGroup>
                            </Row>  
                            
                            {/* <Row>
                                <Form.Label className="h6" htmlFor="name">Requirement Name<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        className='input-field'
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Row> */}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddRequirement}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}