import React, { useState, useRef } from 'react';
import {Row, Col} from 'react-bootstrap';
import { getToken, getUser} from '../../utilities/common';
import { useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import ReactToPrint from 'react-to-print';
import Navbar from '../Navbar/Navbar'
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export default function ViewLeave () {    

    const userToken = getToken();
    const userId = getUser();
    const location = useLocation();
    const navigate = useNavigate();
    const componentRef = useRef();
    const [leaveId, setLeaveId] = useState(location.state);
    const [leave, setLeave] = useState({});
    const [files, setFiles] = useState([]);

    // Get memo detials by ID
    React.useEffect(() => {
        leave.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'employee_leaves/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_leave_id: leaveId
            }
        }).then(response => {
            const res = response.data.data[0];
            setLeave(res.employee_leave);
            setFiles(res.files);
        }).catch(function (error) {
            console.log('Error getting all Memos');
            console.log(error);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className='comm-page-container'>
                <div className='print-page-container me-3' ref={componentRef}>
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className='payslip-header d-flex justify-content-end'>LEAVE</Row>
                        <Row className='d-flex justify-content-end'>DATE: {(function() {
                                return moment(leave.date_start).format('MMMM DD, YYYY');
                            })()}
                    </Row>

                    <Row className='col-6 p-1 mt-3'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr className='payslip-blue-bg'>
                                    <th colSpan={2}>Leave No. {leave.id ? leave.id : ''}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bold'>Employee</td>
                                    <td>{leave.employee_name ? leave.employee_name : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>Leave type</td>
                                    <td>{leave.leave_type ? leave.leave_type : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>Number of Days</td>
                                    <td>{leave.no_of_days ? leave.no_of_days : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>Status</td>
                                    <td>{leave.status ? (leave.status).toUpperCase() : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='p-1 mt-3'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr className='p-3'>
                                    <div dangerouslySetInnerHTML={{__html: leave.remarks?leave.remarks:''}}/>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='bold mt-5'>FILE ATTACHMENTS</Row>
                    <Row className='p-1'>
                        <table className='table table-bordered'>
                            <tbody>
                                {
                                    (files && files.length) ? (
                                        files.map((file) => {
                                            return (
                                                <Row style={{width: 2000}}>
                                                    <Col xs={6} class="input-group p-0">
                                                        <Row className="question-bg text-color mt-1 ms-0 p-0">
                                                            <Col xs={1} class="">
                                                                <FolderOpenIcon/>
                                                            </Col>
                                                            <Col xs={9} className=''>
                                                                <span className="mt-1 fileName">{file.file_name}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <button id="removeRow" className="trash-icon mt-1"><DownloadIcon/></button>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) : (<p></p>)
                                }
                            </tbody>
                        </table>
                    </Row>

                    <Row className='d-flex justify-content-end me-3 mt-4'>
                        <Col className='d-flex justify-content-end'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="print-button me-0 noprint me-1">
                                        <span className="export-text"><PrintIcon/> Print</span>
                                    </button>
                                )}
                                content={() => componentRef.current}
                                documentTitle={"Memorandum"}
                            />
                            <button className="save-button blue noprint" onClick={()=> navigate(-1)}>
                                <span className="export-text">Back</span>
                            </button>
                        </Col>
                    </Row>
                    
                </Row>
                </div>
            </div>
        </div>
    );
}