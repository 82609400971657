import { Modal, Row, Container} from 'react-bootstrap'
import './modal.css'

import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function ApprovePrompt(props) {    

    return (
        <div>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body className="delete-modal">
                <button type="button" class="btn-close"  onClick={props.hide}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll pt-4'>
                        <Container>
                            <Row>
                                <p className='text-center'><ThumbUpIcon className='warning warning-icon primary-color'/></p>
                            </Row>
                            
                            <Row className='pt-3'>
                                <p className='text-center'>ARE YOU SURE YOU WANT TO APPROVE THIS {props.name}?</p>
                            </Row>

                            <div className='d-flex justify-content-end me-2 mt-4'>
                                <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                                <button type="submit" className='save-button' onClick={props.approve}>Approve</button>
                            </div>
    
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}