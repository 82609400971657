import { useState } from 'react';
import {Modal, Row, Col, Form, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

// css
import '../Modals/modal.css'
import './Payroll.css';
import "../../utilities/common.css"

export default function PrintPayroll(props) {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    return(
        <div>
            {/* Print Payroll Modal */}
            <Modal show={props.show} onHide={props.hide} size="m" centered>
                <Form>
                    <Modal.Body>
                    <div className='header'>
                            <p>PRINT PAYROLL</p>
                        </div>
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col>Date From</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="date" name="date from" className="mb-2" onChange={(e) => setDateFrom(e.target.value)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Date To</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="date" name="date to" className="mb-2" onChange={(e) => setDateTo(e.target.value)}/>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <Link to="/payroll/print" state={{from: dateFrom, to: dateTo}} className='print-payroll'>
                            <div className='d-flex justify-content-end me-2 mt-4'>
                                <button type="submit" className='save-button'>Print</button>
                            </div>
                        </Link>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    );
}